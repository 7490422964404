.image-slider-wrap {
	margin-right: -20px;
	margin-left: -20px;

	@media (min-width: 480px) {
		margin-right: 0;
		margin-left: 0;
	}
}

.image-slider {
	$self: &;
	margin: 0;
	width: 100%;
	max-width: 565px;

	@media (min-width: $screen-sm) {
		max-width: none;
		width: 565px;
	}

	@media (min-width: $screen-lg) {
		width: 650px;
	}

	@media (min-width: $screen-xxl) {
		width: 950px;
	}

	&--news {
		width: 100%;
		max-width: 650px;

		@media (min-width: $screen-md) {
			width: 100%;
			max-width: none;
		}

		#{$self}__img-wrap {
			height: auto;
			padding-top: 0;
			text-align: left;
			background-color: $white;

			&::after {
				content: none;
			}
		}

		#{$self}__img {
			position: relative;
			top: auto;
			left: auto;
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 100%;
			object-fit: contain;
			font-family: 'object-fit: contain; object-position: center center;';
		}

		#{$self}__btn-prev {
			top: 20px;
			left: 20px;
			transform: translateY(0);

			@media (min-width: $screen-sm) {
				left: 20px;
			}
		}

		#{$self}__btn-next {
			top: 20px;
			left: 50px;
			transform: translateY(0);

			@media (min-width: $screen-sm) {
				left: 70px;
			}
		}
	}

	&__item {
		width: auto;
		height: auto;
		box-sizing: border-box;
	}

	&__img-wrap {
		position: relative;
		height: 0;
		padding-top: 56.25%;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(180deg, rgba(50, 50, 50, 0.4) -26.93%, rgba(50, 50, 50, 0) 100%);
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__btn-prev {
		left: 20px;

		@media (min-width: $screen-sm) {
			top: 20px;
			left: auto;
			right: 60px;
			transform: translateY(0);
		}

		@media (min-width: $screen-xxl) {
			right: 75px;
		}
	}

	&__btn-next {
		right: 20px;

		@media (min-width: $screen-sm) {
			top: 20px;
			transform: translateY(0);
		}

		@media (min-width: $screen-xxl) {
			right: 30px;
		}
	}
}
