.mobile-slider-wrapper {
	position: relative;
	width: 100%;
	padding-right: 30px;
	padding-left: 30px;

	@media (min-width: $screen-sm) {
		padding-right: 0;
		padding-left: 0;
	}

	&--team {

		.mobile-slider__btn-prev,
		.mobile-slider__btn-next {
			top: 45px;
			transform: translateY(0);
		}
	}
}

.mobile-slider {
	$self: &;

	&--number {

		#{$self}__wrapper {

			@media (min-width: $screen-sm) {
				margin-bottom: -20px;
			}

			@media (min-width: $screen-lg) {
				margin-bottom: -40px;
			}
		}

		#{$self}__item {

			@media (min-width: $screen-sm) {
				margin-bottom: 20px;
				width: 160px;
				margin-right: 60px;
			}

			@media (min-width: $screen-md) {
				width: 25%;
				margin-right: 0;
				padding-right: 50px;
			}

			@media (min-width: $screen-lg) {
				margin-bottom: 40px;
				padding-right: 100px;
			}

			@media (min-width: $screen-xxl) {
				padding-right: 70px;
			}
		}
	}

	&--team {

		#{$self}__wrapper {

			@media (min-width: $screen-sm) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin-bottom: -40px;
			}

			@media (min-width: $screen-xxl) {
				margin-bottom: -60px;
			}
		}

		#{$self}__item {

			@media (min-width: $screen-sm) {
				margin-bottom: 40px;
			}

			@media (min-width: $screen-xxl) {
				margin-bottom: 60px;
			}
		}

	}

	&--icons {

		#{$self}__wrapper {

			@media (min-width: $screen-sm) {
				margin-bottom: -30px;
			}

			@media (min-width: $screen-md) {
				margin-bottom: -40px;
			}

			@media (min-width: $screen-xl) {
				margin-bottom: -60px;
			}
		}

		#{$self}__item {

			@media (min-width: $screen-sm) {
				width: 165px;
				margin-right: 40px;
				margin-bottom: 30px;
			}

			@media (min-width: $screen-md) {
				width: 230px;
				margin-right: 40px;
				margin-bottom: 40px;
			}

			@media (min-width: $screen-lg) {
				width: 265px;
			}

			@media (min-width: $screen-xl) {
				width: 315px;
				margin-right: 80px;
				margin-bottom: 60px;
			}

			@media (min-width: $screen-xxl) {
				margin-right: 90px;
			}
		}
	}

	&--icons_short {

		#{$self}__wrapper {

			@media (min-width: $screen-sm) {
				margin-bottom: -20px;
			}

			@media (min-width: $screen-md) {
				margin-bottom: -30px;
			}

			@media (min-width: $screen-xl) {
				margin-bottom: -40px;
			}
		}

		#{$self}__item {

			@media (min-width: $screen-sm) {
				width: 140px;
				margin-right: 70px;
				margin-bottom: 20px;
			}

			@media (min-width: $screen-md) {
				margin-right: 40px;
				margin-bottom: 30px;
			}

			@media (min-width: $screen-lg) {
				margin-right: 80px;
			}

			@media (min-width: $screen-xl) {
				margin-right: 80px;
				margin-bottom: 40px;
			}
		}
	}

	&--residents {

		#{$self}__wrapper {

			@media (min-width: $screen-sm) {
				align-items: stretch;
				margin-bottom: -20px;
			}

			@media (min-width: $screen-xl) {
				margin-bottom: -40px;
			}
		}

		#{$self}__item {
			height: auto;

			@media (min-width: $screen-sm) {
				display: flex;
				width: calc(50% - 10px);
				margin-right: 20px;
				margin-bottom: 20px;
			}

			@media (min-width: $screen-xl) {
				width: calc(50% - 20px);
				margin-right: 40px;
				margin-bottom: 40px;
			}

			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}

	&--experts {

		#{$self}__wrapper {

			@media (min-width: $screen-sm) {
				margin-bottom: -20px;
			}

			@media (min-width: $screen-md) {
				margin-bottom: -30px;
			}

			@media (min-width: $screen-lg) {
				margin-bottom: -40px;
			}

			@media (min-width: $screen-xxl) {
				margin-bottom: -60px;
			}
		}

		#{$self}__item {

			@media (min-width: $screen-sm) {
				width: 50%;
				margin-bottom: 20px;
				padding-right: 40px;
			}

			@media (min-width: $screen-md) {
				margin-bottom: 30px;
			}

			@media (min-width: $screen-lg) {
				margin-bottom: 40px;
			}

			@media (min-width: $screen-xxl) {
				margin-bottom: 60px;
			}
		}
	}

	&__wrapper {

		@media (min-width: $screen-sm) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;
			box-sizing: border-box;
		}
	}

	&__item {
		box-sizing: border-box;
	}

	&__btn-prev {
		left: 0;

		@media (min-width: $screen-sm) {
			display: none;
		}
	}

	&__btn-next {
		right: 0;

		@media (min-width: $screen-sm) {
			display: none;
		}
	}
}
