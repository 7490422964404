.logo-slider-wrapper {
	position: relative;
	width: 100%;
	padding-right: 30px;
	padding-left: 30px;
}

.logo-slider {
	$self: &;

	&__item {
		box-sizing: border-box;
		user-select: none;
	}

	&__logo-link {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		height: 80px;

		@media (min-width: $screen-sm) {
			height: 60px;
		}

		@media (min-width: $screen-md) {
			height: 80px;
		}

		&:hover {

			#{$self}__img {
				filter: grayscale(0);
			}
		}
	}

	&__img {
		flex-shrink: 0;
		max-height: 100%;
		filter: grayscale(100%);
		transition: filter $transition-time;
	}

	&__btn-prev {
		left: 0;
	}

	&__btn-next {
		right: 0;
	}
}
