.page-footer {
	padding-top: 80px;
	padding-bottom: 20px;
	background-color: $white;

	@media (min-width: $screen-lg) {
		padding-top: 120px;
	}

	@media (min-width: $screen-xxl) {
		padding-top: 160px;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__nav {
		margin-bottom: 20px;

		@media (min-width: $screen-sm) {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 30px;
		}

		@media (min-width: $screen-md) {
			margin-bottom: 50px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 60px;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		color: $gray-darken;

		@media (min-width: $screen-sm) {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}

		p {
			font-size: 12px;
			line-height: 18px;

			@media (min-width: $screen-md) {
				font-size: 14px;
				line-height: 20px;
			}

			@media (min-width: $screen-xxl) {
				font-size: 16px;
				line-height: 24px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__copyright {
		max-width: 420px;
		margin-bottom: 20px;

		@media (min-width: $screen-sm) {
			margin-bottom: 0;
		}

		@media (min-width: $screen-md) {
			max-width: 600px;
		}

		@media (min-width: $screen-xxl) {
			max-width: 685px;
		}
	}

	&__developer-link {
		color: $gray-darken;
	}
}

.footer-menu {
	$self: &;
	display: none;

	@media (min-width: $screen-sm) {
		display: block;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin-right: 20px;

		@media (min-width: $screen-md) {
			margin-right: 30px;
		}

		@media (min-width: $screen-xl) {
			margin-right: 40px;
		}

		@media (min-width: $screen-xxl) {
			margin-right: 60px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		color: $text-color;
		font-size: 14px;
		line-height: 20px;
		border-bottom: 1px solid rgba($text-color, 0.35);

		@media (min-width: $screen-md) {
			font-size: 16px;
			line-height: 26px;
		}

		&:hover {
			color: $primary-sea;
			border-color: transparent;
		}

		&:focus {}

		&:active {
			color: $secondary-sea;
		}

		&--active {
			color: $primary-green;
			border-color: transparent;

			&:hover,
			&:active {
				color: $primary-green;
			}
		}
	}
}
