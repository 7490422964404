.media-grid {
	$self: &;
	width: 100%;

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0;
		margin-bottom: -30px;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-md) {
			margin-bottom: -40px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: -50px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: -60px;
		}
	}

	&__item {
		width: 100%;
		max-width: 482px;
		margin-bottom: 30px;

		@media (min-width: $screen-sm) {
			width: calc(50% - 10px);
			max-width: none;
			margin-right: 20px;
		}

		@media (min-width: $screen-md) {
			width: 314px;
			margin-right: 21px;
			margin-bottom: 40px;
		}

		@media (min-width: $screen-lg) {
			width: 386px;
			margin-bottom: 50px;
		}

		@media (min-width: $screen-xl) {
			width: 426px;
			margin-bottom: 60px;
		}

		@media (min-width: $screen-xxl) {
			width: 520px;
			margin-right: 20px;
		}

		&:nth-child(2n) {
			margin-right: 0;

			@media (min-width: $screen-md) {
				margin-right: 21px;
			}

			@media (min-width: $screen-xxl) {
				margin-right: 20px;
			}
		}

		&:hover {

			#{$self}__img {
				opacity: 0.8;
			}
		}

		&:nth-child(3n) {

			@media (min-width: $screen-md) {
				margin-right: 0;
			}
		}
	}

	&__img-wrap {
		display: block;
		position: relative;
		margin-bottom: 10px;
		overflow: hidden;
		padding-top: 56.1538%;
		height: 0;
		overflow: hidden;

		@media (min-width: $screen-md) {
			height: 176px;
			padding-top: 0;
		}

		@media (min-width: $screen-lg) {
			height: 217px;
		}

		@media (min-width: $screen-xl) {
			height: 239px;
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 20px;
			height: 292px;
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
		transition: opacity $transition-time ease;

		@media (min-width: $screen-md) {
			position: relative;
			top: auto;
			left: auto;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $screen-md) {
			margin-bottom: 15px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__date {
		font-size: 14px;
		line-height: 20px;
		color: $gray-darker;

		@media (min-width: $screen-lg) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 18px;
			line-height: 26px;
		}
	}

	&__more {
		width: 100%;
		max-width: 482px;
		padding-top: 45px;
		text-align: center;

		@media (min-width: $screen-sm) {
			max-width: none;
			padding-top: 55px;
		}

		@media (min-width: $screen-md) {
			padding-top: 70px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 80px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 120px;
		}
	}
}
