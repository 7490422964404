.promo-map {
	position: relative;
	width: 100%;
	height: 310px;
	margin-bottom: 15px;

	@media (min-width: $screen-sm) {
		height: 455px;
	}

	@media (min-width: $screen-md) {
		height: 568px;
	}

	@media (min-width: $screen-xxl) {
		height: 760px;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 120px;
		background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
		pointer-events: none;

		@media (min-width: $screen-sm) {
			height: 258px;
		}
	}

	&__content {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&__container {
		@include container();
		height: 100%;
	}

	&__row {
		@include row();
		height: 100%;
	}

	&__col {
		@include col();
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-between;
		height: 100%;
	}

	&__section-title {
		padding-top: 20px;

		@media (min-width: $screen-sm) {
			padding-top: 40px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 60px;
		}
	}

	&__contacts {
		display: none;

		@media (min-width: $screen-sm) {
			display: block;
		}

		&--mobile {
			display: block;

			@media (min-width: $screen-sm) {
				display: none;
			}
		}

		@media (min-width: $screen-sm) {
			padding-bottom: 40px;
		}

		@media (min-width: $screen-xxl) {
			padding-bottom: 60px;
		}
	}

	&__address,
	&__phone-link {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $screen-lg) {
			font-size: 18px;
			line-height: 26px
		}

		@media (min-width: $screen-xl) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__address {
		margin-bottom: 10px;

		@media (min-width: $screen-xxl) {
			margin-bottom: 20px;
		}
	}
}

.map {
	width: 100%;
	height: 100%;
}
