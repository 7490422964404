.area {
	padding: 10px 0;

	@media (min-width: $screen-lg) {
		padding: 25px 0;
	}

	&__content {
		width: 100%;

		@media (min-width: $screen-sm) {
			width: 540px;
			margin: 0 auto;
		}

		@media (min-width: $screen-md) {
			width: 720px;
		}

		@media (min-width: $screen-lg) {
			width: 900px;
		}


		@media (min-width: $screen-xl) {
			width: 1000px;
		}
	}

	&__img-wrap {
		position: relative;
		padding-top: 81.4887%;
		height: 0;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		position: absolute;
	}

	&__tooltip {
		display: none;
		position: absolute;
		bottom: 65px;
		left: 50%;
		z-index: 1;
		width: 260px;
		padding: 10px 15px;
		text-align: center;
		background: $white;
		box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
		transform: translateX(-50%);

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			width: 0;
			height: 0;
			margin-left: -20px;
			border: solid transparent;
			border-color: rgba(255, 255, 255, 0);
			border-top-color: #ffffff;
			border-width: 20px;
			pointer-events: none;
		}
	}

	&__btn {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
		font-size: 14px;
		line-height: 1;
		color: $white;
		background-color: $primary-sea;
		border: 2px solid $white;
		border-radius: 50%;
		transform-origin: center center;
		transition-property: background-color, color, border-color, transform;
		transition-duration: $transition-time;
		will-change: transform;
		cursor: pointer;
		user-select: none;

		@media (min-width: $screen-sm) {
			width: 30px;
			height: 30px;
			font-size: 16px;
		}

		@media (min-width: $screen-lg) {
			width: 35px;
			height: 35px;
			font-size: 18px;
		}

		&:hover {

			@media (min-width: $screen-lg) {
				color: $white;
				background-color: $secondary-sea;
				border-color: $white;
			}
		}

		&--active {
			color: $primary-sea;
			background-color: $white;
			border-color: $primary-sea;
			transform: scale(1.4286);

			&:hover {
				color: $primary-sea;
				background-color: $white;
				border-color: $primary-sea;
			}
		}
	}

	&__close {
		position: absolute;
		top: 8px;
		right: 8px;
	}
}

.area-list {
	margin-bottom: 10px;
	padding-left: 1.5em;
	list-style: none;
	counter-reset: li;

	@media (min-width: $screen-sm) {
		margin-bottom: 15px;
		padding-left: 2em;
	}

	@media (min-width: $screen-md) {
		padding-left: 2.5em;
	}

	@media (min-width: $screen-xl) {
		margin-bottom: 20px;
	}

	li {
		max-width: 80ch;
		margin-bottom: 15px;
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-md) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 26px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	li::before {
		counter-increment: li;
		content: counters(li, ".") ". ";
		display: inline-block;
		width: 1.5em;
		//margin-right: 0.5em;
		margin-left: -1.5em;
		font-weight: 600;
		color: $primary-green;
		text-align: left;

		@media (min-width: $screen-sm) {
			width: 2em;
			//margin-right: 0.5em;
			margin-left: -2em;
		}
	}

	// second level

	li > ol {
		padding-left: 2.5em;

		li::before {
			width: 2em;
			margin-right: 0.5em;
			margin-left: -2.5em;
		}
	}

	// third level

	li > ol > li > ol {
		padding-left: 3.5em;

		li::before {
			width: 3em;
			margin-right: 0.5em;
			margin-left: -3.5em;
		}
	}

	&__item {

	}

	&__link {
		font-size: 14px;
		line-height: 20px;
	}
}
