.base-text {
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 24px;

	@media (min-width: $screen-sm) {
		margin-bottom: 15px;
	}

	@media (min-width: $screen-md) {
		font-size: 18px;
		line-height: 26px;
	}

	@media (min-width: $screen-xl) {
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 28px;
	}
}

.content-text {
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 20px;

	@media (min-width: $screen-sm) {
		margin-bottom: 15px;
		font-size: 16px;
		line-height: 24px;
	}

	@media (min-width: $screen-md) {
		font-size: 18px;
		line-height: 26px;
	}

	@media (min-width: $screen-xxl) {
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 28px;
	}
}

.slider-content {
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 20px;

	@media (min-width: $screen-md) {
		margin-bottom: 15px;
		font-size: 16px;
		line-height: 24px;
	}

	@media (min-width: $screen-lg) {
		font-size: 18px;
		line-height: 26px;
	}

	@media (min-width: $screen-xl) {
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 28px;
	}
}

.paragraph-limit {

	p {
		max-width: 80ch;
	}

	:last-child {
		margin-bottom: 0;
	}
}

.arrow {

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 10px;
		height: 10px;
		border-right: 2px solid $gray-light;
		border-bottom: 2px solid $gray-light;
		transform-origin: top left;
		transition-property: transform, border-color;
		transition-duration: $transition-time, $transition-time;
		transform: rotate(45deg) translate(-50%, -50%);
	}

	&--big {

		&::after {
			width: 16px;
			height: 16px;
			border-right: 3px solid $gray-light;
			border-bottom: 3px solid $gray-light;
		}
	}

	&--up {

		&::after {
			transform: rotate(-135deg) translate(-50%, -50%);
		}
	}

	&--left {

		&::after {
			left: 0;
			transform: rotate(135deg);
		}
	}

	&--theme_darkgray {

		&::after {
			border-right-color: $gray-darker;
			border-bottom-color: $gray-darker;
		}
	}

	&--theme_green {

		&::after {
			border-right-color: $primary-green;
			border-bottom-color: $primary-green;
		}
	}
}
