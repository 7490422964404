.content-block {

	:last-child {
		margin-bottom: 0;
	}

	p {
		max-width: 80ch;
		@extend .content-text;
	}

	ul:not([class]) {
		margin-bottom: 10px;

		@media (min-width: $screen-sm) {
			margin-bottom: 15px;
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 20px;
		}

		li {
			margin-bottom: 10px;
			font-size: 14px;
			line-height: 20px;

			@media (min-width: $screen-sm) {
				margin-bottom: 15px;
				font-size: 16px;
				line-height: 24px;
			}

			@media (min-width: $screen-md) {
				font-size: 18px;
				line-height: 26px;
			}

			@media (min-width: $screen-xxl) {
				margin-bottom: 20px;
				font-size: 20px;
				line-height: 28px;
			}

			&::before {
				top: 9px;

				@media (min-width: $screen-sm) {
					top: 11px;
				}

				@media (min-width: $screen-md) {
					top: 12px;
				}

				@media (min-width: $screen-xxl) {
					top: 13px;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ol:not([class]) {
		margin-bottom: 10px;
		padding-left: 1.5em;
		list-style: none;
		counter-reset: li;
		/* TODO: проверить все отступы в контентном и обычном варианте */

		@media (min-width: $screen-sm) {
			margin-bottom: 15px;
			padding-left: 2em;
		}

		@media (min-width: $screen-lg) {
			padding-left: 2.5em;
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 20px;
		}

		li {
			margin-bottom: 10px;
			font-size: 14px;
			line-height: 20px;

			@media (min-width: $screen-sm) {
				margin-bottom: 15px;
				font-size: 16px;
				line-height: 24px;
			}

			@media (min-width: $screen-md) {
				font-size: 18px;
				line-height: 26px;
			}

			@media (min-width: $screen-xxl) {
				margin-bottom: 20px;
				font-size: 20px;
				line-height: 28px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		li::before {
			counter-increment: li;
			content: counters(li, ".") ". ";
			display: inline-block;
			width: 1.5em;
			//margin-right: 0.5em;
			margin-left: -1.5em;
			font-weight: 600;
			color: $primary-green;
			text-align: left;

			@media (min-width: $screen-sm) {
				width: 2em;
				//margin-right: 0.5em;
				margin-left: -2em;
			}
		}

		// second level

		li > ol {
			margin-top: 10px;
			padding-left: 2.5em;

			@media (min-width: $screen-sm) {
				margin-top: 15px;
			}

			@media (min-width: $screen-xxl) {
				margin-top: 20px;
			}

			li::before {
				width: 2em;
				margin-right: 0.5em;
				margin-left: -2.5em;
			}
		}

		// third level

		li > ol > li > ol {
			padding-left: 3.5em;

			li::before {
				width: 3em;
				margin-right: 0.5em;
				margin-left: -3.5em;
			}
		}
	}

	&--doc {

		img {
			margin-bottom: 15px;
			border: 1px solid $gray-lighter;

			@media (min-width: $screen-lg) {
				margin-bottom: 20px;
			}

			@media (min-width: $screen-xl) {
				margin-bottom: 30px;
			}
		}
	}
}
