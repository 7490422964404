.contacts {
	@include row();

	&__info {
		@include col(xs, 12, 12);
		@include col(sm, 4, 10);
		@include col(md, 4, 12);
		margin-bottom: 25px;

		@media (min-width: $screen-sm) {
			margin-bottom: 0;
		}
	}

	&__map {
		@include col(xs, 12, 12);
		@include col(sm, 6, 10);
		@include col(md, 8, 12);
	}

	&__map-wrap {
		margin: 0 -20px;
		height: 340px;

		@media (min-width: $screen-sm) {
			width: 100%;
			height: 380px;
			margin: 0;
		}

		@media (min-width: $screen-md) {
			height: 420px;
		}

		@media (min-width: $screen-lg) {
			height: 515px;
		}

		@media (min-width: $screen-xl) {
			height: 570px;
		}

		@media (min-width: $screen-xxl) {
			height: 690px;
		}
	}
}

.contacts-list {
	margin: 0;
	margin-bottom: 30px;
	padding: 0;
	list-style: none;

	@media (min-width: $screen-xxl) {
		margin-bottom: 40px;
	}

	&__item {
		margin-bottom: 20px;

		@media (min-width: $screen-xxl) {
			margin-bottom: 30px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link-wrap {
		display: block;
		white-space: nowrap;
	}

	&__title,
	&__text,
	&__phone-link,
	&__email-link,
	&__link-tip {
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $screen-md) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-lg) {
			font-size: 20px;
			line-height: 28px;
		}
	}


	&__title {
		margin-bottom: 5px;
		font-weight: 600;
	}

	&__text {}

	&__phone-link,
	&__email-link {
		color: $text-color;

		&:hover {
			color: $primary-green;
		}

		&:focus {}

		&:active {
			color: $secondary-green;
		}
	}

	&__email-link {
		color: $gray-darken;
	}

	&__link-tip {}
}
