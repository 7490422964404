.hash-tag-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		margin-right: 5px;

		@media (min-width: $screen-sm) {
			margin-right: 10px;
		}
	}

	&__link {
		font-size: 14px;
		line-height: 20px;
		color: $primary-sea;

		@media (min-width: $screen-sm) {
			font-size: 18px;
			line-height: 26px;
		}
	}
}
