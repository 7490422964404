.download-link-wrap {
	$self: &;

	+ #{$self} {
		margin-top: 20px;
	}
}

.download-link {
	$self: &;
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	&:hover,
	&:active {

		#{$self}__icon {
			stroke: $primary-sea;
		}

		#{$self}__title {
			color: $text-color;
		}

		#{$self}__tip {
			color: $text-color;
		}
	}

	&--doc {

		#{$self}__icon-wrap {
			width: 30px;
			height: 38px;
			margin-right: 15px;
		}

		#{$self}__icon {
			stroke: $primary-green;
		}

		#{$self}__title {
			display: inline;
			margin-bottom: 0;
			font-weight: 400;
			color: $text-color;
			border-bottom: 1px solid $gray-dark;

			@media (min-width: $screen-sm) {
				font-size: 16px;
				line-height: 24px;
			}

			@media (min-width: $screen-md) {
				font-size: 18px;
				line-height: 26px;
			}
		}

		#{$self}__tip {
			margin-top: 10px;
			display: block;
		}

		&:hover,
		&:active {

			#{$self}__icon {
				stroke: $secondary-green;
			}

			#{$self}__title {
				color: $black;
				border-color: transparent;
			}

			#{$self}__tip {
				color: $black;
			}
		}
	}

	&__icon-wrap {
		flex-shrink: 0;
		width: 26px;
		height: 26px;
		margin-right: 10px;
	}

	&__icon {
		fill: none;
		stroke: $primary-green;
		transition: stroke $transition-time,
	}

	&__title,
	&__tip {
		font-size: 14px;
		line-height: 20px;
		color: $text-color-muted;
		transition: color, border-color;
		transition-duration: $transition-time;

		@media (min-width: $screen-xxl) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__title {
		display: block;
		margin-bottom: 5px;
		font-weight: 600;
	}

	&__tip {
		font-weight: 400;
		text-transform: uppercase;

		span {
			text-transform: none;
		}
	}
}
