.price-search {
	@include container();
	margin-bottom: 25px;

	@media (min-width: $screen-sm) {
		margin-bottom: 40px;
	}

	@media (min-width: $screen-lg) {
		margin-bottom: 60px;
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__content {
		width: 100%;
		max-width: 720px;
	}
}
