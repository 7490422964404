h1,
h2,
h3,
h4 {
	margin-top: 0;
	font-weight: 400;
}

h1 {
	margin-bottom: 20px;
	font-size: 24px;
	line-height: 32px;

	@media (min-width: $screen-sm) {
		font-size: 32px;
		line-height: 42px;
	}

	@media (min-width: $screen-lg) {
		margin-bottom: 30px;
		font-size: 40px;
		line-height: 54px;
	}

	@media (min-width: $screen-xl) {
		font-size: 48px;
		line-height: 60px;
	}

	@media (min-width: $screen-xxl) {
		margin-bottom: 40px;
	}
}

h2 {
	margin-bottom: 20px;
	font-size: 24px;
	line-height: 32px;

	@media (min-width: $screen-md) {
		font-size: 32px;
		line-height: 42px;
	}

	@media (min-width: $screen-lg) {
		margin-bottom: 30px;
		font-size: 36px;
		line-height: 48px;
	}

	@media (min-width: $screen-xxl) {
		margin-bottom: 40px;
	}
}

h3 {
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 24px;

	@media (min-width: $screen-md) {
		font-size: 18px;
		line-height: 26px;
	}

	@media (min-width: $screen-lg) {
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 28px;
	}

	@media (min-width: $screen-xxl) {
		margin-bottom: 30px;
	}
}

p {
	@extend .base-text;
}

ul:not([class]) {
	margin-bottom: 10px;
	padding-left: 0;
	list-style: none;

	@media (min-width: $screen-sm) {
		margin-bottom: 15px;
	}

	@media (min-width: $screen-xl) {
		margin-bottom: 20px;
	}

	li {
		position: relative;
		max-width: 80ch;
		margin-bottom: 10px;
		padding-left: 26px;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $screen-md) {
			padding-left: 30px;
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 15px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 20px;
			line-height: 28px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 11px;
			width: 20px;
			height: 2px;
			background-color: $primary-green;
			border-radius: 10px;

			@media (min-width: $screen-md) {
				top: 12px;
			}

			@media (min-width: $screen-xl) {
				top: 13px;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

ol:not([class]) {
	margin-bottom: 10px;
	padding-left: 1.5em;
	list-style: none;
	counter-reset: li;

	@media (min-width: $screen-sm) {
		margin-bottom: 15px;
		padding-left: 2em;
	}

	@media (min-width: $screen-md) {
		padding-left: 2.5em;
	}

	@media (min-width: $screen-xl) {
		margin-bottom: 20px;
	}

	li {
		max-width: 80ch;
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $screen-md) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 15px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 20px;
			line-height: 28px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	li::before {
		counter-increment: li;
		content: counters(li, ".") ". ";
		display: inline-block;
		width: 1.5em;
		//margin-right: 0.5em;
		margin-left: -1.5em;
		font-weight: 600;
		color: $primary-green;
		text-align: left;

		@media (min-width: $screen-sm) {
			width: 2em;
			//margin-right: 0.5em;
			margin-left: -2em;
		}
	}

	// second level

	li > ol {
		padding-left: 2.5em;

		li::before {
			width: 2em;
			margin-right: 0.5em;
			margin-left: -2.5em;
		}
	}

	// third level

	li > ol > li > ol {
		padding-left: 3.5em;

		li::before {
			width: 3em;
			margin-right: 0.5em;
			margin-left: -3.5em;
		}
	}
}

mark {
	background: $primary-red;
	color: $text-color;
}

.link {

	&--theme_green {
		color: $text-color;
		border-bottom: 1px solid rgba($text-color, 0.35);

		&:hover {
			color: $primary-green;
			border-color: transparent;
		}

		&:focus {}

		&:active {
			color: $secondary-green;
		}
	}

	&--theme_sea {
		color: $text-color;

		&:hover {
			color: $primary-sea;
		}

		&:focus {}

		&:active {
			color: $secondary-sea;
		}
	}

	&--theme_gray {
		color: $gray-darker;
		border-bottom: 1px solid rgba($text-color, 0.35);

		&:hover {
			color: $gray-darker;
			border-color: transparent;
		}

		&:focus {}

		&:active {
			color: $text-color;
		}
	}

	&--theme_border-sea {
		color: $text-color;
		border-bottom: 1px solid rgba($primary-sea, 0.3);

		&:hover {
			color: $text-color;
			border-color: transparent;
		}

		&:focus {}

		&:active {
			color: $text-color;
		}
	}

	&--hashtag {
		color: $primary-sea;

		&:hover {
			color: $secondary-sea;
		}

		&:focus {}

		&:active {
			color: $secondary-sea;
		}
	}
}

.section {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding-top: 45px;

	@media (min-width: $screen-sm) {
		padding-top: 55px;
	}

	@media (min-width: $screen-md) {
		padding-top: 70px;
	}

	@media (min-width: $screen-lg) {
		padding-top: 80px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 120px;
	}

	&--top {
		padding-top: 20px;

		@media (min-width: $screen-md) {
			padding-top: 30px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 40px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 80px;
		}
	}

	&--top_no-banner {
		padding-top: 20px;

		@media (min-width: $screen-sm) {
			padding-top: 30px;
		}

		@media (min-width: $screen-md) {
			padding-top: 70px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 80px;
		}
	}

	&--top_tabs {
		padding-top: 20px;

		@media (min-width: $screen-sm) {
			padding-top: 30px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 40px;
		}
	}

	&--fluid {
		max-width: none;
		margin: 0;
	}

	&--no-padding {
		padding-top: 0;
		padding-bottom: 0;
	}

	&--theme_sea {
		background-color: $primary-sea;
	}

	&--theme_green {
		background-color: $primary-green;
	}

	&__inner {

		&--theme_green {
			background-color: $primary-green;
		}

		&--theme_lightsea {
			background-color: $tertiary-sea;
		}

		&--theme_separated {

			@media (min-width: $screen-sm) {
				background: linear-gradient(to right, $gray-lighter 50%, $white 50%);
				position: relative;
			}

			&::before,
			&::after {

				@media (min-width: $screen-sm) {
					content: "";
					position: absolute;
					width: 50%;
					height: 1px;
					top: 0;
					right: 1px;
					background-color: $gray-lighten;
				}
			}

			&::after {

				@media (min-width: $screen-sm) {
					top: auto;
					bottom: 0;
				}
			}
		}
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}
}

.section-pattern {

	@media (min-width: $screen-lg) {
		position: relative;
	}

	&::after {

		@media (min-width: $screen-lg) {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			z-index: -1;
			width: 325px;
			height: 100%;
			max-height: 766px;
			background-repeat: no-repeat;
			background-position: right bottom;
			background-size: contain;
			transform: translateY(-50%);
			background-image: url("../img/section-pattern--lg.svg");
		}

		@media (min-width: $screen-xl) {
			background-image: url("../img/section-pattern--xl.svg");
			width: 466px;
			max-height: 766px;
		}

		@media (min-width: $screen-xxl) {
			background-image: url("../img/section-pattern--xxl.svg");
			width: 680px;
			max-height: 766px;
		}
	}

	&--top {

		&::after {
			top: 0;
			transform: translateY(0);
		}
	}

	&--bottom {

		&::after {
			bottom: 0;
			transform: translateY(0);
		}
	}

}

.content-section {
	padding-top: 45px;

	@media (min-width: $screen-sm) {
		padding-top: 55px;
	}

	@media (min-width: $screen-md) {
		padding-top: 70px;
	}

	@media (min-width: $screen-lg) {
		padding-top: 80px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 120px;
	}
}

.icon-link {
	$self: &;
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	&:hover {

		#{$self}__icon {
			opacity: 0.5;
		}

		#{$self}__title {
			opacity: 0.5;
		}
	}

	&__icon {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		margin-right: 5px;
		transition: opacity $transition-time;

		@media (min-width: $screen-md) {
			margin-right: 10px;
		}
	}

	&__title {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $white;
		transition: opacity $transition-time;

		@media (min-width: $screen-md) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.page-blockquote {
	$self: &;
	position: relative;
	margin: 0;
	padding: 22px 0 0;

	@media (min-width: $screen-sm) {
		padding-top: 30px;
	}

	@media (min-width: $screen-md) {
		padding-top: 37px;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 17px;
		background-image: url("data:image/svg+xml,%3Csvg width='32' height='27' viewBox='0 0 32 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83957 13.3258C11.0945 13.8484 12.0642 14.6903 12.7487 15.8516C13.4902 16.9548 13.861 18.2903 13.861 19.8581C13.861 21.9484 13.205 23.6613 11.893 24.9968C10.5811 26.3323 8.95543 27 7.01604 27C4.96257 27 3.27986 26.3323 1.96791 24.9968C0.655971 23.6613 0 21.9484 0 19.8581C0 18.8129 0.114082 17.7968 0.342246 16.8097C0.627451 15.8226 1.14082 14.3419 1.88235 12.3677L6.41711 0H13.262L9.83957 13.3258ZM27.9786 13.3258C29.2335 13.8484 30.2032 14.6903 30.8877 15.8516C31.6292 16.9548 32 18.2903 32 19.8581C32 21.9484 31.344 23.6613 30.0321 24.9968C28.7201 26.3323 27.0945 27 25.1551 27C23.1016 27 21.4189 26.3323 20.1069 24.9968C18.795 23.6613 18.139 21.9484 18.139 19.8581C18.139 18.8129 18.2531 17.7968 18.4813 16.8097C18.7665 15.8226 19.2799 14.3419 20.0214 12.3677L24.5561 0H31.4011L27.9786 13.3258Z' fill='%23323232'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;

		@media (min-width: $screen-sm) {
			width: 24px;
			line-height: 20px;
		}

		@media (min-width: $screen-md) {
			width: 32px;
			height: 27px;
		}
	}

	&--team {

		#{$self}__text {

			p {
				margin-bottom: 10px;
				font-size: 14px;
				line-height: 20px;

				@media (min-width: $screen-md) {
					margin-bottom: 15px;
					font-size: 16px;
					line-height: 24px;
				}

				@media (min-width: $screen-lg) {
					font-size: 18px;
					line-height: 26px;
				}
			}
		}

	}

	&__text {
		margin-bottom: 20px;

		@media (min-width: $screen-xl) {
			margin-bottom: 30px;
		}

		p {
			@extend .content-text;
			max-width: 60ch;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__author {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	&__photo {
		flex-shrink: 0;
		width: 50px;
		height: 50px;
		margin-right: 10px;
		border-radius: 50%;
		overflow: hidden;

		@media (min-width: $screen-sm) {
			width: 40px;
			height: 40px;
		}

		@media (min-width: $screen-md) {
			width: 70px;
			height: 70px;
			margin-right: 20px;
		}
	}

	&__name {
		font-family: $font-family;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-md) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.slider-title {
	margin-top: 0;
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: $text-color;

	@media (min-width: $screen-md) {
		font-size: 18px;
		line-height: 26px;
	}

	@media (min-width: $screen-lg) {
		margin-bottom: 30px;
	}

	@media (min-width: $screen-xl) {
		margin-bottom: 40px;
	}

	@media (min-width: $screen-xxl) {
		font-size: 20px;
		line-height: 28px;
	}
}

.content-wrap {
	padding-top: 20px;

	@media (min-width: $screen-sm) {
		padding-top: 30px;
	}

	@media (min-width: $screen-lg) {
		padding-top: 40px;
	}
}

.embed-container {
	position: relative;
	max-width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.back {
	margin-bottom: 30px;

	@media (min-width: $screen-sm) {
		margin-bottom: 40px;
	}

	@media (min-width: $screen-xxl) {
		margin-bottom: 60px;
	}

	&__link {
		position: relative;
		margin-left: 20px;
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}

		@extend .arrow;
		@extend .arrow--left;
		@extend .arrow--theme_darkgray;

		&::after {
			left: -5px;
			border-right-color: $gray-dark;
			border-bottom-color: $gray-dark;
		}

		&:active {

			&::after {
				border-right-color: $gray-darkest;
				border-bottom-color: $gray-darkest;
			}
		}
	}
}

.content-box {

	h2 {
		margin-top: 0;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-lg) {
			font-size: 18px;
			line-height: 26px;
		}
	}

	p {
		max-width: 80ch;
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 15px;
			font-size: 18px;
			line-height: 26px;
		}
	}

	ul:not([class]) {
		margin-bottom: 10px;

		@media (min-width: $screen-lg) {
			margin-bottom: 15px;
		}

		li {
			margin-bottom: 10px;
			font-size: 14px;
			line-height: 20px;

			@media (min-width: $screen-sm) {
				font-size: 16px;
				line-height: 24px;
			}

			@media (min-width: $screen-lg) {
				margin-bottom: 15px;
				font-size: 18px;
				line-height: 26px;
			}

			&::before {
				top: 9px;

				@media (min-width: $screen-sm) {
					top: 11px;
				}

				@media (min-width: $screen-lg) {
					top: 12px;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ol:not([class]) {
		margin-bottom: 10px;
		padding-left: 1.5em;
		list-style: none;
		counter-reset: li;
		/* TODO: проверить все отступы в контентном и обычном варианте */

		@media (min-width: $screen-sm) {
			margin-bottom: 15px;
			padding-left: 2em;
		}

		@media (min-width: $screen-lg) {
			padding-left: 2.5em;
		}

		li {
			margin-bottom: 10px;
			font-size: 14px;
			line-height: 20px;

			@media (min-width: $screen-sm) {
				font-size: 16px;
				line-height: 24px;
			}

			@media (min-width: $screen-lg) {
				margin-bottom: 15px;
				font-size: 18px;
				line-height: 26px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		li::before {
			counter-increment: li;
			content: counters(li, ".") ". ";
			display: inline-block;
			width: 1.5em;
			//margin-right: 0.5em;
			margin-left: -1.5em;
			font-weight: 600;
			color: $primary-green;
			text-align: left;

			@media (min-width: $screen-sm) {
				width: 2em;
				//margin-right: 0.5em;
				margin-left: -2em;
			}
		}

		// second level

		li > ol {
			margin-top: 10px;
			padding-left: 2.5em;

			@media (min-width: $screen-lg) {
				margin-top: 15px;
			}

			li::before {
				width: 2em;
				margin-right: 0.5em;
				margin-left: -2.5em;
			}
		}

		// third level

		li > ol > li > ol {
			padding-left: 3.5em;

			li::before {
				width: 3em;
				margin-right: 0.5em;
				margin-left: -3.5em;
			}
		}
	}
}
