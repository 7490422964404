.reviews {
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;

	@media (min-width: $screen-sm) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: $screen-md) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	@media (min-width: $screen-xl) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	@media (min-width: $screen-xxl) {
		padding-top: 90px;
		padding-bottom: 90px;
	}
}
