.gallery-slider-wrap {
	position: relative;
	width: 100%;
	padding-right: 25px;
	padding-left: 25px;

	@media (min-width: $screen-sm) {
		padding-right: 0;
		padding-left: 0;
	}
}

.gallery-slider {

	&__item {
		box-sizing: border-box;
	}

	&__item-inner {
		max-width: 494px;
		margin: 0 auto;

		@media (min-width: $screen-sm) {
			max-width: none;
			margin: 0;
		}
	}

	&__img-wrap {
		position: relative;
		height: 0;
		padding-top: 66.5992%;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__text {
		margin-top: 10px;
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $text-color;

		@media (min-width: $screen-sm) {
			text-align: left;
		}

		@media (min-width: $screen-md) {
			margin-top: 15px;
		}

		@media (min-width: $screen-lg) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-xl) {
			font-size: 20px;
			line-height: 28px;
		}

		@media (min-width: $screen-xxl) {
			margin-top: 20px;
			font-size: 24px;
			line-height: 32px;
		}
	}

	&__btn-prev {
		left: -5px;

		@media (min-width: $screen-sm) {
			top: -30px;
			left: auto;
			right: 35px;
		}

		@media (min-width: $screen-md) {
			right: 40px;
		}

		@media (min-width: $screen-lg) {
			top: -40px;
		}

		@media (min-width: $screen-xxl) {
			top: -50px;
		}
	}

	&__btn-next {
		right: -5px;

		@media (min-width: $screen-sm) {
			top: -30px;
			right: 0;
		}

		@media (min-width: $screen-lg) {
			top: -40px;
		}

		@media (min-width: $screen-xxl) {
			top: -50px;
		}
	}
}
