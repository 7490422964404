.top-menu {

	@media (min-width: $screen-md) {
		background-color: $gray-lighter;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__filter {
		width: 100%;
		max-width: 500px;
		margin: 15px 0;

		@media (min-width: $screen-sm) {
			margin: 20px 0;
		}

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__list {
		display: none;

		@media (min-width: $screen-md) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			margin: 0;
			padding: 0;
			padding-top: 15px;
			list-style: none;
		}
	}

	&__item {
		margin-right: 30px;
		margin-bottom: 15px;

		@media (min-width: $screen-xxl) {
			margin-right: 40px;
		}
	}

	&__link {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		white-space: nowrap;
		color: $text-color-muted;

		@media (min-width: $screen-xxl) {
			font-size: 16px;
			line-height: 24px;
		}

		&--active {
			color: $primary-green;
			cursor: default;

			&:hover {
				color: $primary-green;
			}

			&:focus {

			}

			&:active {
				color: $primary-green;
			}
		}
	}
}
