.media-item {
	@include row();

	&__content {
		@include col(xs, 12, 12);
		@include col(sm, 9, 10);
		@include col(md, 8, 12);
		@include col(lg, 7, 12);

	}

	&__aside {
		@include col(xs, 12, 12);
		@include col(sm, 9, 10);
		@include col(md, 4, 12);
		@include col(lg, 5, 12);
		@include col(xl, 4, 12);
		@include col-offset(xl, 1, 12);
		padding-top: 45px;

		@media (min-width: $screen-sm) {
			padding-top: 55px;
		}

		@media (min-width: $screen-md) {
			padding-top: 0;
		}
	}

	&__hash-tag-list {
		margin-bottom: 20px;
	}

	&__title {
		margin-top: 0;
		margin-bottom: 15px;
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;

		@media (min-width: $screen-sm) {
			margin-bottom: 20px;
			font-size: 24px;
			line-height: 32px;
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 30px;
		}
	}

	&__slider,
	&__video {
		margin-bottom: 10px;

		@media (min-width: $screen-md) {
			margin-bottom: 15px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 20px;
		}
	}

	&__author {
		margin-top: 20px;
		max-width: 80ch;
		font-size: 14px;
		line-height: 20px;
		color: $gray-darker;

		@media (min-width: $screen-sm) {
			margin-top: 30px;
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-lg) {
			margin-top: 40px;
			font-size: 18px;
			line-height: 26px;
		}
	}
}
