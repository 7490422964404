.intro {
	background-color: $primary-sea;

	@media (min-width: $screen-sm) {
		/*todo: переделать блок с картинкой*/
	}

	&__inner {
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
		padding-top: 30px;
		color: $white;
		overflow: hidden;

		@media (min-width: $screen-sm) {
			padding-top: 85px;
			padding-bottom: 0;
		}

		@media (min-width: $screen-md) {
			padding-top: 95px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 125px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 105px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 145px;
		}
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__banner {
		@include col(xs, 10, 12);
		@include col(sm, 5, 10);
		@include col(md, 7, 12);
		@include col(lg, 5, 12);
		@include col(xl, 6, 12);
		@include col(xxl, 6, 12);

		br {
			display: none;

			@media (min-width: $screen-md) {
				display: block;
			}
		}
	}

	&__text {
		@include col(xs, 12, 12);
		@include col(sm, 5, 10);
		@include col(md, 5, 12);
		@include col(lg, 5, 12);
		@include col(xxl, 4, 12);
	}

	&__img {
		@include col(xs, 12, 12);
		@include col(sm, 5, 10);
		@include col(md, 6, 12);
		@include col(lg, 7, 12);
		@include col(xxl, 7, 12);
		@include col-offset(md, 1, 12);
		@include col-offset(lg, 0, 12);
		@include col-offset(xxl, 1, 12);
		position: relative;
		min-height: 285px;

		@media (min-width: $screen-sm) {
			min-height: auto;
		}
	}

	&__bg {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 970px;
		height: 436px;
		background-image: url("../img/intro-banner-img.svg");
		background-repeat: no-repeat;
		background-position: left bottom;
		background-size: contain;

		@media (min-width: $screen-sm) {
			width: 795px;
			height: 357px;
		}

		@media (min-width: $screen-md) {
			width: 1083px;
			height: 487px;
		}
	}

	&__title {
		margin-bottom: 10px;
		font-size: 24px;
		line-height: 32px;

		@media (min-width: $screen-md) {
			font-size: 36px;
			line-height: 48px;
		}

		@media (min-width: $screen-lg) {
			font-size: 40px;
			line-height: 54px;
		}

		@media (min-width: $screen-xl) {
			font-size: 48px;
			line-height: 60px;
		}
	}

	&__link {
		@include col(xs, 12, 12);
		padding-top: 20px;
		padding-bottom: 20px;
		line-height: 1;

		@media (min-width: $screen-sm) {
			padding-top: 25px;
			padding-bottom: 25px;
			text-align: right;
		}

		@media (min-width: $screen-md) {
			padding-top: 30px;
			padding-bottom: 80px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 35px;
			padding-bottom: 40px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 40px;
			padding-bottom: 100px;
		}
	}

	p {
		@extend .content-text;
		max-width: 40ch;

		@media (min-width: $screen-sm) {
			max-width: none;
		}
	}
}
