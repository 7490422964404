.accordion {
	$self: &;

	&--building {

		#{$self}__title {
			position: relative;
			padding-left: 20px;
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			color: $text-color-muted;

			@media (min-width: $screen-md) {
				padding-left: 24px;
				font-size: 16px;
				line-height: 24px;
			}

			@media (min-width: $screen-lg) {
				font-size: 18px;
				line-height: 26px;
			}

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				width: 11px;
				height: 14px;
				background-image: url("data:image/svg+xml,%3Csvg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 5.88393C10.5 6.69115 10.1998 7.53059 9.71043 8.3568C9.22258 9.18043 8.56467 9.9606 7.89417 10.6398C7.22522 11.3174 6.55437 11.8839 6.04994 12.2814C5.82921 12.4553 5.64111 12.5962 5.5 12.6993C5.35889 12.5962 5.17079 12.4553 4.95006 12.2814C4.44563 11.8839 3.77478 11.3174 3.10583 10.6398C2.43533 9.9606 1.77742 9.18043 1.28957 8.3568C0.800209 7.53059 0.5 6.69115 0.5 5.88393C0.5 3.07695 2.74464 0.8125 5.5 0.8125C8.25536 0.8125 10.5 3.07695 10.5 5.88393Z' fill='%2395C12B' stroke='%2395C12B'/%3E%3Ccircle cx='5.5' cy='5.8125' r='2.5' fill='white'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				transform: translateY(-50%);

				@media (min-width: $screen-md) {
					width: 14px;
					height: 18px;
				}
			}
		}

		#{$self}__sub-list {
			padding-bottom: 5px;
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		border-bottom: 1px solid $gray-lighten;

		&--active {

			#{$self}__title {
				@extend .arrow;
				@extend .arrow--big;
				@extend .arrow--theme_green;

				&::after {
					top: calc(50% - 8px);
					right: 10px;
					left: auto;
					transform-origin: center center;
					transform: rotate(-135deg) translateY(0);
				}
			}
		}
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__title {
		position: relative;
		display: block;
		width: 100%;
		padding: 15px 0;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: $text-color;

		@media (min-width: $screen-sm) {
			padding-top: 20px;
			padding-bottom: 20px;
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-md) {
			font-size: 20px;
			line-height: 28px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 30px;
			padding-bottom: 30px;
			font-size: 24px;
			line-height: 32px;
		}

		&:hover {
			color: $text-color;
		}

		@extend .arrow;
		@extend .arrow--big;

		&::after {
			top: calc(50% - 8px);
			right: 10px;
			left: auto;
			transform-origin: center center;
			transform: rotate(45deg) translateY(0);
		}
	}

	&__sub-list {
		display: none;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__content {
		padding-bottom: 15px;

		@media (min-width: $screen-sm) {
			padding-bottom: 20px;
		}

		@media (min-width: $screen-lg) {
			padding-bottom: 30px;
		}
	}

	&__sub-item {

		&--info-doc {
			padding: 10px 0;
		}
	}
}
