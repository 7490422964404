.icon-card {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	text-align: center;

	@media (min-width: $screen-sm) {
		align-items: flex-start;
		text-align: left;
	}

	&__icon-wrap {
		width: 80px;
		height: 80px;
		margin-bottom: 10px;
		user-select: none;

		@media (min-width: $screen-md) {
			width: 100px;
			height: 100px;
			margin-bottom: 20px;
		}
	}

	&__icon {}

	&__text {

		:last-child {
			margin-bottom: 0;
		}

		p {
			@extend .slider-content;
		}
	}
}
