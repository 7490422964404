// Borders
$social-border-color: 					$gray-dark !default;
$social-border-color-hover: 		rgba($gray-dark, 0.6) !default;
$social-border-color-active: 		rgba($gray-dark, 0.8) !default;

// Icons
$social-icon-color: 						$gray-dark !default;
$social-icon-color-hover: 			rgba($gray-dark, 0.6) !default;
$social-icon-color-active: 			rgba($gray-dark, 0.8) !default;

$social-border-color:						#E6E6E6;
$social-icon-color:							$gray;

$social-border-color-hover: 		$primary-green;
$social-border-color-active: 		$secondary-green;
$social-icon-color-hover: 			$primary-green;
$social-icon-color-active: 			$secondary-green;

.social {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;

	&__icon {
		fill: $gray-dark;
		transition: all $transition-time;
		vertical-align: middle;
	}

	&__link {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		font-size: 0;
		width: 26px;
		height: 26px;
		text-decoration: none;
		border: 2px solid $social-border-color;
		border-radius: 50%;
		transition: all $transition-time;

		& + #{$self}__link {
			margin-left: 10px;
		}

		&:hover {
			border-color: $social-border-color-hover;

			#{$self}__icon {
				fill: $social-icon-color-hover;
			}
		}

		&:focus {}

		&:active {
			border-color: $social-border-color-active;

			#{$self}__icon {
				fill: $social-icon-color-active;
			}
		}
	}

	&__link {

		/* TODO: отредактировать размеры */

		&--vk {

			#{$self}__icon {
				width: 19px;
				height: 10px;
			}
		}

		&--instagram {

			#{$self}__icon {
				width: 14px;
				height: 14px;
			}
		}

		&--fb {

			#{$self}__icon {
				width: 10px;
				height: 18px;
			}
		}

		&--twitter {

			#{$self}__icon {
				width: 16px;
				height: 12px;
			}
		}

		&--ok {

			#{$self}__icon {
				width: 8px;
				height: 16px;
			}
		}

		&--youtube {

			#{$self}__icon {
				width: 17px;
				height: 12px;
			}
		}

		&--telegram {

			#{$self}__icon {
				width: 16px;
				height: 12px;
			}
		}

		&--whatsapp {

			#{$self}__icon {
				width: 14px;
				height: 14px;
			}
		}
	}
}
