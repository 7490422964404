.doc-grid {

	&__row {
		@include row();
		margin-bottom: -20px;

		@media (min-width: $screen-sm) {
			margin-bottom: -30px;
		}
	}

	&__col {
		@include col(xs, 12, 12);
		@include col(sm, 4, 10);
		@include col(md, 4, 12);
		@include col(lg, 3, 12);
		margin-bottom: 20px;

		@media (min-width: $screen-sm) {
			margin-bottom: 30px;
		}
	}
}
