.p-filter {
	width: 100%;
	border-bottom: 1px solid $gray-lighten;

	&__row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		width: 984px;
		margin: 0 auto;
		padding: 15px 0;

		@media (min-width: $screen-lg) {
			width: 1200px;
		}

		@media (min-width: $screen-xl) {
			width: 1320px;
		}

		@media (min-width: $screen-xxl) {
			width: 1600px;
		}
	}

	&__col {

		&:nth-child(1) {
			flex-shrink: 0;
			width: 225px;
			margin-right: 25px;

			@media (min-width: $screen-lg) {
				width: 285px;
			}

			@media (min-width: $screen-xl) {
				width: 300px;
				margin-right: 40px;
			}
		}

		&:nth-child(2) {
			flex-grow: 1;
			width: auto;
			margin-right: 25px;

			@media (min-width: $screen-xl) {
				margin-right: 40px;
			}
		}

		&:nth-child(3) {
			flex-shrink: 0;
			width: 100px;
			margin-right: 25px;

			@media (min-width: $screen-xl) {
				margin-right: 40px;
			}
		}

		&:nth-child(4) {
			flex-shrink: 0;
			width: 100px;
			margin-right: 25px;

			@media (min-width: $screen-xl) {
				margin-right: 40px;
			}

			@media (min-width: $screen-xxl) {
				margin-right: 250px;
			}
		}
	}

	&__link {
		position: relative;
		font-size: 14px;
		line-height: 20px;
		color: $text-color-muted;

		@media (min-width: $screen-lg) {
			font-size: 16px;
			line-height: 24px;
		}

		&::before {
			content: "";
			position: absolute;
			top: -17px;
			left: 0;
			width: 12px;
			height: 7px;
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0L12 7H0L6 0Z' fill='%23DCDCDC'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}

		&--active {

			&::before {
				transform: rotate(180deg);
			}
		}
	}
}
