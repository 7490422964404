input,
textarea {
	font-family: $font-family;
	-webkit-appearance: none;
	border-radius: 0;

	&:focus {
		outline: none;
	}
}

.input-wrap {

	&--icon {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 20px;
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			transform: translateY(-50%);
		}

		&_search {

			&:after {
				background-image: url("../img/icons/icon-search.svg");
			}
		}
	}
}

// Main elements

.input-search {
	width: 100%;
	padding: 11px 50px 11px 30px;
	font-size: 16px;
	line-height: 24px;
	color: $text-color;
	border: 2px solid rgba($primary-green, 0.5);
	border-radius: 100px;
	transition-property: border-color, color;
	transition-duration: $transition-time;

	&:focus {
		border-color: $primary-green;
	}

	&::placeholder {
		font-size: 16px;
		line-height: 24px;
		color: $text-color-muted;
		transition: color $transition-time;
	}
}

.form-input,
.form-textarea {
	padding-left: 0;
	padding-bottom: 5px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: $text-color;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid $gray-dark;
	transition: color $transition-time,
	border-color $transition-time;

	@media (min-width: $screen-sm) {
		font-size: 16px;
		line-height: 24px;
	}

	&:active,
	&:focus {
		border-color: $gray-darken;
		outline: none;

		&::placeholder {
			color: rgba($gray-darken, 0.4);
		}
	}

	&::placeholder {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $gray-darken;
		transition: color $transition-time;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&:-webkit-autofill {
		-webkit-animation-name: autofill;
		-webkit-animation-fill-mode: both;
		-webkit-text-fill-color: $text-color;
	}

	&.invalid {
		color: $primary-red;
		border-color: rgba($primary-red, 0.5);

		&::placeholder {
			color: $primary-red;
		}
	}
}

.form-textarea {
	display: block;
	resize: none;
}

.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

	& + .input-file-label {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		min-width: 0;
		max-width: 100%;
		overflow: hidden;
		cursor: pointer;
	}
}

.input-file-label {
	$self: &;

	&:hover {

		#{$self}__icon {
			fill: none;
			stroke: $gray-darken;
		}

		#{$self}__text {
			color: $gray-darker;
		}
	}

	&:active {

		#{$self}__icon {
			fill: none;
			stroke: $gray-darken;
		}

		#{$self}__text {
			color: $text-color;
		}
	}

	&__icon-wrap {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 24px;
		margin-right: 8px;
	}

	&__icon {
		flex-shrink: 0;
		fill: none;
		stroke: $gray-dark;
		vertical-align: middle;
		transition: fill $transition-time;
	}

	&__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: $gray-darken;
		text-overflow: ellipsis;
/*		white-space: nowrap;*/
		overflow: hidden;
		transition: color $transition-time;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.agreement {

	&__text {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $gray-darken;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		color: $gray-darken;
	}
}

// fix autocomplete

@-webkit-keyframes autofill {
	to {
		background: transparent;
	}
}

// Select 2 Standard

.select2-container *:focus {
	outline: none;
}

// Select

.select2-container--default .custom-select {
	height: 40px;
	font-family: $font-family;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $text-color-muted;
	background-color: $white;
	border: 1px solid $gray-light;
	border-radius: 0;
}

.select2-container--default .custom-select .select2-selection__rendered {
	color: $text-color-muted;
}

.select2-container .custom-select .select2-selection__rendered {
	padding-left: 15px;
	padding-right: 56px;
	line-height: 38px;
}

.select2-container--default .custom-select .select2-selection__placeholder {
	font-family: $font-family;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $text-color-muted;
}

.select2-container--default .custom-select .select2-selection__arrow b {
	display: none;
}

.select2-container--default .custom-select .select2-selection__arrow {
	top: 0;
	right: 14px;
	width: 16px;
	height: 100%;

	&::after {
		@extend	.arrow;
	}
}

.select2-container--default.select2-container--open .custom-select .select2-selection__arrow {

	&::after {
		@extend	.arrow--up;
	}
}

// Select 2 Dropdown

.custom-dropdown {
	border: 1px solid $gray-light;
	border-radius: 2px;
}

.custom-dropdown .select2-results__option {
	padding: 10px 15px;
	font-family: $font-family;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $text-color-muted;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $tertiary-sea;
	color: $text-color-muted;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: rgba($primary-sea, 0.4);
}

// Select 2 Standard Theme Dark

.select2-container--default .custom-select--theme_dark {
	height: 40px;
	font-family: $font-family;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $text-color-muted;
	background-color: $gray-lighten;
	border: 1px solid $gray-lighten;
	border-radius: 0;
}

.select2-container--default .custom-select--theme_dark .select2-selection__rendered {
	color: $text-color-muted;
}

.select2-container .custom-select--theme_dark .select2-selection__rendered {
	padding-left: 15px;
	padding-right: 56px;
	line-height: 38px;
}

.select2-container--default .custom-select--theme_dark .select2-selection__placeholder {
	font-family: $font-family;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $text-color-muted;
}

.select2-container--default .custom-select--theme_dark .select2-selection__arrow b {
	display: none;
}

.select2-container--default .custom-select--theme_dark .select2-selection__arrow {
	top: 0;
	right: 14px;
	width: 16px;
	height: 100%;

	&::after {
		@extend	.arrow;
		@extend	.arrow--theme_darkgray;
	}
}

.select2-container--default.select2-container--open .custom-select--theme_dark .select2-selection__arrow {

	&::after {
		@extend	.arrow--up;
	}
}

// Select 2 Dropdown

.custom-dropdown--theme_dark {
	border: 1px solid $gray-light;
	border-radius: 2px;
}

.custom-dropdown--theme_dark .select2-results__option {
	padding: 10px 15px;
	font-family: $font-family;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $text-color-muted;
}

// Multiple select

.select2-container--default .multiple-custom-select {
	min-height: auto;
	line-height: 1;
	background-color: white;
	border: none;
	border-bottom: 2px solid $gray-dark;
	border-radius: 0;
	cursor: pointer;
	transition: border-color $transition-time;

	&::after {
		@extend .arrow;
		top: auto;
		right: 3px;
		bottom: 12px;
		left: auto;
		border-right-color: $gray-dark;
		border-bottom-color: $gray-dark;
		transform-origin: center center;
		transform: rotate(45deg) translate(0);
	}
}

.select2-container--default.select2-container--focus .multiple-custom-select {
	border: none;
	border-bottom: 2px solid $gray-dark;
}

.select2-container--default.select2-container--open .multiple-custom-select {
	border-bottom: 2px solid $gray-darken;

	&::after {
		border-right-color: $primary-green;
		border-bottom-color: $primary-green;
		transform: rotate(-135deg) translate(0);
	}
}

.select2-container .multiple-custom-select .select2-selection__rendered {
	padding: 0;
	white-space: normal;
}

.select2-container--default .multiple-custom-select .select2-selection__choice {
	padding-top: 3px;
	padding-bottom: 3px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: $text-color;
	background-color: $gray-lighten;
	border-color: $gray-dark;
}

.select2-container--default .multiple-custom-select .select2-search--inline .select2-search__field {
	margin: 0;
	padding: 0 0 3px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: $text-color;
	transition: color $transition-time;

	@media (min-width: $screen-sm) {
		font-size: 16px;
		line-height: 24px;
	}

	&::placeholder {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $gray-darken;
		transition: color $transition-time;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.select2-container--default.select2-container--open .multiple-custom-select .select2-search--inline .select2-search__field {

	&::placeholder {
		color: rgba($gray-darken, 0.4);
	}
}

.multiple-custom-dropdown {
	border: none;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.select2-container--open .multiple-custom-dropdown {
	top: 8px;
}

.multiple-custom-dropdown .select2-results__options {
	padding: 12px 0 12px 16px;
}

.select2-container--default .multiple-custom-dropdown .select2-results__option--highlighted[aria-selected] {
	background: none;
}

.multiple-custom-dropdown .select2-results__option {
	position: relative;
	padding-left: 26px;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: $text-color;

	&::before {
		content: "";
		position: absolute;
		top: 7px;
		left: 0;
		width: 16px;
		height: 16px;
		border: 2px solid $gray-dark;
		transition: border-color $transition-time;
	}
}

.multiple-custom-dropdown .select2-results__option[aria-selected=true] {
	background: none;

	&::before {
		border-color: $primary-green;
	}

	&::after {
		content: "";
		position: absolute;
		top: 11px;
		left: 3px;
		width: 10px;
		height: 8px;
		background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.94444L4.03448 6L9 1' stroke='%2395C12B' stroke-width='2'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
}
