.news-promo {
	$self: &;
	margin: 0 -20px;

	@media (min-width: $screen-sm) {
		margin: 0;
	}

	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-sm) {
			flex-direction: row;
			flex-wrap: wrap;
			margin-bottom: -20px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: -40px;
		}
	}

	&__item {
		max-width: 400px;
		margin-bottom: 20px;

		@media (min-width: $screen-sm) {
			width: calc(50% - 10px);
			max-width: none;
			margin-right: 20px;
			margin-bottom: 20px;
		}

		@media (min-width: $screen-xl) {
			width: calc(50% - 20px);
			margin-right: 40px;
			margin-bottom: 40px;
		}

		&--theme_bordered {

			#{$self}__link {

				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: transparent;
					border: 15px solid $primary-green;
				}
			}

			#{$self}__img {
				display: none;
			}

			#{$self}__item-title {
				color: $text-color;
				border-bottom: 1px solid rgba($text-color, 0.35);
			}

			#{$self}__item-date {
				color: $text-color;
			}
		}

		&:nth-child(2n) {

			@media (min-width: $screen-sm) {
				margin-right: 0;
			}
		}
	}

	&__link {
		display: block;
		position: relative;
		width: 100%;
		padding-top: 47.7333%;
		height: 0;

		@media (min-width: $screen-xl) {
			padding-top: 35.9375%;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($black, 0.5);
		}

		&:hover {

			#{$self}__item-title {
				color: $primary-green;
				border-color: transparent;
			}
		}

		&:focus {}

		&:active {

			#{$self}__item-title {
				color: $secondary-green;
			}
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__item-title-wrap {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		padding: 25px;

		@media (min-width: $screen-md) {
			padding: 40px 30px;
		}

		@media (min-width: $screen-lg) {
			padding-right: 40px;
			padding-left: 40px;
		}

		@media (min-width: $screen-xxl) {
			padding: 50px;
		}
	}

	&__item-title {
		display: inline;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $white;
		border-bottom: 1px solid rgba($white, 0.35);
		transition: color $transition-time,
		border-color $transition-time;

		@media (min-width: $screen-md) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-lg) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-xl) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__item-date {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 25px;
		font-family: $font-family;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: $white;

		@media (min-width: $screen-md) {
			padding: 40px 30px;
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-lg) {
			padding-right: 40px;
			padding-left: 40px;
		}

		@media (min-width: $screen-xxl) {
			padding: 50px;
		}
	}
}
