.page-header {
	$self: &;
	position: fixed;
	top: 0;
	z-index: 20;
	width: 100%;
	background-color: $white;
	//background-color: rgba(red, 0.3);/* temp style */

	&--fixed {
		position: fixed;
		top: 0;
		z-index: 20;
		width: 100%;
		background: $white;
		border-bottom: 1px solid $gray-light;

		#{$self}__contacts--top {
			display: none;
		}

		#{$self}__controls--top {
			align-self: center;
		}
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0;

		@media (min-width: $screen-lg) {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}

	&__logo {
		flex-shrink: 0;
		position: relative;
		z-index: 3;
		width: 231px;
		height: 47px;

		@media (min-width: $screen-lg) {
			width: 268px;
			height: 55px;
		}

		@media (min-width: $screen-xxl) {
			width: 390px;
			height: 80px;
		}
	}

	&__content {

		@media (min-width: $screen-lg) {
			padding-left: 35px;
		}

		@media (min-width: $screen-xl) {
			padding-left: 60px;
		}
	}

	&__nav {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-color: $white;
		overflow-y: auto;
		padding-top: 67px;

		@media (min-width: $screen-sm) {
			right: 0;
			left: auto;
			width: 375px;
			border-left: 1px solid $gray-light;
		}

		@media (min-width: $screen-md) {
			width: 40%;
		}

		@media (min-width: $screen-lg) {
			display: block;
			position: relative;
			top: auto;
			left: auto;
			width: auto;
			height: auto;
			padding: 0;
			border: none;
			overflow: hidden;
		}
	}

	&__nav-inner {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		height: 100%;
		padding: 35px 20px 60px 20px;
		overflow-y: auto;

		@media (min-width: $screen-sm) {
			padding-right: 30px;
			padding-bottom: 50px;
			padding-left: 30px;
		}

		@media (min-width: $screen-md) {
			padding-top: 45px;
			padding-bottom: 40px;
		}

		@media (min-width: $screen-lg) {
			padding: 0;
		}
	}

	&__contacts {

		&--top {
			display: none;

			@media (min-width: $screen-md) {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
				margin-right: auto;
				margin-left: 40px;
			}

			@media (min-width: $screen-lg) {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				margin-bottom: 10px;
				margin-left: 0;
			}

			@media (min-width: $screen-xl) {
				margin-bottom: 15px;
			}
		}

		&--bottom {
			margin-bottom: 40px;

			@media (min-width: $screen-md) {
				display: none;
			}
		}
	}

	&__social {
		margin-bottom: 15px;

		@media (min-width: $screen-md) {
			margin-right: 40px;
			margin-bottom: 0;
		}
	}

	&__phone-link {
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		white-space: nowrap;
		color: $gray-darken;

		@media (min-width: $screen-md) {
			font-size: 14px;
			line-height: 20px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__controls {
		margin-bottom: 30px;

		&--bottom {

			@media (min-width: $screen-sm) {
				display: none;
			}
		}

		&--top {
			display: none;

			@media (min-width: $screen-sm) {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
				position: relative;
				z-index: 3;
				margin-right: 30px;
				margin-bottom: 0;
				margin-left: auto;
			}

			@media (min-width: $screen-lg) {
				align-self: flex-start;
				margin-right: 0;
			}
		}
	}

	&__order {
		margin-bottom: 15px;

		@media (min-width: $screen-sm) {
			margin-right: 20px;
			margin-bottom: 0;
		}

		@media (min-width: $screen-xxl) {
			margin-right: 40px;
		}
	}

	&__order-btn {
		min-width: 175px;

		@media (min-width: $screen-xxl) {
			min-width: 230px;
			padding: 12px 20px;
		}
	}

	&__lang-link {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-transform: uppercase;
		color: $gray-darken;

		@media (min-width: $screen-sm) {
			font-size: 14px;
			line-height: 20px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__policy {
		margin-top: auto;

		@media (min-width: $screen-lg) {
			display: none;
		}
	}

	&__policy-link {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $gray-darken;
	}

	&__menu-toggle {
		z-index: 2;
		font-size: 0;

		@media (min-width: $screen-lg) {
			display: none;
		}
	}
}
