.p-mobile-filter {
	$self: &;
	position: relative;
	padding-right: 20px;
	padding-bottom: 10px;
	padding-left: 20px;
	border-bottom: 1px solid #ebebeb;

	&__toggle {
		display: inline-block;
		cursor: pointer;

		&.is-opened {

			#{$self}__title {

				&::after {
					transform: rotate(-135deg) translateY(0);
				}
			}
		}
	}

	&__icon {
		margin-right: 5px;
		fill: none;
		stroke: $gray-dark;
	}

	&__title {
		position: relative;
		padding-right: 16px;
		font-size: 12px;
		line-height: 18px;
		color: $text-color-muted;

		@extend .arrow;

		&::after {
			top: calc(50% - 4px);
			left: auto;
			right: 0;
			width: 6px;
			height: 6px;
			transform-origin: center center;
			border-right: 3px solid $gray-dark;
			border-bottom: 3px solid $gray-dark;
			transform: rotate(45deg) translateY(0);
		}
	}

	&__list {
		display: none;
		position: absolute;
		top: 100%;
		left: 20px;
		margin: 0;
		padding: 0;
		list-style: none;
		background-color: $white;
		box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
	}

	&__item {
		padding: 8px;
		font-size: 12px;
		line-height: 18px;
		color: $text-color-muted;
		cursor: pointer;
		background-color: $white;
		transition: background-color;
		user-select: none;

		&:active {
			background-color: $tertiary-sea;
		}

		&--active {
			background-color: $tertiary-sea;
		}
	}
}
