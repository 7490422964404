.info-management {
	$self: &;

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
		@include col(sm, 5, 10);
		@include col(md, 6, 12);

		&--content {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
		}
	}

	&__img-wrap {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-end;
		align-items: center;
		height: 100%;
		margin: 0 -20px;
		padding-top: 25px;
		text-align: center;
		background-color: $gray-lighter;

		@media (min-width: $screen-sm) {
			margin: 0;
			padding-top: 30px;
			background-color: transparent;
		}

		@media (min-width: $screen-lg) {
			padding-top: 90px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 30px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 110px;
		}
	}

	&__img {
		flex-shrink: 0;
		max-height: 281px;
		max-width: 100%;

		@media (min-width: $screen-sm) {
			max-height: 309px;
		}

		@media (min-width: $screen-md) {
			max-height: 388px;
		}

		@media (min-width: $screen-lg) {
			max-height: 405px;
		}

		@media (min-width: $screen-xl) {
			max-height: 461px;
		}

		@media (min-width: $screen-xxl) {
			max-height: 551px;
		}
	}

	.team-card {
		margin: 0 -20px;
		padding: 20px;
		text-align: left;

		@media (min-width: $screen-sm) {
			margin: 0;
			padding: 30px 10px;
		}

		@media (min-width: $screen-md) {
			margin: 0;
			padding: 60px 30px;
		}

		@media (min-width: $screen-lg) {
			padding: 60px 50px;
		}

		@media (min-width: $screen-xxl) {
			padding-right: 60px;
			padding-left: 60px;
		}
	}
}
