.building {
	$self: &;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100%;

	&__img-bottom,
	&__img-top,
	&__img-mobile {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: auto;
		transform: translateY(-50%);
	}

	&__img-top {
		z-index: 1;
		transition: transform 1s ease-in-out;
		will-change: transform;
		cursor: pointer;

		@media (min-width: $screen-sm) {
			display: none;
		}

		@media (min-width: $screen-lg) {
			display: block;
		}

		&:hover {

			@media (min-width: $screen-lg) {
				transform: translateY(-50%) scale(1.03);
			}
		}

		&.is-hidden {
			display: none;
		}
	}

	&__img-mobile {
		display: none;
		z-index: 1;

		@media (min-width: $screen-sm) {
			display: block;
		}

		@media (min-width: $screen-lg) {
			display: none;
		}
	}

	&__list {
		display: none;
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-lg) {
			display: block;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		position: absolute;
		pointer-events: none;

		&:nth-child(1) {
			top: 30%;
			left: 16%;

			@media (min-width: $screen-xxl) {
				top: 32%;
			}
		}

		&:nth-child(2) {
			top: 49%;
			left: 16%;
		}

		&:nth-child(3) {
			top: 67%;
			left: 16%;

			@media (min-width: $screen-xxl) {
				top: 65%;
			}
		}

		&:nth-child(4) {
			top: 28%;
			left: 54.5%;
		}

		&:nth-child(5) {
			top: 49%;
			left: 69%;
		}

		&:nth-child(6) {
			top: 30%;
			left: 76%;

			@media (min-width: $screen-xxl) {
				top: 32%;
			}
		}

		&:nth-child(7) {
			top: 30%;
			left: 86.5%;

			@media (min-width: $screen-xxl) {
				top: 32%;
			}
		}

		&:nth-child(8) {
			top: 30%;
			left: 94.5%;

			@media (min-width: $screen-xxl) {
				top: 32%;
			}
		}

		&:nth-child(9) {
			top: 67%;
			left: 83%;

			@media (min-width: $screen-xxl) {
				top: 65%;
			}
		}

		&:nth-child(10) {
			top: 67%;
			left: 54.5%;

			@media (min-width: $screen-xxl) {
				top: 65%;
			}
		}

		&--hidden {

			#{$self}__btn {
				visibility: hidden;
			}
		}
	}

	&__tooltip {
		display: none;
		position: absolute;
		bottom: 65px;
		left: 50%;
		z-index: 1;
		width: 260px;
		padding: 10px 15px;
		text-align: center;
		background: $white;
		box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
		transform: translateX(-50%);
		pointer-events: auto;

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			width: 0;
			height: 0;
			margin-left: -20px;
			border: solid transparent;
			border-color: rgba(255, 255, 255, 0);
			border-top-color: #ffffff;
			border-width: 20px;
			pointer-events: none;
		}
	}

	&__btn {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		box-shadow: 0 10px 5px 0px rgba(0,0,0,0.1);
		overflow: hidden;
		transition-property: transform;
		transition-duration: 1s;
		will-change: transform;
		user-select: none;

		@media (min-width: $screen-lg) {
			width: 40px;
			height: 40px;
		}

		@media (min-width: $screen-xxl) {
			width: 55px;
			height: 55px;
		}

		&--hidden {
			visibility: hidden;
		}
	}

	&__btn-icon {
		width: 20px;

		@media (min-width: $screen-lg) {
			width: 40px;
		}

		@media (min-width: $screen-xxl) {
			width: 55px;
		}
	}

	&__close {
		position: absolute;
		top: 8px;
		right: 8px;
	}
}

.building-segment-bottom {
	fill: transparent;
	transition: fill $transition-time;
	cursor: pointer;

	&:hover {

		@media (min-width: $screen-lg) {
			fill: rgba($primary-green, 0.2);
		}
	}
}

.building-list {
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 15px;

		@media (min-width: $screen-lg) {
			margin-bottom: 20px;
		}
	}

	&__img {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		margin-right: 15px;

		@media (min-width: $screen-sm) {
			width: 24px;
			height: 24px;
		}

		@media (min-width: $screen-lg) {
			width: 26px;
			height: 26px;
		}
	}

	&__link-wrap {}

	&__link {
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-md) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-lg) {
			font-size: 18px;
			line-height: 26px;
		}

		&:not([href]) {
			border: none;
		}
	}
}
