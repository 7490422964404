@at-root {

  @-ms-viewport {
    width: device-width;
  }
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-regular.woff2') format('woff2'),
			 url('../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-600.woff2') format('woff2'),
			 url('../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-600.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-700.woff2') format('woff2'),
			 url('../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-700.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

.page {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%;

  &__inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__content {
    flex-grow: 1;
		padding-top: 74px;

		@media (min-width: $screen-lg) {
			padding-top: 90px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 95px;
		}

		@media (min-width: $screen-xxl) {
			padding-top: 130px;
		}
  }

  &__footer-wrapper {
    flex-shrink: 0;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;

	&:focus {
		outline: none;
	}
}

body {
  display: flex;           // Исправляем баг в IE для min-height and flexbox (flex-direction:column)
  flex-direction: column;  // и прижимаем footer в IE 10-11
  margin: 0;
  min-height: 100%;
  //word-break: break-word;
	font-weight: 400;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  text-align: left;
  color: $text-color;
  background-color: $body-bg;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none;
  }

  &:not(:root) {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
	vertical-align: middle;
}

a {
  color: $text-color;
  background-color: transparent;
	text-decoration: none;
  transition: color $transition-time,
							border-color $transition-time;

  &:hover {
    color: $link-color--hover;
  }

	&:focus {}

	&:not([class]) {
		border-bottom: 1px solid rgba($primary-sea, 0.5);

		&:hover {
			border-color: transparent;
		}

		&:focus {}

		&:active {
			color: $secondary-sea;
		}
	}

  &[href^="tel:"] {

    @media (min-width: $screen-lg) {
      white-space: nowrap;
      pointer-events: none;
    }
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: $typo-margin-vertical * 2;
  margin-bottom: $typo-margin-vertical * 2;
  border: 0;
  border-top: 1px solid $border-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: $font-family;
  font-weight: 700;
  line-height: 1.2;
  color: currentColor;
}

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: $typo-margin-vertical;
}

p {
/*  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;*/
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: $typo-margin-vertical;
  padding-left: 1.5em;

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

li {
  line-height: inherit;
}

// dt {
//   font-weight: 700;
// }

// dd {
//   margin-left: 0;

//   @media (min-width: $screen-sm) {
//     margin-left: 1.5rem;
//   }

//   & + dt {
//     margin-top: ($line-height / 2);
//   }
// }

// .small,
small {
  font-size: $font-size-sm;
}

sub,
sup {
  font-size: $font-size-sm;
}

// .mark,
// mark {
//   background: rgba($color-warning, 0.3);
//   padding: 0.1em 0.3em;
// }

b,
strong {
  font-weight: bolder;
}

// abbr[title] {
//   text-decoration: underline dotted;
// }

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

// samp,
code,
kbd,
pre {
  font-family: $font-family--monospace;
}

code {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: rgba(#000, 0.08);
  border-radius: $border-radius;
}

kbd {
  padding: 0.06em 0.3em;
  color: $text-color;
  //background-color: $gray-lightest;
  border-radius: $border-radius;

  kbd {
    padding: 0;
  }
}

pre {
  display: block;
  width: 100%;
  margin-bottom: $typo-margin-vertical;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(#000, 0.08);

  code {
    background: transparent;
  }
}

.visuallyhidden {
	@include visuallyhidden();
}
