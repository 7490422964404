.dual-slider {
	$self: &;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	width: 100%;
	overflow: hidden;

	@media (min-width: $screen-sm) {
		flex-direction: row;
	}

	&__first {
		position: relative;
		flex-shrink: 0;
		width: 100%;
		height: 260px;
		background-color: $gray-lighter;

		@media (min-width: $screen-sm) {
			width: 50%;
			height: auto;
		}
	}

	&__second {
		flex-shrink: 0;
		width: 100%;
		background-color: $gray-lighter;

		@media (min-width: $screen-sm) {
			width: 50%;
		}
	}




	&__nav {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 48px;
		border: 1px solid rgba($gray-lighten, 0.5);
		border-right: none;
		border-left: none;
		overflow: hidden;
	}

	&__counter {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 100px;
		border-right: 1px solid rgba($gray-lighten, 0.5);
	}

	&__counter-wrap {
		font-size: 14px;
		line-height: 24px;
		color: $gray;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 26px;
		}

		@media (min-width: $screen-md) {
			font-size: 20px;
			line-height: 26px;
		}
	}

	&__arrows {
		position: relative;
		width: 100%;
	}
}

.dual-slider-text {
	$self: &;
	position: relative;
	width: 100%;
	overflow: hidden;

	&__item {
		box-sizing: border-box;
	}

	&__content {
		padding: 30px 20px;

		@media (min-width: $screen-sm) {
			padding-top: 75px;
			padding-bottom: 75px;
		}

		@media (min-width: $screen-md) {
			padding: 60px 25px;
		}

		@media (min-width: $screen-lg) {
			padding: 70px 40px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 90px;
			padding-bottom: 90px;
		}

		@media (min-width: $screen-xxl) {
			padding: 160px 160px 160px 70px;
		}
	}

	&__counter {
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 48px;
		line-height: 56px;
		color: $gray;

		@media (min-width: $screen-md) {
			font-size: 60px;
			line-height: 70px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 30px;
			font-size: 96px;
			line-height: 96px;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;

		@media (min-width: $screen-lg) {
			margin-bottom: 15px;
			font-size: 20px;
			line-height: 28px;
		}

		@media (min-width: $screen-xl) {
			font-size: 24px;
			line-height: 32px
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 20px;
		}
	}

	&__text {
		@extend .paragraph-limit;

		p {
			@extend .content-text;
		}
	}

	&__nav {
		position: absolute;
		top: 30px;
		right: 20px;
		width: 62px;
		height: 23px;

		@media (min-width: $screen-md) {
			top: 40px;
		}

		@media (min-width: $screen-lg) {
			top: 40px;
			right: 40px;
		}

		@media (min-width: $screen-xl) {
			right: 60px;
		}

		@media (min-width: $screen-xxl) {
			top: 70px;
			right: 160px;
		}
	}

	&__btn-prev {
		left: 0;
	}

	&__btn-next {
		right: 0;
	}
}

.dual-slider-image {
	$self: &;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&__item {
		position: relative;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		overflow: hidden;
	}

	&__img-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}
}
