.tabs {

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__filter {
		width: 100%;
		max-width: 500px;

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__list {
		display: none;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-md) {
			display: flex;
		}
	}

	&__item {
		display: block;
		margin: -1px 0 0 -1px;
		padding: 10px 20px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: $text-color-muted;
		border: 1px solid $gray-light;
		cursor: pointer;
		user-select: none;

		@media (min-width: $screen-xxl) {
			padding-top: 15px;
			padding-bottom: 15px;
			font-size: 16px;
			line-height: 24px;
		}

		&--current {
			color: $white;
			background-color: $primary-sea;

			&:hover {
				color: $white;
			}
		}
	}

	&__content {
		display: none;

		&--current {
			display: block;
		}
	}
}
