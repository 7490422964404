.number-slide {
	text-align: center;
	user-select: none;

	@media (min-width: $screen-sm) {
		text-align: left;
	}

	&__count {
		margin-bottom: 5px;
		font-weight: 600;
		font-size: 40px;
		line-height: 56px;
		color: $primary-green;

		@media (min-width: $screen-md) {
			font-size: 60px;
			line-height: 70px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 10px;
			font-size: 96px;
			line-height: 96px;
		}
	}

	&__count-tip {
		font-size: 16px;
		line-height: 24px;
		white-space: nowrap;

		@media (min-width: $screen-md) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-lg) {
			font-size: 24px;
			line-height: 32px;
		}
	}

	&__text {
		@extend .content-text;
		margin: 0;
	}
}
