.last-news {

	@media (min-width: $screen-lg) {
		padding-left: 60px;
	}

	@media (min-width: $screen-xl) {
		padding-left: 0;
	}

	&__title {
		margin-top: 0;
		margin-bottom: 20px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $screen-lg) {
			margin-bottom: 30px;
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin-bottom: 20px;

		@media (min-width: $screen-lg) {
			margin-bottom: 50px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link-wrap {
		margin-bottom: 5px;

		@media (min-width: $screen-sm) {
			margin-bottom: 10px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 20px;
		}
	}

	&__link {
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-lg) {
			font-size: 18px;
			line-height: 26px;
		}
	}

	&__date {
		display: block;
		font-size: 14px;
		line-height: 20px;
		color: $gray-darker;
	}
}
