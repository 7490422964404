// Arrows

.swiper-button-next,
.swiper-button-prev {
	top: 50%;
	width: 25px;
	height: 30px;
	margin-top: 0;
	background: transparent;
	border-radius: 0;
	transform: translateY(-50%);
	outline: none;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		width: 16px;
		height: 16px;
		border-right: 3px solid $primary-green;
		border-bottom: 3px solid $primary-green;
		transform-origin: top left;
		transition: border-color $transition-time;
	}

	&:hover,
	&:active {

		&::after {
			border-right-color: $secondary-green;
			border-bottom-color: $secondary-green;
		}
	}

	&.swiper-button-disabled {
		opacity: 1;

		&::after {
			border-right-color: rgba($primary-green, 0.5);
			border-bottom-color: rgba($primary-green, 0.5);
		}
	}

	&--theme_sea {

		&::after {
			border-right: 3px solid $primary-sea;
			border-bottom: 3px solid $primary-sea;
		}

		&:hover,
		&:active {

			&::after {
				border-right-color: $secondary-sea;
				border-bottom-color: $secondary-sea;
			}
		}

		&.swiper-button-disabled {

			&::after {
				border-right-color: rgba($primary-sea, 0.5);
				border-bottom-color: rgba($primary-sea, 0.5);
			}
		}

	}

	&--theme_gray {

		&::after {
			border-right: 3px solid $gray-dark;
			border-bottom: 3px solid $gray-dark;
		}

		&:hover,
		&:active {

			&::after {
				border-right-color: $gray-darken;
				border-bottom-color: $gray-darken;
			}
		}

		&.swiper-button-disabled {

			&::after {
				border-right-color: rgba($gray-dark, 0.5);
				border-bottom-color: rgba($gray-dark, 0.5);
			}
		}

	}

	&--theme_white {

		&::after {
			border-right: 3px solid $white;
			border-bottom: 3px solid $white;
		}

		&:hover,
		&:active {

			&::after {
				border-right-color: rgba($white, 0.8);
				border-bottom-color: rgba($white, 0.8);
			}
		}

		&.swiper-button-disabled {

			&::after {
				border-right-color: rgba($white, 0.5);
				border-bottom-color: rgba($white, 0.5);
			}
		}

	}
}

.swiper-button-next {
	right: 20px;

	&::after {
		left: 50%;
		transform: rotate(-45deg) translate(-50%, -50%);
	}
}

.swiper-button-prev {
	left: 20px;

	&::after {
		left: 50%;
		transform: rotate(135deg) translate(-50%, -50%);
	}
}

// Pagination

.swiper-pagination-bullets {
	bottom: -11px;
	left: 50%;
	font-size: 0;
	transform: translateX(-50%);

	@media (min-width: $screen-sm) {
		bottom: -16px;
	}

	@media (min-width: $screen-md) {
		bottom: -21px;
	}

	@media (min-width: $screen-lg) {
		bottom: -20px;
	}

	@media (min-width: $screen-xl) {
		bottom: -30px;
	}
}

.swiper-pagination-bullets .swiper-pagination-bullet {
	position: relative;
	width: 6px;
	height: 6px;
	margin: 0 3px;
	background-color: rgba($primary-green, 0.25);
	opacity: 1;
	transition: background-color $transition-time;

	@media (min-width: $screen-lg) {
		width: 10px;
		height: 10px;
		margin: 0 5px;
	}
}

.swiper-pagination-bullets .swiper-pagination-bullet-active {
	position: relative;
	background-color: rgba($primary-green, 0.5);
}
