.review-card {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	@media (min-width: $screen-sm) {
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	&__img-wrap {
		flex-shrink: 0;
		height: 50px;
		margin-bottom: 20px;

		@media (min-width: $screen-sm) {
			height: 68px;
			margin-right: 30px;
		}

		@media (min-width: $screen-md) {
			height: 86px;
		}

		@media (min-width: $screen-lg) {
			height: 104px;
			margin-right: 40px;
		}

		@media (min-width: $screen-xl) {
			margin-right: 60px;
		}

		@media (min-width: $screen-xxl) {
			margin-right: 100px;
		}
	}

	&__img {
		max-height: 100%;
	}
}
