.main-banner {
	$self: &;
	position: relative;
	width: 100%;
	height: 192px;
	color: $white;

	@media (min-width: $screen-sm) {
		height: 256px;
	}

	@media (min-width: $screen-md) {
		height: 320px;
	}

	@media (min-width: $screen-xl) {
		height: 360px;
	}

	@media (min-width: $screen-xxl) {
		height: 480px;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.3);
	}

	&--img-control {

		#{$self}__img {
			display: none;

			&--current {
				display: block;
			}
		}

		#{$self}__title {
			display: none;

			&--current {
				display: block;
			}

			&--hidden {
				display: none;
			}
		}
	}

	&--interactive {

		&::after {
			content: none;
		}

		#{$self}__img {
			object-fit: none;
			font-family: $font-family;
		}
	}

	&__img,
	&__primary-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__img {

		&--overlay {

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($black, 0.3);
			}
		}
	}

	&__heading {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
	}

	&__container {
		@include container();
		height: 100%;
	}

	&__row {
		@include row();
		height: 100%;
	}

	&__col {
		@include col(xs, 12, 12);
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		height: 100%;
	}

	&__title {
		margin: 0;
	}
}
