.team-card {
	$self: &;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	text-align: center;

	@media (min-width: $screen-sm) {
		flex-direction: row;
		text-align: left;
	}

	&--expert {

		#{$self}__img-wrap {

			@media (min-width: $screen-sm) {
				margin-right: 15px;
			}

			@media (min-width: $screen-md) {
				width: 160px;
				height: 160px;
				margin-right: 20px;
			}

			@media (min-width: $screen-lg) {
				width: 200px;
				height: 200px;
				margin-right: 40px;
			}

			@media (min-width: $screen-xl) {
				width: 240px;
				height: 240px;
			}
		}

		#{$self}__name {
			font-size: 16px;
			line-height: 24px;

			@media (min-width: $screen-md) {
				font-size: 18px;
				line-height: 26px;
			}

			@media (min-width: $screen-lg) {
				font-size: 24px;
				line-height: 32px;
			}
		}
	}

	&__img-wrap {
		flex-shrink: 0;
		width: 120px;
		height: 120px;
		margin-bottom: 15px;
		border-radius: 50%;
		overflow: hidden;

		@media (min-width: $screen-sm) {
			margin-right: 30px;
			margin-bottom: 0;
		}

		@media (min-width: $screen-md) {
			width: 230px;
			height: 230px;
			margin-right: 40px;
		}

		@media (min-width: $screen-lg) {
			width: 250px;
			height: 250px;
		}
	}

	&__name {
		display: block;
		margin-bottom: 5px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: $primary-sea;

		@media (min-width: $screen-sm) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-md) {
			margin-bottom: 10px;
			font-size: 20px;
			line-height: 28px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 15px;
			font-size: 24px;
			line-height: 32px;
		}
	}

	&__position {
		display: block;
		max-width: 80ch;
		margin-bottom: 10px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $text-color;

		@media (min-width: $screen-sm) {
			margin-bottom: 15px;
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-md) {
			margin-bottom: 25px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 30px;
			font-size: 18px;
			line-height: 26px;
		}
	}

	&__links {
		margin-bottom: 15px;

		@media (min-width: $screen-md) {
			margin-bottom: 30px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 35px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 40px;
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 50px;
		}
	}

	&__link-wrap {
		display: block;
		white-space: nowrap;

		+ #{$self}__link-wrap {
			margin-top: 10px;
		}
	}

	&__link,
	&__link-tip {
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		color: $text-color;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-sm) {
			font-size: 18px;
			line-height: 26px;
		}
	}
}
