.btn {
	display: inline-block;
	min-width: 164px;
	max-width: 100%;
	margin: 0;
	padding: 12px 20px;
	font-family: $font-family;
	font-weight: 600;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	color: $primary-sea;
	background-color: transparent;
	border: 1px solid $primary-sea;
	border-radius: 100px;
	vertical-align: middle;
	overflow: hidden;
	transition: color, background-color, border-color;
	transition-duration: $transition-time;
	user-select: none;
	cursor: pointer;

	&:hover {
		color: $white;
		background-color: $primary-green;
		border-color: $primary-green;
	}

	&:focus {}

	&:active {
		color: $white;
		background-color: $secondary-green;
		border-color: $secondary-green;
	}

	&:disabled {
		opacity: 0.65;
		color: $primary-sea;
		cursor: default;

		&:hover,
		&:active {
			color: $primary-sea;
			background-color: transparent;
			border-color: $primary-sea;
		}
	}

	&--theme_filled {
		color: $white;
		background-color: $primary-sea;
		border-color: $primary-sea;

		&:disabled {
			opacity: 0.65;
			color: $white;
			cursor: default;

			&:hover,
			&:active {
				color: $white;
				background-color: $primary-sea;
				border-color: $primary-sea;
			}
		}
	}

	&--size_small {
		padding-top: 9px;
		padding-bottom: 10px;
	}

	&--size_big {
		padding-top: 16px;
		padding-bottom: 16px;
		font-size: 16px;
	}

	&--size_adaptive {

		@media (min-width: $screen-md) {
			padding-top: 16px;
			padding-bottom: 16px;
			font-size: 16px;
		}
	}
}

.btn-close {
	position: relative;
	width: 30px;
	height: 30px;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	cursor: pointer;

	&:hover {

		&::before,
		&::after {
			background-color: rgba($white, 0.7);
		}
	}

	&:focus {
		outline: none;
	}

	&:active {

		&::before,
		&::after {
			background-color: $white;
		}
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0;
		width: 30px;
		height: 2px;
		background-color: $white;
		transition: background-color $transition-time;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}

	&--size_small {
		width: 12px;
		height: 12px;

		&::before,
		&::after {
			width: 12px;
			height: 1px;
		}
	}

	&--theme_gray {

		&::before,
		&::after {
			background-color: $gray-darken;
		}

		&:hover {

			&::before,
			&::after {
				background-color: rgba($gray-darken, 0.7);
			}
		}

		&:active {

			&::before,
			&::after {
				background-color: $gray-darken;
			}
		}

	}
}
