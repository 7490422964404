.service-menu {
	padding-top: 20px;

	@media (min-width: $screen-md) {
		padding-top: 30px;
	}

	@media (min-width: $screen-lg) {
		padding-top: 40px;
	}

	@media (min-width: $screen-xxl) {
		padding-top: 80px;
	}
}

.service-sub-menu {

	.tabs {
		padding-top: 45px;

		@media (min-width: $screen-sm) {
			padding-top: 55px;
		}

		@media (min-width: $screen-md) {
			padding-top: 70px;
		}

		@media (min-width: $screen-lg) {
			padding-top: 80px;
		}

		@media (min-width: $screen-xl) {
			padding-top: 120px;
		}
	}

	.tabs__list {
		display: flex;
	}
}
