.info-block-wrap {
	padding-top: 20px;

	@media (min-width: $screen-sm) {
		padding-top: 30px;
	}

	@media (min-width: $screen-lg) {
		padding-top: 40px;
	}
}

.info-block {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	@media (min-width: $screen-sm) {
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}

	&__img-wrap {
		width: 80px;
		height: 80px;
		margin-bottom: 10px;

		@media (min-width: $screen-sm) {
			width: 100px;
			height: 100px;
			margin-right: 20px;
			margin-bottom: 0;
		}
	}

	&__content {

		:last-child {
			margin-bottom: 0;
		}

		p {
			max-width: 60ch;
			@extend .content-text;
		}
	}
}
