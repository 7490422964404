.history-slider {
	$self: &;

	&__item {
		box-sizing: border-box;
	}

	&__header {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		width: 100%;

		@media (min-width: $screen-sm) {
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
		}

		@media (min-width: $screen-md) {
			margin-bottom: 10px;
		}
	}

	&__year {
		flex-shrink: 0;
		font-weight: 500;
		font-size: 36px;
		line-height: 48px;
		white-space: nowrap;
		color: $primary-sea;
		user-select: none;

		@media (min-width: $screen-md) {
			font-size: 50px;
			line-height: 60px;
		}

		@media (min-width: $screen-xl) {
			font-size: 60px;
			line-height: 70px;
		}
	}

	&__line {
		display: none;
		position: relative;
		width: 100%;
		height: 100%;
		user-select: none;

		@media (min-width: $screen-sm) {
			display: flex;
		}

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: calc(100% - 20px);
			height: 2px;
			background-color: $primary-sea;
			transform: translate(-50%, -50%);
		}
	}

	&__text {
		max-width: 480px;
		margin: 0 auto;
		text-align: center;

		@media (min-width: $screen-sm) {
			max-width: none;
			margin: 0;
			padding-right: 56px;
			text-align: left;
		}

		@media (min-width: $screen-lg) {
			padding-right: 88px;
		}

		@media (min-width: $screen-xl) {
			padding-right: 72px;
		}

		p	{
			@extend .slider-content;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__btn-prev {
		left: -5px;

		@media (min-width: $screen-sm) {
			top: -30px;
			left: auto;
			right: 35px;
		}

		@media (min-width: $screen-md) {
			right: 40px;
		}

		@media (min-width: $screen-lg) {
			top: -40px;
		}

		@media (min-width: $screen-xxl) {
			top: -50px;
		}
	}

	&__btn-next {
		right: -5px;

		@media (min-width: $screen-sm) {
			top: -30px;
			right: 0;
		}

		@media (min-width: $screen-lg) {
			top: -40px;
		}

		@media (min-width: $screen-xxl) {
			top: -50px;
		}
	}
}
