.modal {
	display: none;
	width: 100%;
	height: auto;
	padding: 0;
	background-color: transparent;

	&__inner {
		padding-top: 65px;
		padding-bottom: 65px;

		@media (min-width: $screen-sm) {

		}
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12 ,12);
		@include col(md, 8 ,12);
/*		@include col(xl, 6 ,12);*/
		@include col-offset(md, 2 ,12);
/*		@include col-offset(xl, 3 ,12);*/
	}

	&__content {
		margin: 0 -20px;
		padding: 0 20px;
		background-color: $white;

		@media (min-width: $screen-md) {
			margin: 0;
			padding: 0;
		}
	}
}

.modal-form {

	.fancybox-slide {
		padding: 0;
	}
}

.modal-order-block {
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;

	@media (min-width: $screen-sm) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	&__form {}

	&__title,
	&__success-title {
		padding-top: 0;
		margin-bottom: 40px;
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		color: $text-color;

		@media (min-width: $screen-sm) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__success {
		display: none;
	}

	&__success-title {
		margin: 0;
	}

	&__btn-close {
		position: absolute;
		top: -50px;
		right: 0;

		@media (min-width: $screen-md) {
			right: 10px;
		}
	}
}
