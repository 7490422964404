.steps-slider-wrapper {
	position: relative;
	width: 100%;
	padding-right: 25px;
	padding-left: 25px;

	@media (min-width: $screen-sm) {
		padding-right: 0;
		padding-left: 0;
	}
}

.steps-slider {
	$self: &;

	&__item {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		width: auto;
		height: auto;
		box-sizing: border-box;

		@media (min-width: $screen-sm) {
			align-items: center;
			width: 265px;
			padding-right: 65px;
		}

		&::after {
			content: "";
			position: absolute;
		}

		&:first-child {

			@media (min-width: $screen-sm) {
				align-items: flex-start;
				width: 205px;
				padding-right: 30px;
			}

			#{$self}__item-inner {

				@media (min-width: $screen-sm) {
					align-items: flex-start;
				}
			}

			#{$self}__text {

				@media (min-width: $screen-sm) {
					padding: 0;
					text-align: left;
				}
			}
		}

		&:last-child {

			@media (min-width: $screen-sm) {
				padding-right: 0;
			}

			#{$self}__step-wrap {

				&::after {

					@media (min-width: $screen-sm) {
						width: 50%;
					}
				}
			}
		}
	}

	&__item-inner {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	&__step-wrap {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 60px;
		margin-bottom: 15px;
		background-color: $white;
		border: 3px solid $primary-sea;
		border-radius: 50%;
		overflow: hidden;
		user-select: none;

		&::after {
			content: none;
			position: absolute;
			top: 28px;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 3px;
			background-color: $primary-sea;

			@media (min-width: $screen-sm) {
				content: "";
			}
		}
	}


	&__step {
		flex-shrink: 0;
		font-weight: 600;
		font-size: 32px;
		line-height: 42px;
		color: $primary-sea;
	}


	&__text {
		@extend .paragraph-limit;
		text-align: center;

		p {
			@extend .slider-content;
		}
	}

	&__btn-prev {
		left: -5px;

		@media (min-width: $screen-sm) {
			top: -30px;
			left: auto;
			right: 35px;
		}

		@media (min-width: $screen-md) {
			right: 40px;
		}

		@media (min-width: $screen-lg) {
			top: -40px;
		}

		@media (min-width: $screen-xxl) {
			top: -50px;
		}
	}

	&__btn-next {
		right: -5px;

		@media (min-width: $screen-sm) {
			top: -30px;
			right: 0;
		}

		@media (min-width: $screen-lg) {
			top: -40px;
		}

		@media (min-width: $screen-xxl) {
			top: -50px;
		}
	}
}
