.history {

	&__slider {
		position: relative;
		width: 100%;
		padding-right: 25px;
		padding-left: 25px;

		@media (min-width: $screen-sm) {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
