.client-card {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	text-align: center;

	@media (min-width: $screen-sm) {
		flex: 1;
		align-items: flex-start;
		padding: 20px;
		text-align: left;
		border: 1px solid $gray-light;
	}

	@media (min-width: $screen-lg) {
		padding-right: 40px;
		padding-left: 40px;
	}

	@media (min-width: $screen-xxl) {
		padding: 40px 60px;
	}

	&__logo-wrap {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: flex-start;
		height: 60px;
		margin-bottom: 10px;

		@media (min-width: $screen-sm) {
			width: 100%;
		}

		@media (min-width: $screen-md) {
			height: 80px;
		}

		@media (min-width: $screen-lg) {
			height: 110px;
		}
	}

	&__img {
		flex-shrink: 0;
		max-height: 60px;
		transition: opacity $transition-time ease;

		@media (min-width: $screen-md) {
			max-height: 80px;
		}

		@media (min-width: $screen-lg) {
			max-height: 100px;
		}

		&:hover {
			opacity: 0.7;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $screen-sm) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-lg) {
			font-size: 20px;
			line-height: 28px;
		}

		@media (min-width: $screen-xl) {
			margin-bottom: 20px;
			font-size: 24px;
			line-height: 32px;
		}
	}

	&__text {
		@extend .paragraph-limit;
		margin-bottom: 15px;

		@media (min-width: $screen-sm) {
			flex: 1;
		}

		@media (min-width: $screen-md) {
			margin-bottom: 20px;
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 30px;
		}

		p {
			@extend .content-text;
		}
	}

	&__link {
		font-size: 16px;
		line-height: 24px;
		color: $primary-green;

		@media (min-width: $screen-xxl) {
			font-size: 18px;
			line-height: 26px;
		}

		&:hover {
			color: $secondary-green;
		}

		&:active {
			color: $secondary-green;
		}
	}

}
