.structure-slider {
	$self: &;

	&__wrapper {

		@media (min-width: $screen-sm) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;
			box-sizing: border-box;
			margin-bottom: -20px;

			@media (min-width: $screen-xl) {
				margin-bottom: -40px;
			}
		}
	}

	&__item {
		box-sizing: border-box;

		@media (min-width: $screen-sm) {
			width: calc(50% - 10px);
			margin-right: 20px;
			margin-bottom: 20px;
		}

		@media (min-width: $screen-xl) {
			width: calc(50% - 20px);
			margin-right: 40px;
			margin-bottom: 40px;
		}

		&:nth-child(2n) {

			@media (min-width: $screen-sm) {
				margin-right: 0;
			}
		}
	}

	&__link {
		display: block;
		position: relative;
		height: 0;
		padding-top: 49%;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 23.81%, #000000 100%);
			opacity: 0.8;
			transition: opacity $transition-time;
		}

		&:hover {

			&::after {
				opacity: 1;
			}
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: left center;';
		transition: opacity $transition-time;
	}

	&__title {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
		width: 100%;
		margin: 0;
		padding: 20px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: $white;

		@media (min-width: $screen-md) {
			padding: 25px;
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-lg) {
			padding: 30px;
		}

		@media (min-width: $screen-xl) {
			padding: 40px;
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__btn-prev {

		@media (min-width: $screen-sm) {
			display: none;
		}
	}

	&__btn-next {

		@media (min-width: $screen-sm) {
			display: none;
		}
	}
}
