.about {

	&__row {
		@include row();
	}

	&__text {
		@include col(xs, 12, 12);
		@include col(md, 8, 12);
		margin-bottom: 20px;

		@media (min-width: $screen-md) {
			margin-bottom: 0;
		}

		@extend .paragraph-limit;
	}

	&__action {
		@include col(xs, 12, 12);
		@include col(md, 4, 12);
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		@media (min-width: $screen-md) {
			justify-content: flex-end;
		}
	}

	&__btn-wrap {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;

		@media (min-width: $screen-sm) {
			flex-direction: row;
		}

		@media (min-width: $screen-md) {
			flex-direction: column;
		}
	}

	&__btn {
		min-width: 225px;
		margin-bottom: 20px;

		@media (min-width: $screen-sm) {
			margin-right: 40px;
			margin-bottom: 0;
		}

		@media (min-width: $screen-md) {
			min-width: 250px;
			margin-right: 0;
			margin-bottom: 20px;
		}

		@media (min-width: $screen-lg) {
			min-width: 315px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
