.price-list {

	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__row {
		width: 100%;
		border-bottom: 1px solid $gray-lighten;
	}

	&__row-inner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		padding: 20px;

		@media (min-width: $screen-sm) {
			flex-wrap: nowrap;
			width: 100%;
			margin: 0 auto;
			padding: 15px 0;
		}

		@media (min-width: $screen-md) {
			width: 984px;
		}

		@media (min-width: $screen-lg) {
			width: 1200px;
		}

		@media (min-width: $screen-xl) {
			width: 1320px;
		}

		@media (min-width: $screen-xxl) {
			width: 1600px;
		}
	}

	&__col {

		&:nth-child(1) {
			flex-shrink: 0;
			width: 100%;
			margin-bottom: 15px;

			@media (min-width: $screen-sm) {
				width: 200px;
				margin-right: 25px;
				margin-bottom: 0;
				margin-left: 25px;
			}

			@media (min-width: $screen-md) {
				width: 225px;
				margin-left: 0;
			}

			@media (min-width: $screen-lg) {
				width: 285px;
			}

			@media (min-width: $screen-xl) {
				width: 300px;
				margin-right: 40px;
			}
		}

		&:nth-child(2) {
			width: 100%;
			margin-bottom: 15px;

			@media (min-width: $screen-sm) {
				margin-bottom: 35px;
			}

			@media (min-width: $screen-sm) {
				flex-grow: 1;
				width: auto;
				margin-bottom: 0;
				margin-right: 25px;
			}

			@media (min-width: $screen-xl) {
				margin-right: 40px;
			}
		}

		&:nth-child(3) {
			flex-shrink: 0;
			width: 50%;

			@media (min-width: $screen-sm) {
				width: 100px;
				margin-bottom: 0;
				margin-right: 25px;
			}

			@media (min-width: $screen-xl) {
				margin-right: 40px;
			}
		}

		&:nth-child(4) {
			flex-shrink: 0;
			width: 50%;

			@media (min-width: $screen-sm) {
				width: 100px;
				margin-bottom: 0;
				margin-right: 25px;
			}

			@media (min-width: $screen-xl) {
				margin-right: 40px;
			}

			@media (min-width: $screen-xxl) {
				margin-right: 250px;
			}
		}
	}

	&__caption {
		display: block;
		margin-bottom: 5px;
		font-size: 12px;
		line-height: 18px;
		color: $text-color-muted;

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__object,
	&__description,
	&__time,
	&__price {
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-lg) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__object {
		color: $text-color-muted;
	}

	&__time {
		font-weight: 600;
	}

	&__price {
		font-weight: 600;
		color: $primary-sea;
	}

	&__mobile-filter {

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__filter {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
		}
	}
}
