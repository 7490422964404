.order-block {

	&__form,
	&__success {
		@include row();
	}

	&__success {
		display: none;
		min-height: 582px;

		@media (min-width: $screen-sm) {
			min-height: 627px;
		}

		@media (min-width: $screen-md) {
			min-height: 635px;
		}

		@media (min-width: $screen-lg) {
			min-height: 653px;
		}

		@media (min-width: $screen-xxl) {
			min-height: 692px;
		}
	}

	&__col {
		@include col(xs, 12, 12);
		@include col(sm, 8, 12);
		@include col(md, 6, 12);
		@include col(lg, 4, 12);
		@include col-offset(sm, 2, 12);
		@include col-offset(md, 3, 12);
		@include col-offset(lg, 4, 12);
	}

	&__text {
		@include col(xs, 12, 12);
		@include col(sm, 10, 12);
		@include col(md, 8, 12);
		@include col(lg, 6, 12);
		@include col-offset(sm, 1, 12);
		@include col-offset(md, 2, 12);
		@include col-offset(lg, 3, 12);
		margin-bottom: 16px;
		text-align: center;

		@media (min-width: $screen-sm) {
			margin-bottom: 32px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 40px;
		}

		p {
			font-size: 14px;
			line-height: 24px;

			@media (min-width: $screen-sm) {
				font-size: 18px;
				line-height: 28px;
			}

			@media (min-width: $screen-xxl) {
				font-size: 20px;
				line-height: 32px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__text-wrap {
		padding: 0 10%;
	}

	&__success-text {
		text-align: center;
	}
}

.order-form {
	$self: &;
	max-width: 380px;
	margin: 0 auto;

	&__input-wrap {

		+ #{$self}__input-wrap {
			margin-top: 20px;

			@media (min-width: $screen-sm) {
				margin-top: 30px;
			}
		}
	}

	&__input {
		width: 100%;
	}

	&__btn-wrap {
		width: 100%;
		max-width: 315px;
		margin: 40px auto 20px;
		text-align: center;

		@media (min-width: $screen-sm) {
			margin-top: 30px;
		}
	}

	&__submit {
		width: 100%;
	}

}
