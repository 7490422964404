@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 1;
}

.hamburger.is-active:hover {
  opacity: 1;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #77A310;
}

.hamburger-box {
  width: 20px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 3px;
  background-color: #95C12B;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -7px;
}

.hamburger-inner::after {
  bottom: -7px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 40px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 40px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 40px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 40px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 40px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 40px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-4px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-4px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(4px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(4px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-4px, -5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-4px, 5px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(4px, -5px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(4px, 5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 7px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 14px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 7px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 14px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 7px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -40px;
  top: -40px;
  transform: translate3d(40px, 40px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -40px;
  top: -40px;
  transform: translate3d(-40px, 40px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 7px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -40px;
  top: 40px;
  transform: translate3d(40px, -40px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -40px;
  top: 40px;
  transform: translate3d(-40px, -40px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1.5px;
}

.hamburger--slider .hamburger-inner::before {
  top: 7px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 14px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-2.85714px, -4px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 7px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 14px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(2.85714px, -4px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 7px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 7px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

@-ms-viewport {
  width: device-width;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-600.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-600.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-700.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v14-cyrillic-ext_latin-ext_latin_cyrillic-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.page {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%;
}

.page__inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page__content {
  flex-grow: 1;
  padding-top: 74px;
}

@media (min-width: 1280px) {
  .page__content {
    padding-top: 90px;
  }
}

@media (min-width: 1440px) {
  .page__content {
    padding-top: 95px;
  }
}

@media (min-width: 1700px) {
  .page__content {
    padding-top: 130px;
  }
}

.page__footer-wrapper {
  flex-shrink: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100%;
  font-weight: 400;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #323232;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;
}

a svg,
button svg {
  pointer-events: none;
}

svg:not(:root) {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #323232;
  background-color: transparent;
  text-decoration: none;
  transition: color 0.3s, border-color 0.3s;
}

a:hover {
  color: #009CA5;
}

a:not([class]) {
  border-bottom: 1px solid rgba(0, 156, 165, 0.5);
}

a:not([class]):hover {
  border-color: transparent;
}

a:not([class]):active {
  color: #0A7F86;
}

@media (min-width: 1280px) {
  a[href^="tel:"] {
    white-space: nowrap;
    pointer-events: none;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid #DCDCDC;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1.2;
  color: currentColor;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 16px;
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 1em;
}

p {
  /*  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;*/
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: 1em;
  padding-left: 1.5em;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}

li {
  line-height: inherit;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  font-size: 0.75em;
}

b,
strong {
  font-weight: bolder;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

code,
kbd,
pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

code {
  padding: 0.06em 0.3em;
  color: #323232;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

kbd {
  padding: 0.06em 0.3em;
  color: #323232;
  border-radius: 3px;
}

kbd kbd {
  padding: 0;
}

pre {
  display: block;
  width: 100%;
  margin-bottom: 1em;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(0, 0, 0, 0.08);
}

pre code {
  background: transparent;
}

.visuallyhidden {
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.base-text, p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .base-text, p {
    margin-bottom: 15px;
  }
}

@media (min-width: 1024px) {
  .base-text, p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1440px) {
  .base-text, p {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
}

.content-text, .page-blockquote__text p, .content-block p, .intro p, .number-slide__text, .dual-slider-text__text p, .info-block__content p, .client-card__text p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .content-text, .page-blockquote__text p, .content-block p, .intro p, .number-slide__text, .dual-slider-text__text p, .info-block__content p, .client-card__text p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) {
  .content-text, .page-blockquote__text p, .content-block p, .intro p, .number-slide__text, .dual-slider-text__text p, .info-block__content p, .client-card__text p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1700px) {
  .content-text, .page-blockquote__text p, .content-block p, .intro p, .number-slide__text, .dual-slider-text__text p, .info-block__content p, .client-card__text p {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
}

.slider-content, .history-slider__text p, .steps-slider__text p, .icon-card__text p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  .slider-content, .history-slider__text p, .steps-slider__text p, .icon-card__text p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .slider-content, .history-slider__text p, .steps-slider__text p, .icon-card__text p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1440px) {
  .slider-content, .history-slider__text p, .steps-slider__text p, .icon-card__text p {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
}

.paragraph-limit p, .about__text p, .dual-slider-text__text p, .steps-slider__text p, .client-card__text p, .info-list__content p {
  max-width: 80ch;
}

.paragraph-limit :last-child, .about__text :last-child, .dual-slider-text__text :last-child, .steps-slider__text :last-child, .client-card__text :last-child, .info-list__content :last-child {
  margin-bottom: 0;
}

.arrow::after, .back__link::after, .select2-container--default .custom-select .select2-selection__arrow::after, .select2-container--default .custom-select--theme_dark .select2-selection__arrow::after, .select2-container--default .multiple-custom-select::after, .accordion__item--active .accordion__title::after, .accordion__title::after, .p-mobile-filter__title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-right: 2px solid #DCDCDC;
  border-bottom: 2px solid #DCDCDC;
  transform-origin: top left;
  transition-property: transform, border-color;
  transition-duration: 0.3s, 0.3s;
  transform: rotate(45deg) translate(-50%, -50%);
}

.arrow--big::after, .accordion__item--active .accordion__title::after, .accordion__title::after {
  width: 16px;
  height: 16px;
  border-right: 3px solid #DCDCDC;
  border-bottom: 3px solid #DCDCDC;
}

.arrow--up::after, .select2-container--default.select2-container--open .custom-select .select2-selection__arrow::after, .select2-container--default.select2-container--open .custom-select--theme_dark .select2-selection__arrow::after {
  transform: rotate(-135deg) translate(-50%, -50%);
}

.arrow--left::after, .back__link::after {
  left: 0;
  transform: rotate(135deg);
}

.arrow--theme_darkgray::after, .back__link::after, .select2-container--default .custom-select--theme_dark .select2-selection__arrow::after {
  border-right-color: #646464;
  border-bottom-color: #646464;
}

.arrow--theme_green::after, .accordion__item--active .accordion__title::after {
  border-right-color: #95C12B;
  border-bottom-color: #95C12B;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  font-weight: 400;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (min-width: 1280px) {
  h1 {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 54px;
  }
}

@media (min-width: 1440px) {
  h1 {
    font-size: 48px;
    line-height: 60px;
  }
}

@media (min-width: 1700px) {
  h1 {
    margin-bottom: 40px;
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
}

@media (min-width: 1024px) {
  h2 {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (min-width: 1280px) {
  h2 {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 48px;
  }
}

@media (min-width: 1700px) {
  h2 {
    margin-bottom: 40px;
  }
}

h3 {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  h3 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1700px) {
  h3 {
    margin-bottom: 30px;
  }
}

ul:not([class]) {
  margin-bottom: 10px;
  padding-left: 0;
  list-style: none;
}

@media (min-width: 768px) {
  ul:not([class]) {
    margin-bottom: 15px;
  }
}

@media (min-width: 1440px) {
  ul:not([class]) {
    margin-bottom: 20px;
  }
}

ul:not([class]) li {
  position: relative;
  max-width: 80ch;
  margin-bottom: 10px;
  padding-left: 26px;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  ul:not([class]) li {
    padding-left: 30px;
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  ul:not([class]) li {
    margin-bottom: 15px;
  }
}

@media (min-width: 1700px) {
  ul:not([class]) li {
    font-size: 20px;
    line-height: 28px;
  }
}

ul:not([class]) li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 20px;
  height: 2px;
  background-color: #95C12B;
  border-radius: 10px;
}

@media (min-width: 1024px) {
  ul:not([class]) li::before {
    top: 12px;
  }
}

@media (min-width: 1440px) {
  ul:not([class]) li::before {
    top: 13px;
  }
}

ul:not([class]) li:last-child {
  margin-bottom: 0;
}

ol:not([class]) {
  margin-bottom: 10px;
  padding-left: 1.5em;
  list-style: none;
  counter-reset: li;
}

@media (min-width: 768px) {
  ol:not([class]) {
    margin-bottom: 15px;
    padding-left: 2em;
  }
}

@media (min-width: 1024px) {
  ol:not([class]) {
    padding-left: 2.5em;
  }
}

@media (min-width: 1440px) {
  ol:not([class]) {
    margin-bottom: 20px;
  }
}

ol:not([class]) li {
  max-width: 80ch;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  ol:not([class]) li {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  ol:not([class]) li {
    margin-bottom: 15px;
  }
}

@media (min-width: 1700px) {
  ol:not([class]) li {
    font-size: 20px;
    line-height: 28px;
  }
}

ol:not([class]) li:last-child {
  margin-bottom: 0;
}

ol:not([class]) li::before {
  counter-increment: li;
  content: counters(li, ".") ". ";
  display: inline-block;
  width: 1.5em;
  margin-left: -1.5em;
  font-weight: 600;
  color: #95C12B;
  text-align: left;
}

@media (min-width: 768px) {
  ol:not([class]) li::before {
    width: 2em;
    margin-left: -2em;
  }
}

ol:not([class]) li > ol {
  padding-left: 2.5em;
}

ol:not([class]) li > ol li::before {
  width: 2em;
  margin-right: 0.5em;
  margin-left: -2.5em;
}

ol:not([class]) li > ol > li > ol {
  padding-left: 3.5em;
}

ol:not([class]) li > ol > li > ol li::before {
  width: 3em;
  margin-right: 0.5em;
  margin-left: -3.5em;
}

mark {
  background: #FF5858;
  color: #323232;
}

.link--theme_green {
  color: #323232;
  border-bottom: 1px solid rgba(50, 50, 50, 0.35);
}

.link--theme_green:hover {
  color: #95C12B;
  border-color: transparent;
}

.link--theme_green:active {
  color: #77A310;
}

.link--theme_sea {
  color: #323232;
}

.link--theme_sea:hover {
  color: #009CA5;
}

.link--theme_sea:active {
  color: #0A7F86;
}

.link--theme_gray {
  color: #646464;
  border-bottom: 1px solid rgba(50, 50, 50, 0.35);
}

.link--theme_gray:hover {
  color: #646464;
  border-color: transparent;
}

.link--theme_gray:active {
  color: #323232;
}

.link--theme_border-sea {
  color: #323232;
  border-bottom: 1px solid rgba(0, 156, 165, 0.3);
}

.link--theme_border-sea:hover {
  color: #323232;
  border-color: transparent;
}

.link--theme_border-sea:active {
  color: #323232;
}

.link--hashtag {
  color: #009CA5;
}

.link--hashtag:hover {
  color: #0A7F86;
}

.link--hashtag:active {
  color: #0A7F86;
}

.section {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 45px;
}

@media (min-width: 768px) {
  .section {
    padding-top: 55px;
  }
}

@media (min-width: 1024px) {
  .section {
    padding-top: 70px;
  }
}

@media (min-width: 1280px) {
  .section {
    padding-top: 80px;
  }
}

@media (min-width: 1440px) {
  .section {
    padding-top: 120px;
  }
}

.section--top {
  padding-top: 20px;
}

@media (min-width: 1024px) {
  .section--top {
    padding-top: 30px;
  }
}

@media (min-width: 1280px) {
  .section--top {
    padding-top: 40px;
  }
}

@media (min-width: 1700px) {
  .section--top {
    padding-top: 80px;
  }
}

.section--top_no-banner {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .section--top_no-banner {
    padding-top: 30px;
  }
}

@media (min-width: 1024px) {
  .section--top_no-banner {
    padding-top: 70px;
  }
}

@media (min-width: 1280px) {
  .section--top_no-banner {
    padding-top: 80px;
  }
}

.section--top_tabs {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .section--top_tabs {
    padding-top: 30px;
  }
}

@media (min-width: 1280px) {
  .section--top_tabs {
    padding-top: 40px;
  }
}

.section--fluid {
  max-width: none;
  margin: 0;
}

.section--no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.section--theme_sea {
  background-color: #009CA5;
}

.section--theme_green {
  background-color: #95C12B;
}

.section__inner--theme_green {
  background-color: #95C12B;
}

.section__inner--theme_lightsea {
  background-color: #D9F0F2;
}

@media (min-width: 768px) {
  .section__inner--theme_separated {
    background: linear-gradient(to right, #F0F0F0 50%, #FFFFFF 50%);
    position: relative;
  }
}

@media (min-width: 768px) {
  .section__inner--theme_separated::before, .section__inner--theme_separated::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    top: 0;
    right: 1px;
    background-color: #EBEBEB;
  }
}

@media (min-width: 768px) {
  .section__inner--theme_separated::after {
    top: auto;
    bottom: 0;
  }
}

.section__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .section__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .section__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .section__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .section__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .section__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .section__container {
    width: 1620px;
  }
}

.section__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.section__row > * {
  flex-basis: 100%;
}

.section__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1280px) {
  .section-pattern {
    position: relative;
  }
}

@media (min-width: 1280px) {
  .section-pattern::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    z-index: -1;
    width: 325px;
    height: 100%;
    max-height: 766px;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("../img/section-pattern--lg.svg");
  }
}

@media (min-width: 1440px) {
  .section-pattern::after {
    background-image: url("../img/section-pattern--xl.svg");
    width: 466px;
    max-height: 766px;
  }
}

@media (min-width: 1700px) {
  .section-pattern::after {
    background-image: url("../img/section-pattern--xxl.svg");
    width: 680px;
    max-height: 766px;
  }
}

.section-pattern--top::after {
  top: 0;
  transform: translateY(0);
}

.section-pattern--bottom::after {
  bottom: 0;
  transform: translateY(0);
}

.content-section {
  padding-top: 45px;
}

@media (min-width: 768px) {
  .content-section {
    padding-top: 55px;
  }
}

@media (min-width: 1024px) {
  .content-section {
    padding-top: 70px;
  }
}

@media (min-width: 1280px) {
  .content-section {
    padding-top: 80px;
  }
}

@media (min-width: 1440px) {
  .content-section {
    padding-top: 120px;
  }
}

.icon-link {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.icon-link:hover .icon-link__icon {
  opacity: 0.5;
}

.icon-link:hover .icon-link__title {
  opacity: 0.5;
}

.icon-link__icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  transition: opacity 0.3s;
}

@media (min-width: 1024px) {
  .icon-link__icon {
    margin-right: 10px;
  }
}

.icon-link__title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  transition: opacity 0.3s;
}

@media (min-width: 1024px) {
  .icon-link__title {
    font-size: 16px;
    line-height: 24px;
  }
}

.page-blockquote {
  position: relative;
  margin: 0;
  padding: 22px 0 0;
}

@media (min-width: 768px) {
  .page-blockquote {
    padding-top: 30px;
  }
}

@media (min-width: 1024px) {
  .page-blockquote {
    padding-top: 37px;
  }
}

.page-blockquote::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='27' viewBox='0 0 32 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83957 13.3258C11.0945 13.8484 12.0642 14.6903 12.7487 15.8516C13.4902 16.9548 13.861 18.2903 13.861 19.8581C13.861 21.9484 13.205 23.6613 11.893 24.9968C10.5811 26.3323 8.95543 27 7.01604 27C4.96257 27 3.27986 26.3323 1.96791 24.9968C0.655971 23.6613 0 21.9484 0 19.8581C0 18.8129 0.114082 17.7968 0.342246 16.8097C0.627451 15.8226 1.14082 14.3419 1.88235 12.3677L6.41711 0H13.262L9.83957 13.3258ZM27.9786 13.3258C29.2335 13.8484 30.2032 14.6903 30.8877 15.8516C31.6292 16.9548 32 18.2903 32 19.8581C32 21.9484 31.344 23.6613 30.0321 24.9968C28.7201 26.3323 27.0945 27 25.1551 27C23.1016 27 21.4189 26.3323 20.1069 24.9968C18.795 23.6613 18.139 21.9484 18.139 19.8581C18.139 18.8129 18.2531 17.7968 18.4813 16.8097C18.7665 15.8226 19.2799 14.3419 20.0214 12.3677L24.5561 0H31.4011L27.9786 13.3258Z' fill='%23323232'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

@media (min-width: 768px) {
  .page-blockquote::before {
    width: 24px;
    line-height: 20px;
  }
}

@media (min-width: 1024px) {
  .page-blockquote::before {
    width: 32px;
    height: 27px;
  }
}

.page-blockquote--team .page-blockquote__text p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  .page-blockquote--team .page-blockquote__text p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .page-blockquote--team .page-blockquote__text p {
    font-size: 18px;
    line-height: 26px;
  }
}

.page-blockquote__text {
  margin-bottom: 20px;
}

@media (min-width: 1440px) {
  .page-blockquote__text {
    margin-bottom: 30px;
  }
}

.page-blockquote__text p {
  max-width: 60ch;
}

.page-blockquote__text p:last-child {
  margin-bottom: 0;
}

.page-blockquote__author {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.page-blockquote__photo {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .page-blockquote__photo {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1024px) {
  .page-blockquote__photo {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }
}

.page-blockquote__name {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  .page-blockquote__name {
    font-size: 16px;
    line-height: 24px;
  }
}

.slider-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

@media (min-width: 1024px) {
  .slider-title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .slider-title {
    margin-bottom: 30px;
  }
}

@media (min-width: 1440px) {
  .slider-title {
    margin-bottom: 40px;
  }
}

@media (min-width: 1700px) {
  .slider-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.content-wrap {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .content-wrap {
    padding-top: 30px;
  }
}

@media (min-width: 1280px) {
  .content-wrap {
    padding-top: 40px;
  }
}

.embed-container {
  position: relative;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.back {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .back {
    margin-bottom: 40px;
  }
}

@media (min-width: 1700px) {
  .back {
    margin-bottom: 60px;
  }
}

.back__link {
  position: relative;
  margin-left: 20px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .back__link {
    font-size: 16px;
    line-height: 24px;
  }
}

.back__link::after {
  left: -5px;
  border-right-color: #C4C4C4;
  border-bottom-color: #C4C4C4;
}

.back__link:active::after {
  border-right-color: #323232;
  border-bottom-color: #323232;
}

.content-box h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .content-box h2 {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .content-box h2 {
    font-size: 18px;
    line-height: 26px;
  }
}

.content-box p {
  max-width: 80ch;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .content-box p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .content-box p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 26px;
  }
}

.content-box ul:not([class]) {
  margin-bottom: 10px;
}

@media (min-width: 1280px) {
  .content-box ul:not([class]) {
    margin-bottom: 15px;
  }
}

.content-box ul:not([class]) li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .content-box ul:not([class]) li {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .content-box ul:not([class]) li {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 26px;
  }
}

.content-box ul:not([class]) li::before {
  top: 9px;
}

@media (min-width: 768px) {
  .content-box ul:not([class]) li::before {
    top: 11px;
  }
}

@media (min-width: 1280px) {
  .content-box ul:not([class]) li::before {
    top: 12px;
  }
}

.content-box ul:not([class]) li:last-child {
  margin-bottom: 0;
}

.content-box ol:not([class]) {
  margin-bottom: 10px;
  padding-left: 1.5em;
  list-style: none;
  counter-reset: li;
  /* TODO: проверить все отступы в контентном и обычном варианте */
}

@media (min-width: 768px) {
  .content-box ol:not([class]) {
    margin-bottom: 15px;
    padding-left: 2em;
  }
}

@media (min-width: 1280px) {
  .content-box ol:not([class]) {
    padding-left: 2.5em;
  }
}

.content-box ol:not([class]) li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .content-box ol:not([class]) li {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .content-box ol:not([class]) li {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 26px;
  }
}

.content-box ol:not([class]) li:last-child {
  margin-bottom: 0;
}

.content-box ol:not([class]) li::before {
  counter-increment: li;
  content: counters(li, ".") ". ";
  display: inline-block;
  width: 1.5em;
  margin-left: -1.5em;
  font-weight: 600;
  color: #95C12B;
  text-align: left;
}

@media (min-width: 768px) {
  .content-box ol:not([class]) li::before {
    width: 2em;
    margin-left: -2em;
  }
}

.content-box ol:not([class]) li > ol {
  margin-top: 10px;
  padding-left: 2.5em;
}

@media (min-width: 1280px) {
  .content-box ol:not([class]) li > ol {
    margin-top: 15px;
  }
}

.content-box ol:not([class]) li > ol li::before {
  width: 2em;
  margin-right: 0.5em;
  margin-left: -2.5em;
}

.content-box ol:not([class]) li > ol > li > ol {
  padding-left: 3.5em;
}

.content-box ol:not([class]) li > ol > li > ol li::before {
  width: 3em;
  margin-right: 0.5em;
  margin-left: -3.5em;
}

.btn {
  display: inline-block;
  min-width: 164px;
  max-width: 100%;
  margin: 0;
  padding: 12px 20px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: #009CA5;
  background-color: transparent;
  border: 1px solid #009CA5;
  border-radius: 100px;
  vertical-align: middle;
  overflow: hidden;
  transition: color, background-color, border-color;
  transition-duration: 0.3s;
  user-select: none;
  cursor: pointer;
}

.btn:hover {
  color: #FFFFFF;
  background-color: #95C12B;
  border-color: #95C12B;
}

.btn:active {
  color: #FFFFFF;
  background-color: #77A310;
  border-color: #77A310;
}

.btn:disabled {
  opacity: 0.65;
  color: #009CA5;
  cursor: default;
}

.btn:disabled:hover, .btn:disabled:active {
  color: #009CA5;
  background-color: transparent;
  border-color: #009CA5;
}

.btn--theme_filled {
  color: #FFFFFF;
  background-color: #009CA5;
  border-color: #009CA5;
}

.btn--theme_filled:disabled {
  opacity: 0.65;
  color: #FFFFFF;
  cursor: default;
}

.btn--theme_filled:disabled:hover, .btn--theme_filled:disabled:active {
  color: #FFFFFF;
  background-color: #009CA5;
  border-color: #009CA5;
}

.btn--size_small {
  padding-top: 9px;
  padding-bottom: 10px;
}

.btn--size_big {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
}

@media (min-width: 1024px) {
  .btn--size_adaptive {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
  }
}

.btn-close {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-close:hover::before, .btn-close:hover::after {
  background-color: rgba(255, 255, 255, 0.7);
}

.btn-close:focus {
  outline: none;
}

.btn-close:active::before, .btn-close:active::after {
  background-color: #FFFFFF;
}

.btn-close::before, .btn-close::after {
  content: "";
  position: absolute;
  left: 0;
  width: 30px;
  height: 2px;
  background-color: #FFFFFF;
  transition: background-color 0.3s;
}

.btn-close::before {
  transform: rotate(45deg);
}

.btn-close::after {
  transform: rotate(-45deg);
}

.btn-close--size_small {
  width: 12px;
  height: 12px;
}

.btn-close--size_small::before, .btn-close--size_small::after {
  width: 12px;
  height: 1px;
}

.btn-close--theme_gray::before, .btn-close--theme_gray::after {
  background-color: #969696;
}

.btn-close--theme_gray:hover::before, .btn-close--theme_gray:hover::after {
  background-color: rgba(150, 150, 150, 0.7);
}

.btn-close--theme_gray:active::before, .btn-close--theme_gray:active::after {
  background-color: #969696;
}

input,
textarea {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-appearance: none;
  border-radius: 0;
}

input:focus,
textarea:focus {
  outline: none;
}

.input-wrap--icon {
  position: relative;
}

.input-wrap--icon::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transform: translateY(-50%);
}

.input-wrap--icon_search:after {
  background-image: url("../img/icons/icon-search.svg");
}

.input-search {
  width: 100%;
  padding: 11px 50px 11px 30px;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
  border: 2px solid rgba(149, 193, 43, 0.5);
  border-radius: 100px;
  transition-property: border-color, color;
  transition-duration: 0.3s;
}

.input-search:focus {
  border-color: #95C12B;
}

.input-search::placeholder {
  font-size: 16px;
  line-height: 24px;
  color: #646464;
  transition: color 0.3s;
}

.form-input,
.form-textarea {
  padding-left: 0;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #323232;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #C4C4C4;
  transition: color 0.3s, border-color 0.3s;
}

@media (min-width: 768px) {
  .form-input,
  .form-textarea {
    font-size: 16px;
    line-height: 24px;
  }
}

.form-input:active, .form-input:focus,
.form-textarea:active,
.form-textarea:focus {
  border-color: #969696;
  outline: none;
}

.form-input:active::placeholder, .form-input:focus::placeholder,
.form-textarea:active::placeholder,
.form-textarea:focus::placeholder {
  color: rgba(150, 150, 150, 0.4);
}

.form-input::placeholder,
.form-textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #969696;
  transition: color 0.3s;
}

@media (min-width: 768px) {
  .form-input::placeholder,
  .form-textarea::placeholder {
    font-size: 16px;
    line-height: 24px;
  }
}

.form-input:-webkit-autofill,
.form-textarea:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-text-fill-color: #323232;
}

.form-input.invalid,
.form-textarea.invalid {
  color: #FF5858;
  border-color: rgba(255, 88, 88, 0.5);
}

.form-input.invalid::placeholder,
.form-textarea.invalid::placeholder {
  color: #FF5858;
}

.form-textarea {
  display: block;
  resize: none;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file + .input-file-label {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.input-file-label:hover .input-file-label__icon {
  fill: none;
  stroke: #969696;
}

.input-file-label:hover .input-file-label__text {
  color: #646464;
}

.input-file-label:active .input-file-label__icon {
  fill: none;
  stroke: #969696;
}

.input-file-label:active .input-file-label__text {
  color: #323232;
}

.input-file-label__icon-wrap {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 24px;
  margin-right: 8px;
}

.input-file-label__icon {
  flex-shrink: 0;
  fill: none;
  stroke: #C4C4C4;
  vertical-align: middle;
  transition: fill 0.3s;
}

.input-file-label__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #969696;
  text-overflow: ellipsis;
  /*		white-space: nowrap;*/
  overflow: hidden;
  transition: color 0.3s;
}

@media (min-width: 768px) {
  .input-file-label__text {
    font-size: 16px;
    line-height: 24px;
  }
}

.agreement__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #969696;
}

.agreement__text:last-child {
  margin-bottom: 0;
}

.agreement__link {
  color: #969696;
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

.select2-container *:focus {
  outline: none;
}

.select2-container--default .custom-select {
  height: 40px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 0;
}

.select2-container--default .custom-select .select2-selection__rendered {
  color: #646464;
}

.select2-container .custom-select .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 56px;
  line-height: 38px;
}

.select2-container--default .custom-select .select2-selection__placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.select2-container--default .custom-select .select2-selection__arrow b {
  display: none;
}

.select2-container--default .custom-select .select2-selection__arrow {
  top: 0;
  right: 14px;
  width: 16px;
  height: 100%;
}

.custom-dropdown {
  border: 1px solid #DCDCDC;
  border-radius: 2px;
}

.custom-dropdown .select2-results__option {
  padding: 10px 15px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #D9F0F2;
  color: #646464;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(0, 156, 165, 0.4);
}

.select2-container--default .custom-select--theme_dark {
  height: 40px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
  background-color: #EBEBEB;
  border: 1px solid #EBEBEB;
  border-radius: 0;
}

.select2-container--default .custom-select--theme_dark .select2-selection__rendered {
  color: #646464;
}

.select2-container .custom-select--theme_dark .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 56px;
  line-height: 38px;
}

.select2-container--default .custom-select--theme_dark .select2-selection__placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.select2-container--default .custom-select--theme_dark .select2-selection__arrow b {
  display: none;
}

.select2-container--default .custom-select--theme_dark .select2-selection__arrow {
  top: 0;
  right: 14px;
  width: 16px;
  height: 100%;
}

.custom-dropdown--theme_dark {
  border: 1px solid #DCDCDC;
  border-radius: 2px;
}

.custom-dropdown--theme_dark .select2-results__option {
  padding: 10px 15px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.select2-container--default .multiple-custom-select {
  min-height: auto;
  line-height: 1;
  background-color: white;
  border: none;
  border-bottom: 2px solid #C4C4C4;
  border-radius: 0;
  cursor: pointer;
  transition: border-color 0.3s;
}

.select2-container--default .multiple-custom-select::after {
  top: auto;
  right: 3px;
  bottom: 12px;
  left: auto;
  border-right-color: #C4C4C4;
  border-bottom-color: #C4C4C4;
  transform-origin: center center;
  transform: rotate(45deg) translate(0);
}

.select2-container--default.select2-container--focus .multiple-custom-select {
  border: none;
  border-bottom: 2px solid #C4C4C4;
}

.select2-container--default.select2-container--open .multiple-custom-select {
  border-bottom: 2px solid #969696;
}

.select2-container--default.select2-container--open .multiple-custom-select::after {
  border-right-color: #95C12B;
  border-bottom-color: #95C12B;
  transform: rotate(-135deg) translate(0);
}

.select2-container .multiple-custom-select .select2-selection__rendered {
  padding: 0;
  white-space: normal;
}

.select2-container--default .multiple-custom-select .select2-selection__choice {
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #323232;
  background-color: #EBEBEB;
  border-color: #C4C4C4;
}

.select2-container--default .multiple-custom-select .select2-search--inline .select2-search__field {
  margin: 0;
  padding: 0 0 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #323232;
  transition: color 0.3s;
}

@media (min-width: 768px) {
  .select2-container--default .multiple-custom-select .select2-search--inline .select2-search__field {
    font-size: 16px;
    line-height: 24px;
  }
}

.select2-container--default .multiple-custom-select .select2-search--inline .select2-search__field::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #969696;
  transition: color 0.3s;
}

@media (min-width: 768px) {
  .select2-container--default .multiple-custom-select .select2-search--inline .select2-search__field::placeholder {
    font-size: 16px;
    line-height: 24px;
  }
}

.select2-container--default.select2-container--open .multiple-custom-select .select2-search--inline .select2-search__field::placeholder {
  color: rgba(150, 150, 150, 0.4);
}

.multiple-custom-dropdown {
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.select2-container--open .multiple-custom-dropdown {
  top: 8px;
}

.multiple-custom-dropdown .select2-results__options {
  padding: 12px 0 12px 16px;
}

.select2-container--default .multiple-custom-dropdown .select2-results__option--highlighted[aria-selected] {
  background: none;
}

.multiple-custom-dropdown .select2-results__option {
  position: relative;
  padding-left: 26px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #323232;
}

.multiple-custom-dropdown .select2-results__option::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #C4C4C4;
  transition: border-color 0.3s;
}

.multiple-custom-dropdown .select2-results__option[aria-selected=true] {
  background: none;
}

.multiple-custom-dropdown .select2-results__option[aria-selected=true]::before {
  border-color: #95C12B;
}

.multiple-custom-dropdown .select2-results__option[aria-selected=true]::after {
  content: "";
  position: absolute;
  top: 11px;
  left: 3px;
  width: 10px;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.94444L4.03448 6L9 1' stroke='%2395C12B' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.swiper-button-next,
.swiper-button-prev {
  top: 50%;
  width: 25px;
  height: 30px;
  margin-top: 0;
  background: transparent;
  border-radius: 0;
  transform: translateY(-50%);
  outline: none;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 16px;
  height: 16px;
  border-right: 3px solid #95C12B;
  border-bottom: 3px solid #95C12B;
  transform-origin: top left;
  transition: border-color 0.3s;
}

.swiper-button-next:hover::after, .swiper-button-next:active::after,
.swiper-button-prev:hover::after,
.swiper-button-prev:active::after {
  border-right-color: #77A310;
  border-bottom-color: #77A310;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.swiper-button-next.swiper-button-disabled::after,
.swiper-button-prev.swiper-button-disabled::after {
  border-right-color: rgba(149, 193, 43, 0.5);
  border-bottom-color: rgba(149, 193, 43, 0.5);
}

.swiper-button-next--theme_sea::after,
.swiper-button-prev--theme_sea::after {
  border-right: 3px solid #009CA5;
  border-bottom: 3px solid #009CA5;
}

.swiper-button-next--theme_sea:hover::after, .swiper-button-next--theme_sea:active::after,
.swiper-button-prev--theme_sea:hover::after,
.swiper-button-prev--theme_sea:active::after {
  border-right-color: #0A7F86;
  border-bottom-color: #0A7F86;
}

.swiper-button-next--theme_sea.swiper-button-disabled::after,
.swiper-button-prev--theme_sea.swiper-button-disabled::after {
  border-right-color: rgba(0, 156, 165, 0.5);
  border-bottom-color: rgba(0, 156, 165, 0.5);
}

.swiper-button-next--theme_gray::after,
.swiper-button-prev--theme_gray::after {
  border-right: 3px solid #C4C4C4;
  border-bottom: 3px solid #C4C4C4;
}

.swiper-button-next--theme_gray:hover::after, .swiper-button-next--theme_gray:active::after,
.swiper-button-prev--theme_gray:hover::after,
.swiper-button-prev--theme_gray:active::after {
  border-right-color: #969696;
  border-bottom-color: #969696;
}

.swiper-button-next--theme_gray.swiper-button-disabled::after,
.swiper-button-prev--theme_gray.swiper-button-disabled::after {
  border-right-color: rgba(196, 196, 196, 0.5);
  border-bottom-color: rgba(196, 196, 196, 0.5);
}

.swiper-button-next--theme_white::after,
.swiper-button-prev--theme_white::after {
  border-right: 3px solid #FFFFFF;
  border-bottom: 3px solid #FFFFFF;
}

.swiper-button-next--theme_white:hover::after, .swiper-button-next--theme_white:active::after,
.swiper-button-prev--theme_white:hover::after,
.swiper-button-prev--theme_white:active::after {
  border-right-color: rgba(255, 255, 255, 0.8);
  border-bottom-color: rgba(255, 255, 255, 0.8);
}

.swiper-button-next--theme_white.swiper-button-disabled::after,
.swiper-button-prev--theme_white.swiper-button-disabled::after {
  border-right-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.swiper-button-next {
  right: 20px;
}

.swiper-button-next::after {
  left: 50%;
  transform: rotate(-45deg) translate(-50%, -50%);
}

.swiper-button-prev {
  left: 20px;
}

.swiper-button-prev::after {
  left: 50%;
  transform: rotate(135deg) translate(-50%, -50%);
}

.swiper-pagination-bullets {
  bottom: -11px;
  left: 50%;
  font-size: 0;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .swiper-pagination-bullets {
    bottom: -16px;
  }
}

@media (min-width: 1024px) {
  .swiper-pagination-bullets {
    bottom: -21px;
  }
}

@media (min-width: 1280px) {
  .swiper-pagination-bullets {
    bottom: -20px;
  }
}

@media (min-width: 1440px) {
  .swiper-pagination-bullets {
    bottom: -30px;
  }
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
  width: 6px;
  height: 6px;
  margin: 0 3px;
  background-color: rgba(149, 193, 43, 0.25);
  opacity: 1;
  transition: background-color 0.3s;
}

@media (min-width: 1280px) {
  .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
}

.swiper-pagination-bullets .swiper-pagination-bullet-active {
  position: relative;
  background-color: rgba(149, 193, 43, 0.5);
}

.modal {
  display: none;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: transparent;
}

.modal__inner {
  padding-top: 65px;
  padding-bottom: 65px;
}

.modal__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .modal__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .modal__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .modal__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .modal__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .modal__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .modal__container {
    width: 1620px;
  }
}

.modal__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.modal__row > * {
  flex-basis: 100%;
}

.modal__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  /*		@include col(xl, 6 ,12);*/
  /*		@include col-offset(xl, 3 ,12);*/
}

@media (min-width: 1024px) {
  .modal__col {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .modal__col {
    margin-left: 16.66667%;
  }
}

.modal__content {
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #FFFFFF;
}

@media (min-width: 1024px) {
  .modal__content {
    margin: 0;
    padding: 0;
  }
}

.modal-form .fancybox-slide {
  padding: 0;
}

.modal-order-block {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .modal-order-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.modal-order-block__title, .modal-order-block__success-title {
  padding-top: 0;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #323232;
}

@media (min-width: 768px) {
  .modal-order-block__title, .modal-order-block__success-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.modal-order-block__success {
  display: none;
}

.modal-order-block__success-title {
  margin: 0;
}

.modal-order-block__btn-close {
  position: absolute;
  top: -50px;
  right: 0;
}

@media (min-width: 1024px) {
  .modal-order-block__btn-close {
    right: 10px;
  }
}

.page-header {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  background-color: #FFFFFF;
}

.page-header--fixed {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  background: #FFFFFF;
  border-bottom: 1px solid #DCDCDC;
}

.page-header--fixed .page-header__contacts--top {
  display: none;
}

.page-header--fixed .page-header__controls--top {
  align-self: center;
}

.page-header__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .page-header__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .page-header__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .page-header__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .page-header__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .page-header__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .page-header__container {
    width: 1620px;
  }
}

.page-header__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.page-header__row > * {
  flex-basis: 100%;
}

.page-header__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.page-header__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

@media (min-width: 1280px) {
  .page-header__wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1700px) {
  .page-header__wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.page-header__logo {
  flex-shrink: 0;
  position: relative;
  z-index: 3;
  width: 231px;
  height: 47px;
}

@media (min-width: 1280px) {
  .page-header__logo {
    width: 268px;
    height: 55px;
  }
}

@media (min-width: 1700px) {
  .page-header__logo {
    width: 390px;
    height: 80px;
  }
}

@media (min-width: 1280px) {
  .page-header__content {
    padding-left: 35px;
  }
}

@media (min-width: 1440px) {
  .page-header__content {
    padding-left: 60px;
  }
}

.page-header__nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  overflow-y: auto;
  padding-top: 67px;
}

@media (min-width: 768px) {
  .page-header__nav {
    right: 0;
    left: auto;
    width: 375px;
    border-left: 1px solid #DCDCDC;
  }
}

@media (min-width: 1024px) {
  .page-header__nav {
    width: 40%;
  }
}

@media (min-width: 1280px) {
  .page-header__nav {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    padding: 0;
    border: none;
    overflow: hidden;
  }
}

.page-header__nav-inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 100%;
  padding: 35px 20px 60px 20px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .page-header__nav-inner {
    padding-right: 30px;
    padding-bottom: 50px;
    padding-left: 30px;
  }
}

@media (min-width: 1024px) {
  .page-header__nav-inner {
    padding-top: 45px;
    padding-bottom: 40px;
  }
}

@media (min-width: 1280px) {
  .page-header__nav-inner {
    padding: 0;
  }
}

.page-header__contacts--top {
  display: none;
}

@media (min-width: 1024px) {
  .page-header__contacts--top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;
    margin-left: 40px;
  }
}

@media (min-width: 1280px) {
  .page-header__contacts--top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-left: 0;
  }
}

@media (min-width: 1440px) {
  .page-header__contacts--top {
    margin-bottom: 15px;
  }
}

.page-header__contacts--bottom {
  margin-bottom: 40px;
}

@media (min-width: 1024px) {
  .page-header__contacts--bottom {
    display: none;
  }
}

.page-header__social {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  .page-header__social {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.page-header__phone-link {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  white-space: nowrap;
  color: #969696;
}

@media (min-width: 1024px) {
  .page-header__phone-link {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1700px) {
  .page-header__phone-link {
    font-size: 16px;
    line-height: 24px;
  }
}

.page-header__controls {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .page-header__controls--bottom {
    display: none;
  }
}

.page-header__controls--top {
  display: none;
}

@media (min-width: 768px) {
  .page-header__controls--top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 3;
    margin-right: 30px;
    margin-bottom: 0;
    margin-left: auto;
  }
}

@media (min-width: 1280px) {
  .page-header__controls--top {
    align-self: flex-start;
    margin-right: 0;
  }
}

.page-header__order {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .page-header__order {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: 1700px) {
  .page-header__order {
    margin-right: 40px;
  }
}

.page-header__order-btn {
  min-width: 175px;
}

@media (min-width: 1700px) {
  .page-header__order-btn {
    min-width: 230px;
    padding: 12px 20px;
  }
}

.page-header__lang-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #969696;
}

@media (min-width: 768px) {
  .page-header__lang-link {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1700px) {
  .page-header__lang-link {
    font-size: 16px;
    line-height: 24px;
  }
}

.page-header__policy {
  margin-top: auto;
}

@media (min-width: 1280px) {
  .page-header__policy {
    display: none;
  }
}

.page-header__policy-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #969696;
}

.page-header__menu-toggle {
  z-index: 2;
  font-size: 0;
}

@media (min-width: 1280px) {
  .page-header__menu-toggle {
    display: none;
  }
}

.main-menu {
  margin-bottom: 40px;
}

@media (min-width: 1280px) {
  .main-menu {
    margin: 0;
  }
}

.main-menu__list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1280px) {
  .main-menu__list {
    flex-direction: row;
    justify-content: space-between;
  }
}

.main-menu__item + .main-menu__item {
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .main-menu__item + .main-menu__item {
    margin-top: 25px;
  }
}

@media (min-width: 1280px) {
  .main-menu__item + .main-menu__item {
    margin-top: 0;
    margin-left: 25px;
  }
}

@media (min-width: 1440px) {
  .main-menu__item + .main-menu__item {
    margin-left: 30px;
  }
}

@media (min-width: 1700px) {
  .main-menu__item + .main-menu__item {
    margin-left: 40px;
  }
}

.main-menu__link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

@media (min-width: 1024px) {
  .main-menu__link {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .main-menu__link {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1700px) {
  .main-menu__link {
    font-size: 16px;
    line-height: 24px;
  }
}

.main-menu__link:hover {
  color: #009CA5;
}

.main-menu__link:active {
  color: #0A7F86;
}

.main-menu__link--active {
  color: #95C12B;
}

.page-footer {
  padding-top: 80px;
  padding-bottom: 20px;
  background-color: #FFFFFF;
}

@media (min-width: 1280px) {
  .page-footer {
    padding-top: 120px;
  }
}

@media (min-width: 1700px) {
  .page-footer {
    padding-top: 160px;
  }
}

.page-footer__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .page-footer__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .page-footer__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .page-footer__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .page-footer__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .page-footer__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .page-footer__container {
    width: 1620px;
  }
}

.page-footer__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.page-footer__row > * {
  flex-basis: 100%;
}

.page-footer__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.page-footer__nav {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .page-footer__nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  .page-footer__nav {
    margin-bottom: 50px;
  }
}

@media (min-width: 1280px) {
  .page-footer__nav {
    margin-bottom: 60px;
  }
}

.page-footer__info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: #969696;
}

@media (min-width: 768px) {
  .page-footer__info {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.page-footer__info p {
  font-size: 12px;
  line-height: 18px;
}

@media (min-width: 1024px) {
  .page-footer__info p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 1700px) {
  .page-footer__info p {
    font-size: 16px;
    line-height: 24px;
  }
}

.page-footer__info p:last-child {
  margin-bottom: 0;
}

.page-footer__copyright {
  max-width: 420px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .page-footer__copyright {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .page-footer__copyright {
    max-width: 600px;
  }
}

@media (min-width: 1700px) {
  .page-footer__copyright {
    max-width: 685px;
  }
}

.page-footer__developer-link {
  color: #969696;
}

.footer-menu {
  display: none;
}

@media (min-width: 768px) {
  .footer-menu {
    display: block;
  }
}

.footer-menu__list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-menu__item {
  margin-right: 20px;
}

@media (min-width: 1024px) {
  .footer-menu__item {
    margin-right: 30px;
  }
}

@media (min-width: 1440px) {
  .footer-menu__item {
    margin-right: 40px;
  }
}

@media (min-width: 1700px) {
  .footer-menu__item {
    margin-right: 60px;
  }
}

.footer-menu__item:last-child {
  margin-right: 0;
}

.footer-menu__link {
  color: #323232;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid rgba(50, 50, 50, 0.35);
}

@media (min-width: 1024px) {
  .footer-menu__link {
    font-size: 16px;
    line-height: 26px;
  }
}

.footer-menu__link:hover {
  color: #009CA5;
  border-color: transparent;
}

.footer-menu__link:active {
  color: #0A7F86;
}

.footer-menu__link--active {
  color: #95C12B;
  border-color: transparent;
}

.footer-menu__link--active:hover, .footer-menu__link--active:active {
  color: #95C12B;
}

.social {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.social__icon {
  fill: #C4C4C4;
  transition: all 0.3s;
  vertical-align: middle;
}

.social__link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0;
  width: 26px;
  height: 26px;
  text-decoration: none;
  border: 2px solid #E6E6E6;
  border-radius: 50%;
  transition: all 0.3s;
}

.social__link + .social__link {
  margin-left: 10px;
}

.social__link:hover {
  border-color: #95C12B;
}

.social__link:hover .social__icon {
  fill: #95C12B;
}

.social__link:active {
  border-color: #77A310;
}

.social__link:active .social__icon {
  fill: #77A310;
}

.social__link {
  /* TODO: отредактировать размеры */
}

.social__link--vk .social__icon {
  width: 19px;
  height: 10px;
}

.social__link--instagram .social__icon {
  width: 14px;
  height: 14px;
}

.social__link--fb .social__icon {
  width: 10px;
  height: 18px;
}

.social__link--twitter .social__icon {
  width: 16px;
  height: 12px;
}

.social__link--ok .social__icon {
  width: 8px;
  height: 16px;
}

.social__link--youtube .social__icon {
  width: 17px;
  height: 12px;
}

.social__link--telegram .social__icon {
  width: 16px;
  height: 12px;
}

.social__link--whatsapp .social__icon {
  width: 14px;
  height: 14px;
}

.hash-tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

.hash-tag-list__item {
  margin-right: 5px;
}

@media (min-width: 768px) {
  .hash-tag-list__item {
    margin-right: 10px;
  }
}

.hash-tag-list__link {
  font-size: 14px;
  line-height: 20px;
  color: #009CA5;
}

@media (min-width: 768px) {
  .hash-tag-list__link {
    font-size: 18px;
    line-height: 26px;
  }
}

.content-block :last-child {
  margin-bottom: 0;
}

.content-block p {
  max-width: 80ch;
}

.content-block ul:not([class]) {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .content-block ul:not([class]) {
    margin-bottom: 15px;
  }
}

@media (min-width: 1700px) {
  .content-block ul:not([class]) {
    margin-bottom: 20px;
  }
}

.content-block ul:not([class]) li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .content-block ul:not([class]) li {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) {
  .content-block ul:not([class]) li {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1700px) {
  .content-block ul:not([class]) li {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
}

.content-block ul:not([class]) li::before {
  top: 9px;
}

@media (min-width: 768px) {
  .content-block ul:not([class]) li::before {
    top: 11px;
  }
}

@media (min-width: 1024px) {
  .content-block ul:not([class]) li::before {
    top: 12px;
  }
}

@media (min-width: 1700px) {
  .content-block ul:not([class]) li::before {
    top: 13px;
  }
}

.content-block ul:not([class]) li:last-child {
  margin-bottom: 0;
}

.content-block ol:not([class]) {
  margin-bottom: 10px;
  padding-left: 1.5em;
  list-style: none;
  counter-reset: li;
  /* TODO: проверить все отступы в контентном и обычном варианте */
}

@media (min-width: 768px) {
  .content-block ol:not([class]) {
    margin-bottom: 15px;
    padding-left: 2em;
  }
}

@media (min-width: 1280px) {
  .content-block ol:not([class]) {
    padding-left: 2.5em;
  }
}

@media (min-width: 1700px) {
  .content-block ol:not([class]) {
    margin-bottom: 20px;
  }
}

.content-block ol:not([class]) li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .content-block ol:not([class]) li {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) {
  .content-block ol:not([class]) li {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1700px) {
  .content-block ol:not([class]) li {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
}

.content-block ol:not([class]) li:last-child {
  margin-bottom: 0;
}

.content-block ol:not([class]) li::before {
  counter-increment: li;
  content: counters(li, ".") ". ";
  display: inline-block;
  width: 1.5em;
  margin-left: -1.5em;
  font-weight: 600;
  color: #95C12B;
  text-align: left;
}

@media (min-width: 768px) {
  .content-block ol:not([class]) li::before {
    width: 2em;
    margin-left: -2em;
  }
}

.content-block ol:not([class]) li > ol {
  margin-top: 10px;
  padding-left: 2.5em;
}

@media (min-width: 768px) {
  .content-block ol:not([class]) li > ol {
    margin-top: 15px;
  }
}

@media (min-width: 1700px) {
  .content-block ol:not([class]) li > ol {
    margin-top: 20px;
  }
}

.content-block ol:not([class]) li > ol li::before {
  width: 2em;
  margin-right: 0.5em;
  margin-left: -2.5em;
}

.content-block ol:not([class]) li > ol > li > ol {
  padding-left: 3.5em;
}

.content-block ol:not([class]) li > ol > li > ol li::before {
  width: 3em;
  margin-right: 0.5em;
  margin-left: -3.5em;
}

.content-block--doc img {
  margin-bottom: 15px;
  border: 1px solid #F0F0F0;
}

@media (min-width: 1280px) {
  .content-block--doc img {
    margin-bottom: 20px;
  }
}

@media (min-width: 1440px) {
  .content-block--doc img {
    margin-bottom: 30px;
  }
}

.intro {
  background-color: #009CA5;
}

@media (min-width: 768px) {
  .intro {
    /*todo: переделать блок с картинкой*/
  }
}

.intro__inner {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 30px;
  color: #FFFFFF;
  overflow: hidden;
}

@media (min-width: 768px) {
  .intro__inner {
    padding-top: 85px;
    padding-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .intro__inner {
    padding-top: 95px;
  }
}

@media (min-width: 1280px) {
  .intro__inner {
    padding-top: 125px;
  }
}

@media (min-width: 1440px) {
  .intro__inner {
    padding-top: 105px;
  }
}

@media (min-width: 1700px) {
  .intro__inner {
    padding-top: 145px;
  }
}

.intro__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .intro__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .intro__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .intro__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .intro__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .intro__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .intro__container {
    width: 1620px;
  }
}

.intro__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.intro__row > * {
  flex-basis: 100%;
}

.intro__banner {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .intro__banner {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .intro__banner {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .intro__banner {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1440px) {
  .intro__banner {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1700px) {
  .intro__banner {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.intro__banner br {
  display: none;
}

@media (min-width: 1024px) {
  .intro__banner br {
    display: block;
  }
}

.intro__text {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .intro__text {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .intro__text {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .intro__text {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1700px) {
  .intro__text {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.intro__img {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  min-height: 285px;
}

@media (min-width: 768px) {
  .intro__img {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .intro__img {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .intro__img {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1700px) {
  .intro__img {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .intro__img {
    margin-left: 8.33333%;
  }
}

@media (min-width: 1280px) {
  .intro__img {
    margin-left: 0%;
  }
}

@media (min-width: 1700px) {
  .intro__img {
    margin-left: 8.33333%;
  }
}

@media (min-width: 768px) {
  .intro__img {
    min-height: auto;
  }
}

.intro__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 970px;
  height: 436px;
  background-image: url("../img/intro-banner-img.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}

@media (min-width: 768px) {
  .intro__bg {
    width: 795px;
    height: 357px;
  }
}

@media (min-width: 1024px) {
  .intro__bg {
    width: 1083px;
    height: 487px;
  }
}

.intro__title {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 32px;
}

@media (min-width: 1024px) {
  .intro__title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media (min-width: 1280px) {
  .intro__title {
    font-size: 40px;
    line-height: 54px;
  }
}

@media (min-width: 1440px) {
  .intro__title {
    font-size: 48px;
    line-height: 60px;
  }
}

.intro__link {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 1;
}

@media (min-width: 768px) {
  .intro__link {
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .intro__link {
    padding-top: 30px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1280px) {
  .intro__link {
    padding-top: 35px;
    padding-bottom: 40px;
  }
}

@media (min-width: 1700px) {
  .intro__link {
    padding-top: 40px;
    padding-bottom: 100px;
  }
}

.intro p {
  max-width: 40ch;
}

@media (min-width: 768px) {
  .intro p {
    max-width: none;
  }
}

.structure {
  margin: 0 -20px;
}

@media (min-width: 768px) {
  .structure {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .structure-slider__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: -20px;
  }
}

@media (min-width: 768px) and (min-width: 1440px) {
  .structure-slider__wrapper {
    margin-bottom: -40px;
  }
}

.structure-slider__item {
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .structure-slider__item {
    width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1440px) {
  .structure-slider__item {
    width: calc(50% - 20px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .structure-slider__item:nth-child(2n) {
    margin-right: 0;
  }
}

.structure-slider__link {
  display: block;
  position: relative;
  height: 0;
  padding-top: 49%;
}

.structure-slider__link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 23.81%, #000000 100%);
  opacity: 0.8;
  transition: opacity 0.3s;
}

.structure-slider__link:hover::after {
  opacity: 1;
}

.structure-slider__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: left center;';
  transition: opacity 0.3s;
}

.structure-slider__title {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

@media (min-width: 1024px) {
  .structure-slider__title {
    padding: 25px;
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .structure-slider__title {
    padding: 30px;
  }
}

@media (min-width: 1440px) {
  .structure-slider__title {
    padding: 40px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .structure-slider__btn-prev {
    display: none;
  }
}

@media (min-width: 768px) {
  .structure-slider__btn-next {
    display: none;
  }
}

.history__slider {
  position: relative;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
}

@media (min-width: 768px) {
  .history__slider {
    padding-right: 0;
    padding-left: 0;
  }
}

.history-slider__item {
  box-sizing: border-box;
}

.history-slider__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
}

@media (min-width: 768px) {
  .history-slider__header {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
  }
}

@media (min-width: 1024px) {
  .history-slider__header {
    margin-bottom: 10px;
  }
}

.history-slider__year {
  flex-shrink: 0;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  white-space: nowrap;
  color: #009CA5;
  user-select: none;
}

@media (min-width: 1024px) {
  .history-slider__year {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (min-width: 1440px) {
  .history-slider__year {
    font-size: 60px;
    line-height: 70px;
  }
}

.history-slider__line {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
}

@media (min-width: 768px) {
  .history-slider__line {
    display: flex;
  }
}

.history-slider__line::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 20px);
  height: 2px;
  background-color: #009CA5;
  transform: translate(-50%, -50%);
}

.history-slider__text {
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .history-slider__text {
    max-width: none;
    margin: 0;
    padding-right: 56px;
    text-align: left;
  }
}

@media (min-width: 1280px) {
  .history-slider__text {
    padding-right: 88px;
  }
}

@media (min-width: 1440px) {
  .history-slider__text {
    padding-right: 72px;
  }
}

.history-slider__text p:last-child {
  margin-bottom: 0;
}

.history-slider__btn-prev {
  left: -5px;
}

@media (min-width: 768px) {
  .history-slider__btn-prev {
    top: -30px;
    left: auto;
    right: 35px;
  }
}

@media (min-width: 1024px) {
  .history-slider__btn-prev {
    right: 40px;
  }
}

@media (min-width: 1280px) {
  .history-slider__btn-prev {
    top: -40px;
  }
}

@media (min-width: 1700px) {
  .history-slider__btn-prev {
    top: -50px;
  }
}

.history-slider__btn-next {
  right: -5px;
}

@media (min-width: 768px) {
  .history-slider__btn-next {
    top: -30px;
    right: 0;
  }
}

@media (min-width: 1280px) {
  .history-slider__btn-next {
    top: -40px;
  }
}

@media (min-width: 1700px) {
  .history-slider__btn-next {
    top: -50px;
  }
}

.news-promo {
  margin: 0 -20px;
}

@media (min-width: 768px) {
  .news-promo {
    margin: 0;
  }
}

.news-promo__list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .news-promo__list {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
}

@media (min-width: 1440px) {
  .news-promo__list {
    margin-bottom: -40px;
  }
}

.news-promo__item {
  max-width: 400px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .news-promo__item {
    width: calc(50% - 10px);
    max-width: none;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1440px) {
  .news-promo__item {
    width: calc(50% - 20px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
}

.news-promo__item--theme_bordered .news-promo__link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 15px solid #95C12B;
}

.news-promo__item--theme_bordered .news-promo__img {
  display: none;
}

.news-promo__item--theme_bordered .news-promo__item-title {
  color: #323232;
  border-bottom: 1px solid rgba(50, 50, 50, 0.35);
}

.news-promo__item--theme_bordered .news-promo__item-date {
  color: #323232;
}

@media (min-width: 768px) {
  .news-promo__item:nth-child(2n) {
    margin-right: 0;
  }
}

.news-promo__link {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 47.7333%;
  height: 0;
}

@media (min-width: 1440px) {
  .news-promo__link {
    padding-top: 35.9375%;
  }
}

.news-promo__link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.news-promo__link:hover .news-promo__item-title {
  color: #95C12B;
  border-color: transparent;
}

.news-promo__link:active .news-promo__item-title {
  color: #77A310;
}

.news-promo__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.news-promo__item-title-wrap {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px;
}

@media (min-width: 1024px) {
  .news-promo__item-title-wrap {
    padding: 40px 30px;
  }
}

@media (min-width: 1280px) {
  .news-promo__item-title-wrap {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media (min-width: 1700px) {
  .news-promo__item-title-wrap {
    padding: 50px;
  }
}

.news-promo__item-title {
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  transition: color 0.3s, border-color 0.3s;
}

@media (min-width: 1024px) {
  .news-promo__item-title {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .news-promo__item-title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1440px) {
  .news-promo__item-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.news-promo__item-date {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
}

@media (min-width: 1024px) {
  .news-promo__item-date {
    padding: 40px 30px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .news-promo__item-date {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media (min-width: 1700px) {
  .news-promo__item-date {
    padding: 50px;
  }
}

.logo-slider-wrapper {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
}

.logo-slider__item {
  box-sizing: border-box;
  user-select: none;
}

.logo-slider__logo-link {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 80px;
}

@media (min-width: 768px) {
  .logo-slider__logo-link {
    height: 60px;
  }
}

@media (min-width: 1024px) {
  .logo-slider__logo-link {
    height: 80px;
  }
}

.logo-slider__logo-link:hover .logo-slider__img {
  filter: grayscale(0);
}

.logo-slider__img {
  flex-shrink: 0;
  max-height: 100%;
  filter: grayscale(100%);
  transition: filter 0.3s;
}

.logo-slider__btn-prev {
  left: 0;
}

.logo-slider__btn-next {
  right: 0;
}

.reviews {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .reviews {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 1024px) {
  .reviews {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1440px) {
  .reviews {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1700px) {
  .reviews {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.reviews-slider__item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: auto;
}

.review-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 768px) {
  .review-card {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.review-card__img-wrap {
  flex-shrink: 0;
  height: 50px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .review-card__img-wrap {
    height: 68px;
    margin-right: 30px;
  }
}

@media (min-width: 1024px) {
  .review-card__img-wrap {
    height: 86px;
  }
}

@media (min-width: 1280px) {
  .review-card__img-wrap {
    height: 104px;
    margin-right: 40px;
  }
}

@media (min-width: 1440px) {
  .review-card__img-wrap {
    margin-right: 60px;
  }
}

@media (min-width: 1700px) {
  .review-card__img-wrap {
    margin-right: 100px;
  }
}

.review-card__img {
  max-height: 100%;
}

.promo-map {
  position: relative;
  width: 100%;
  height: 310px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .promo-map {
    height: 455px;
  }
}

@media (min-width: 1024px) {
  .promo-map {
    height: 568px;
  }
}

@media (min-width: 1700px) {
  .promo-map {
    height: 760px;
  }
}

.promo-map::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
}

@media (min-width: 768px) {
  .promo-map::after {
    height: 258px;
  }
}

.promo-map__content {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.promo-map__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

@media (min-width: 0) {
  .promo-map__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .promo-map__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .promo-map__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .promo-map__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .promo-map__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .promo-map__container {
    width: 1620px;
  }
}

.promo-map__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  height: 100%;
}

.promo-map__row > * {
  flex-basis: 100%;
}

.promo-map__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
}

.promo-map__section-title {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .promo-map__section-title {
    padding-top: 40px;
  }
}

@media (min-width: 1700px) {
  .promo-map__section-title {
    padding-top: 60px;
  }
}

.promo-map__contacts {
  display: none;
}

@media (min-width: 768px) {
  .promo-map__contacts {
    display: block;
  }
}

.promo-map__contacts--mobile {
  display: block;
}

@media (min-width: 768px) {
  .promo-map__contacts--mobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .promo-map__contacts {
    padding-bottom: 40px;
  }
}

@media (min-width: 1700px) {
  .promo-map__contacts {
    padding-bottom: 60px;
  }
}

.promo-map__address, .promo-map__phone-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1280px) {
  .promo-map__address, .promo-map__phone-link {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1440px) {
  .promo-map__address, .promo-map__phone-link {
    font-size: 20px;
    line-height: 28px;
  }
}

.promo-map__address {
  margin-bottom: 10px;
}

@media (min-width: 1700px) {
  .promo-map__address {
    margin-bottom: 20px;
  }
}

.map {
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) {
  .top-menu {
    background-color: #F0F0F0;
  }
}

.top-menu__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .top-menu__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .top-menu__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .top-menu__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .top-menu__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .top-menu__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .top-menu__container {
    width: 1620px;
  }
}

.top-menu__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.top-menu__row > * {
  flex-basis: 100%;
}

.top-menu__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.top-menu__filter {
  width: 100%;
  max-width: 500px;
  margin: 15px 0;
}

@media (min-width: 768px) {
  .top-menu__filter {
    margin: 20px 0;
  }
}

@media (min-width: 1024px) {
  .top-menu__filter {
    display: none;
  }
}

.top-menu__list {
  display: none;
}

@media (min-width: 1024px) {
  .top-menu__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    padding-top: 15px;
    list-style: none;
  }
}

.top-menu__item {
  margin-right: 30px;
  margin-bottom: 15px;
}

@media (min-width: 1700px) {
  .top-menu__item {
    margin-right: 40px;
  }
}

.top-menu__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: #646464;
}

@media (min-width: 1700px) {
  .top-menu__link {
    font-size: 16px;
    line-height: 24px;
  }
}

.top-menu__link--active {
  color: #95C12B;
  cursor: default;
}

.top-menu__link--active:hover {
  color: #95C12B;
}

.top-menu__link--active:active {
  color: #95C12B;
}

.main-banner {
  position: relative;
  width: 100%;
  height: 192px;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .main-banner {
    height: 256px;
  }
}

@media (min-width: 1024px) {
  .main-banner {
    height: 320px;
  }
}

@media (min-width: 1440px) {
  .main-banner {
    height: 360px;
  }
}

@media (min-width: 1700px) {
  .main-banner {
    height: 480px;
  }
}

.main-banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.main-banner--img-control .main-banner__img {
  display: none;
}

.main-banner--img-control .main-banner__img--current {
  display: block;
}

.main-banner--img-control .main-banner__title {
  display: none;
}

.main-banner--img-control .main-banner__title--current {
  display: block;
}

.main-banner--img-control .main-banner__title--hidden {
  display: none;
}

.main-banner--interactive::after {
  content: none;
}

.main-banner--interactive .main-banner__img {
  object-fit: none;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.main-banner__img, .main-banner__primary-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.main-banner__img--overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.main-banner__heading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.main-banner__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

@media (min-width: 0) {
  .main-banner__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .main-banner__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .main-banner__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .main-banner__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .main-banner__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .main-banner__container {
    width: 1620px;
  }
}

.main-banner__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  height: 100%;
}

.main-banner__row > * {
  flex-basis: 100%;
}

.main-banner__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
}

.main-banner__title {
  margin: 0;
}

.about__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.about__row > * {
  flex-basis: 100%;
}

.about__text {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .about__text {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .about__text {
    margin-bottom: 0;
  }
}

.about__action {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media (min-width: 1024px) {
  .about__action {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .about__action {
    justify-content: flex-end;
  }
}

.about__btn-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .about__btn-wrap {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .about__btn-wrap {
    flex-direction: column;
  }
}

.about__btn {
  min-width: 225px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .about__btn {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .about__btn {
    min-width: 250px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (min-width: 1280px) {
  .about__btn {
    min-width: 315px;
  }
}

.about__btn:last-child {
  margin-bottom: 0;
}

.download-link-wrap + .download-link-wrap {
  margin-top: 20px;
}

.download-link {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.download-link:hover .download-link__icon, .download-link:active .download-link__icon {
  stroke: #009CA5;
}

.download-link:hover .download-link__title, .download-link:active .download-link__title {
  color: #323232;
}

.download-link:hover .download-link__tip, .download-link:active .download-link__tip {
  color: #323232;
}

.download-link--doc .download-link__icon-wrap {
  width: 30px;
  height: 38px;
  margin-right: 15px;
}

.download-link--doc .download-link__icon {
  stroke: #95C12B;
}

.download-link--doc .download-link__title {
  display: inline;
  margin-bottom: 0;
  font-weight: 400;
  color: #323232;
  border-bottom: 1px solid #C4C4C4;
}

@media (min-width: 768px) {
  .download-link--doc .download-link__title {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) {
  .download-link--doc .download-link__title {
    font-size: 18px;
    line-height: 26px;
  }
}

.download-link--doc .download-link__tip {
  margin-top: 10px;
  display: block;
}

.download-link--doc:hover .download-link__icon, .download-link--doc:active .download-link__icon {
  stroke: #77A310;
}

.download-link--doc:hover .download-link__title, .download-link--doc:active .download-link__title {
  color: #000000;
  border-color: transparent;
}

.download-link--doc:hover .download-link__tip, .download-link--doc:active .download-link__tip {
  color: #000000;
}

.download-link__icon-wrap {
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.download-link__icon {
  fill: none;
  stroke: #95C12B;
  transition: stroke 0.3s;
}

.download-link__title, .download-link__tip {
  font-size: 14px;
  line-height: 20px;
  color: #646464;
  transition: color, border-color;
  transition-duration: 0.3s;
}

@media (min-width: 1700px) {
  .download-link__title, .download-link__tip {
    font-size: 16px;
    line-height: 24px;
  }
}

.download-link__title {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.download-link__tip {
  font-weight: 400;
  text-transform: uppercase;
}

.download-link__tip span {
  text-transform: none;
}

.mobile-slider-wrapper {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
}

@media (min-width: 768px) {
  .mobile-slider-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.mobile-slider-wrapper--team .mobile-slider__btn-prev,
.mobile-slider-wrapper--team .mobile-slider__btn-next {
  top: 45px;
  transform: translateY(0);
}

@media (min-width: 768px) {
  .mobile-slider--number .mobile-slider__wrapper {
    margin-bottom: -20px;
  }
}

@media (min-width: 1280px) {
  .mobile-slider--number .mobile-slider__wrapper {
    margin-bottom: -40px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--number .mobile-slider__item {
    margin-bottom: 20px;
    width: 160px;
    margin-right: 60px;
  }
}

@media (min-width: 1024px) {
  .mobile-slider--number .mobile-slider__item {
    width: 25%;
    margin-right: 0;
    padding-right: 50px;
  }
}

@media (min-width: 1280px) {
  .mobile-slider--number .mobile-slider__item {
    margin-bottom: 40px;
    padding-right: 100px;
  }
}

@media (min-width: 1700px) {
  .mobile-slider--number .mobile-slider__item {
    padding-right: 70px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--team .mobile-slider__wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -40px;
  }
}

@media (min-width: 1700px) {
  .mobile-slider--team .mobile-slider__wrapper {
    margin-bottom: -60px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--team .mobile-slider__item {
    margin-bottom: 40px;
  }
}

@media (min-width: 1700px) {
  .mobile-slider--team .mobile-slider__item {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--icons .mobile-slider__wrapper {
    margin-bottom: -30px;
  }
}

@media (min-width: 1024px) {
  .mobile-slider--icons .mobile-slider__wrapper {
    margin-bottom: -40px;
  }
}

@media (min-width: 1440px) {
  .mobile-slider--icons .mobile-slider__wrapper {
    margin-bottom: -60px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--icons .mobile-slider__item {
    width: 165px;
    margin-right: 40px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  .mobile-slider--icons .mobile-slider__item {
    width: 230px;
    margin-right: 40px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1280px) {
  .mobile-slider--icons .mobile-slider__item {
    width: 265px;
  }
}

@media (min-width: 1440px) {
  .mobile-slider--icons .mobile-slider__item {
    width: 315px;
    margin-right: 80px;
    margin-bottom: 60px;
  }
}

@media (min-width: 1700px) {
  .mobile-slider--icons .mobile-slider__item {
    margin-right: 90px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--icons_short .mobile-slider__wrapper {
    margin-bottom: -20px;
  }
}

@media (min-width: 1024px) {
  .mobile-slider--icons_short .mobile-slider__wrapper {
    margin-bottom: -30px;
  }
}

@media (min-width: 1440px) {
  .mobile-slider--icons_short .mobile-slider__wrapper {
    margin-bottom: -40px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--icons_short .mobile-slider__item {
    width: 140px;
    margin-right: 70px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) {
  .mobile-slider--icons_short .mobile-slider__item {
    margin-right: 40px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .mobile-slider--icons_short .mobile-slider__item {
    margin-right: 80px;
  }
}

@media (min-width: 1440px) {
  .mobile-slider--icons_short .mobile-slider__item {
    margin-right: 80px;
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--residents .mobile-slider__wrapper {
    align-items: stretch;
    margin-bottom: -20px;
  }
}

@media (min-width: 1440px) {
  .mobile-slider--residents .mobile-slider__wrapper {
    margin-bottom: -40px;
  }
}

.mobile-slider--residents .mobile-slider__item {
  height: auto;
}

@media (min-width: 768px) {
  .mobile-slider--residents .mobile-slider__item {
    display: flex;
    width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1440px) {
  .mobile-slider--residents .mobile-slider__item {
    width: calc(50% - 20px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
}

.mobile-slider--residents .mobile-slider__item:nth-child(2n) {
  margin-right: 0;
}

@media (min-width: 768px) {
  .mobile-slider--experts .mobile-slider__wrapper {
    margin-bottom: -20px;
  }
}

@media (min-width: 1024px) {
  .mobile-slider--experts .mobile-slider__wrapper {
    margin-bottom: -30px;
  }
}

@media (min-width: 1280px) {
  .mobile-slider--experts .mobile-slider__wrapper {
    margin-bottom: -40px;
  }
}

@media (min-width: 1700px) {
  .mobile-slider--experts .mobile-slider__wrapper {
    margin-bottom: -60px;
  }
}

@media (min-width: 768px) {
  .mobile-slider--experts .mobile-slider__item {
    width: 50%;
    margin-bottom: 20px;
    padding-right: 40px;
  }
}

@media (min-width: 1024px) {
  .mobile-slider--experts .mobile-slider__item {
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .mobile-slider--experts .mobile-slider__item {
    margin-bottom: 40px;
  }
}

@media (min-width: 1700px) {
  .mobile-slider--experts .mobile-slider__item {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .mobile-slider__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
  }
}

.mobile-slider__item {
  box-sizing: border-box;
}

.mobile-slider__btn-prev {
  left: 0;
}

@media (min-width: 768px) {
  .mobile-slider__btn-prev {
    display: none;
  }
}

.mobile-slider__btn-next {
  right: 0;
}

@media (min-width: 768px) {
  .mobile-slider__btn-next {
    display: none;
  }
}

.number-slide {
  text-align: center;
  user-select: none;
}

@media (min-width: 768px) {
  .number-slide {
    text-align: left;
  }
}

.number-slide__count {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  color: #95C12B;
}

@media (min-width: 1024px) {
  .number-slide__count {
    font-size: 60px;
    line-height: 70px;
  }
}

@media (min-width: 1440px) {
  .number-slide__count {
    margin-bottom: 10px;
    font-size: 96px;
    line-height: 96px;
  }
}

.number-slide__count-tip {
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
}

@media (min-width: 1024px) {
  .number-slide__count-tip {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .number-slide__count-tip {
    font-size: 24px;
    line-height: 32px;
  }
}

.number-slide__text {
  margin: 0;
}

.dual-slider {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .dual-slider {
    flex-direction: row;
  }
}

.dual-slider__first {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 260px;
  background-color: #F0F0F0;
}

@media (min-width: 768px) {
  .dual-slider__first {
    width: 50%;
    height: auto;
  }
}

.dual-slider__second {
  flex-shrink: 0;
  width: 100%;
  background-color: #F0F0F0;
}

@media (min-width: 768px) {
  .dual-slider__second {
    width: 50%;
  }
}

.dual-slider__nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 48px;
  border: 1px solid rgba(235, 235, 235, 0.5);
  border-right: none;
  border-left: none;
  overflow: hidden;
}

.dual-slider__counter {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-right: 1px solid rgba(235, 235, 235, 0.5);
}

.dual-slider__counter-wrap {
  font-size: 14px;
  line-height: 24px;
  color: #C8C8C8;
}

@media (min-width: 768px) {
  .dual-slider__counter-wrap {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (min-width: 1024px) {
  .dual-slider__counter-wrap {
    font-size: 20px;
    line-height: 26px;
  }
}

.dual-slider__arrows {
  position: relative;
  width: 100%;
}

.dual-slider-text {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.dual-slider-text__item {
  box-sizing: border-box;
}

.dual-slider-text__content {
  padding: 30px 20px;
}

@media (min-width: 768px) {
  .dual-slider-text__content {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

@media (min-width: 1024px) {
  .dual-slider-text__content {
    padding: 60px 25px;
  }
}

@media (min-width: 1280px) {
  .dual-slider-text__content {
    padding: 70px 40px;
  }
}

@media (min-width: 1440px) {
  .dual-slider-text__content {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media (min-width: 1700px) {
  .dual-slider-text__content {
    padding: 160px 160px 160px 70px;
  }
}

.dual-slider-text__counter {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #C8C8C8;
}

@media (min-width: 1024px) {
  .dual-slider-text__counter {
    font-size: 60px;
    line-height: 70px;
  }
}

@media (min-width: 1280px) {
  .dual-slider-text__counter {
    margin-bottom: 30px;
    font-size: 96px;
    line-height: 96px;
  }
}

.dual-slider-text__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

@media (min-width: 1280px) {
  .dual-slider-text__title {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1440px) {
  .dual-slider-text__title {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (min-width: 1700px) {
  .dual-slider-text__title {
    margin-bottom: 20px;
  }
}

.dual-slider-text__nav {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 62px;
  height: 23px;
}

@media (min-width: 1024px) {
  .dual-slider-text__nav {
    top: 40px;
  }
}

@media (min-width: 1280px) {
  .dual-slider-text__nav {
    top: 40px;
    right: 40px;
  }
}

@media (min-width: 1440px) {
  .dual-slider-text__nav {
    right: 60px;
  }
}

@media (min-width: 1700px) {
  .dual-slider-text__nav {
    top: 70px;
    right: 160px;
  }
}

.dual-slider-text__btn-prev {
  left: 0;
}

.dual-slider-text__btn-next {
  right: 0;
}

.dual-slider-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dual-slider-image__item {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.dual-slider-image__img-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.dual-slider-image__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.accordion--building .accordion__title {
  position: relative;
  padding-left: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #646464;
}

@media (min-width: 1024px) {
  .accordion--building .accordion__title {
    padding-left: 24px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .accordion--building .accordion__title {
    font-size: 18px;
    line-height: 26px;
  }
}

.accordion--building .accordion__title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 11px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 5.88393C10.5 6.69115 10.1998 7.53059 9.71043 8.3568C9.22258 9.18043 8.56467 9.9606 7.89417 10.6398C7.22522 11.3174 6.55437 11.8839 6.04994 12.2814C5.82921 12.4553 5.64111 12.5962 5.5 12.6993C5.35889 12.5962 5.17079 12.4553 4.95006 12.2814C4.44563 11.8839 3.77478 11.3174 3.10583 10.6398C2.43533 9.9606 1.77742 9.18043 1.28957 8.3568C0.800209 7.53059 0.5 6.69115 0.5 5.88393C0.5 3.07695 2.74464 0.8125 5.5 0.8125C8.25536 0.8125 10.5 3.07695 10.5 5.88393Z' fill='%2395C12B' stroke='%2395C12B'/%3E%3Ccircle cx='5.5' cy='5.8125' r='2.5' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transform: translateY(-50%);
}

@media (min-width: 1024px) {
  .accordion--building .accordion__title::before {
    width: 14px;
    height: 18px;
  }
}

.accordion--building .accordion__sub-list {
  padding-bottom: 5px;
}

.accordion__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.accordion__item {
  border-bottom: 1px solid #EBEBEB;
}

.accordion__item--active .accordion__title::after {
  top: calc(50% - 8px);
  right: 10px;
  left: auto;
  transform-origin: center center;
  transform: rotate(-135deg) translateY(0);
}

.accordion__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .accordion__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .accordion__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .accordion__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .accordion__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .accordion__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .accordion__container {
    width: 1620px;
  }
}

.accordion__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.accordion__row > * {
  flex-basis: 100%;
}

.accordion__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion__title {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #323232;
}

@media (min-width: 768px) {
  .accordion__title {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1024px) {
  .accordion__title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1280px) {
  .accordion__title {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 24px;
    line-height: 32px;
  }
}

.accordion__title:hover {
  color: #323232;
}

.accordion__title::after {
  top: calc(50% - 8px);
  right: 10px;
  left: auto;
  transform-origin: center center;
  transform: rotate(45deg) translateY(0);
}

.accordion__sub-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.accordion__content {
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .accordion__content {
    padding-bottom: 20px;
  }
}

@media (min-width: 1280px) {
  .accordion__content {
    padding-bottom: 30px;
  }
}

.accordion__sub-item--info-doc {
  padding: 10px 0;
}

.info-doc__accordion .accordion__sub-item {
  padding: 10px 0;
}

.info-doc__accordion .accordion__sub-item:first-child {
  padding-top: 5px;
}

@media (min-width: 1280px) {
  .info-doc__accordion .accordion__sub-item:first-child {
    padding-top: 0;
  }
}

.info-doc__accordion .accordion__sub-item:last-child {
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .info-doc__accordion .accordion__sub-item:last-child {
    padding-bottom: 25px;
  }
}

@media (min-width: 1280px) {
  .info-doc__accordion .accordion__sub-item:last-child {
    padding-bottom: 30px;
  }
}

.service-menu {
  padding-top: 20px;
}

@media (min-width: 1024px) {
  .service-menu {
    padding-top: 30px;
  }
}

@media (min-width: 1280px) {
  .service-menu {
    padding-top: 40px;
  }
}

@media (min-width: 1700px) {
  .service-menu {
    padding-top: 80px;
  }
}

.service-sub-menu .tabs {
  padding-top: 45px;
}

@media (min-width: 768px) {
  .service-sub-menu .tabs {
    padding-top: 55px;
  }
}

@media (min-width: 1024px) {
  .service-sub-menu .tabs {
    padding-top: 70px;
  }
}

@media (min-width: 1280px) {
  .service-sub-menu .tabs {
    padding-top: 80px;
  }
}

@media (min-width: 1440px) {
  .service-sub-menu .tabs {
    padding-top: 120px;
  }
}

.service-sub-menu .tabs__list {
  display: flex;
}

.tabs__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 0) {
  .tabs__container {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .tabs__container {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .tabs__container {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .tabs__container {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .tabs__container {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .tabs__container {
    width: 1620px;
  }
}

.tabs__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.tabs__row > * {
  flex-basis: 100%;
}

.tabs__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.tabs__filter {
  width: 100%;
  max-width: 500px;
}

@media (min-width: 1024px) {
  .tabs__filter {
    display: none;
  }
}

.tabs__list {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1024px) {
  .tabs__list {
    display: flex;
  }
}

.tabs__item {
  display: block;
  margin: -1px 0 0 -1px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #646464;
  border: 1px solid #DCDCDC;
  cursor: pointer;
  user-select: none;
}

@media (min-width: 1700px) {
  .tabs__item {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

.tabs__item--current {
  color: #FFFFFF;
  background-color: #009CA5;
}

.tabs__item--current:hover {
  color: #FFFFFF;
}

.tabs__content {
  display: none;
}

.tabs__content--current {
  display: block;
}

.steps-slider-wrapper {
  position: relative;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
}

@media (min-width: 768px) {
  .steps-slider-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.steps-slider__item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: auto;
  height: auto;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .steps-slider__item {
    align-items: center;
    width: 265px;
    padding-right: 65px;
  }
}

.steps-slider__item::after {
  content: "";
  position: absolute;
}

@media (min-width: 768px) {
  .steps-slider__item:first-child {
    align-items: flex-start;
    width: 205px;
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  .steps-slider__item:first-child .steps-slider__item-inner {
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .steps-slider__item:first-child .steps-slider__text {
    padding: 0;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .steps-slider__item:last-child {
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .steps-slider__item:last-child .steps-slider__step-wrap::after {
    width: 50%;
  }
}

.steps-slider__item-inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.steps-slider__step-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
  background-color: #FFFFFF;
  border: 3px solid #009CA5;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
}

.steps-slider__step-wrap::after {
  content: none;
  position: absolute;
  top: 28px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 3px;
  background-color: #009CA5;
}

@media (min-width: 768px) {
  .steps-slider__step-wrap::after {
    content: "";
  }
}

.steps-slider__step {
  flex-shrink: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #009CA5;
}

.steps-slider__text {
  text-align: center;
}

.steps-slider__btn-prev {
  left: -5px;
}

@media (min-width: 768px) {
  .steps-slider__btn-prev {
    top: -30px;
    left: auto;
    right: 35px;
  }
}

@media (min-width: 1024px) {
  .steps-slider__btn-prev {
    right: 40px;
  }
}

@media (min-width: 1280px) {
  .steps-slider__btn-prev {
    top: -40px;
  }
}

@media (min-width: 1700px) {
  .steps-slider__btn-prev {
    top: -50px;
  }
}

.steps-slider__btn-next {
  right: -5px;
}

@media (min-width: 768px) {
  .steps-slider__btn-next {
    top: -30px;
    right: 0;
  }
}

@media (min-width: 1280px) {
  .steps-slider__btn-next {
    top: -40px;
  }
}

@media (min-width: 1700px) {
  .steps-slider__btn-next {
    top: -50px;
  }
}

.team-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .team-card {
    flex-direction: row;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .team-card--expert .team-card__img-wrap {
    margin-right: 15px;
  }
}

@media (min-width: 1024px) {
  .team-card--expert .team-card__img-wrap {
    width: 160px;
    height: 160px;
    margin-right: 20px;
  }
}

@media (min-width: 1280px) {
  .team-card--expert .team-card__img-wrap {
    width: 200px;
    height: 200px;
    margin-right: 40px;
  }
}

@media (min-width: 1440px) {
  .team-card--expert .team-card__img-wrap {
    width: 240px;
    height: 240px;
  }
}

.team-card--expert .team-card__name {
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .team-card--expert .team-card__name {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .team-card--expert .team-card__name {
    font-size: 24px;
    line-height: 32px;
  }
}

.team-card__img-wrap {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .team-card__img-wrap {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .team-card__img-wrap {
    width: 230px;
    height: 230px;
    margin-right: 40px;
  }
}

@media (min-width: 1280px) {
  .team-card__img-wrap {
    width: 250px;
    height: 250px;
  }
}

.team-card__name {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #009CA5;
}

@media (min-width: 768px) {
  .team-card__name {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1024px) {
  .team-card__name {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1280px) {
  .team-card__name {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;
  }
}

.team-card__position {
  display: block;
  max-width: 80ch;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #323232;
}

@media (min-width: 768px) {
  .team-card__position {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) {
  .team-card__position {
    margin-bottom: 25px;
  }
}

@media (min-width: 1280px) {
  .team-card__position {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 26px;
  }
}

.team-card__links {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  .team-card__links {
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .team-card__links {
    margin-bottom: 35px;
  }
}

@media (min-width: 1440px) {
  .team-card__links {
    margin-bottom: 40px;
  }
}

@media (min-width: 1700px) {
  .team-card__links {
    margin-bottom: 50px;
  }
}

.team-card__link-wrap {
  display: block;
  white-space: nowrap;
}

.team-card__link-wrap + .team-card__link-wrap {
  margin-top: 10px;
}

.team-card__link, .team-card__link-tip {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #323232;
}

@media (min-width: 768px) {
  .team-card__link, .team-card__link-tip {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 768px) {
  .team-card__link, .team-card__link-tip {
    font-size: 18px;
    line-height: 26px;
  }
}

.icon-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .icon-card {
    align-items: flex-start;
    text-align: left;
  }
}

.icon-card__icon-wrap {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  user-select: none;
}

@media (min-width: 1024px) {
  .icon-card__icon-wrap {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
}

.icon-card__text :last-child {
  margin-bottom: 0;
}

.image-slider-wrap {
  margin-right: -20px;
  margin-left: -20px;
}

@media (min-width: 480px) {
  .image-slider-wrap {
    margin-right: 0;
    margin-left: 0;
  }
}

.image-slider {
  margin: 0;
  width: 100%;
  max-width: 565px;
}

@media (min-width: 768px) {
  .image-slider {
    max-width: none;
    width: 565px;
  }
}

@media (min-width: 1280px) {
  .image-slider {
    width: 650px;
  }
}

@media (min-width: 1700px) {
  .image-slider {
    width: 950px;
  }
}

.image-slider--news {
  width: 100%;
  max-width: 650px;
}

@media (min-width: 1024px) {
  .image-slider--news {
    width: 100%;
    max-width: none;
  }
}

.image-slider--news .image-slider__img-wrap {
  height: auto;
  padding-top: 0;
  text-align: left;
  background-color: #FFFFFF;
}

.image-slider--news .image-slider__img-wrap::after {
  content: none;
}

.image-slider--news .image-slider__img {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain; object-position: center center;';
}

.image-slider--news .image-slider__btn-prev {
  top: 20px;
  left: 20px;
  transform: translateY(0);
}

@media (min-width: 768px) {
  .image-slider--news .image-slider__btn-prev {
    left: 20px;
  }
}

.image-slider--news .image-slider__btn-next {
  top: 20px;
  left: 50px;
  transform: translateY(0);
}

@media (min-width: 768px) {
  .image-slider--news .image-slider__btn-next {
    left: 70px;
  }
}

.image-slider__item {
  width: auto;
  height: auto;
  box-sizing: border-box;
}

.image-slider__img-wrap {
  position: relative;
  height: 0;
  padding-top: 56.25%;
}

.image-slider__img-wrap::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(50, 50, 50, 0.4) -26.93%, rgba(50, 50, 50, 0) 100%);
}

.image-slider__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.image-slider__btn-prev {
  left: 20px;
}

@media (min-width: 768px) {
  .image-slider__btn-prev {
    top: 20px;
    left: auto;
    right: 60px;
    transform: translateY(0);
  }
}

@media (min-width: 1700px) {
  .image-slider__btn-prev {
    right: 75px;
  }
}

.image-slider__btn-next {
  right: 20px;
}

@media (min-width: 768px) {
  .image-slider__btn-next {
    top: 20px;
    transform: translateY(0);
  }
}

@media (min-width: 1700px) {
  .image-slider__btn-next {
    right: 30px;
  }
}

.info-block-wrap {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .info-block-wrap {
    padding-top: 30px;
  }
}

@media (min-width: 1280px) {
  .info-block-wrap {
    padding-top: 40px;
  }
}

.info-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 768px) {
  .info-block {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.info-block__img-wrap {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .info-block__img-wrap {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.info-block__content :last-child {
  margin-bottom: 0;
}

.info-block__content p {
  max-width: 60ch;
}

.doc-grid__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
}

.doc-grid__row > * {
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .doc-grid__row {
    margin-bottom: -30px;
  }
}

.doc-grid__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .doc-grid__col {
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .doc-grid__col {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .doc-grid__col {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .doc-grid__col {
    margin-bottom: 30px;
  }
}

.gallery-slider-wrap {
  position: relative;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
}

@media (min-width: 768px) {
  .gallery-slider-wrap {
    padding-right: 0;
    padding-left: 0;
  }
}

.gallery-slider__item {
  box-sizing: border-box;
}

.gallery-slider__item-inner {
  max-width: 494px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .gallery-slider__item-inner {
    max-width: none;
    margin: 0;
  }
}

.gallery-slider__img-wrap {
  position: relative;
  height: 0;
  padding-top: 66.5992%;
}

.gallery-slider__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.gallery-slider__text {
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

@media (min-width: 768px) {
  .gallery-slider__text {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .gallery-slider__text {
    margin-top: 15px;
  }
}

@media (min-width: 1280px) {
  .gallery-slider__text {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1440px) {
  .gallery-slider__text {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1700px) {
  .gallery-slider__text {
    margin-top: 20px;
    font-size: 24px;
    line-height: 32px;
  }
}

.gallery-slider__btn-prev {
  left: -5px;
}

@media (min-width: 768px) {
  .gallery-slider__btn-prev {
    top: -30px;
    left: auto;
    right: 35px;
  }
}

@media (min-width: 1024px) {
  .gallery-slider__btn-prev {
    right: 40px;
  }
}

@media (min-width: 1280px) {
  .gallery-slider__btn-prev {
    top: -40px;
  }
}

@media (min-width: 1700px) {
  .gallery-slider__btn-prev {
    top: -50px;
  }
}

.gallery-slider__btn-next {
  right: -5px;
}

@media (min-width: 768px) {
  .gallery-slider__btn-next {
    top: -30px;
    right: 0;
  }
}

@media (min-width: 1280px) {
  .gallery-slider__btn-next {
    top: -40px;
  }
}

@media (min-width: 1700px) {
  .gallery-slider__btn-next {
    top: -50px;
  }
}

.contacts {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.contacts > * {
  flex-basis: 100%;
}

.contacts__info {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .contacts__info {
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .contacts__info {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .contacts__info {
    margin-bottom: 0;
  }
}

.contacts__map {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .contacts__map {
    flex: 0 0 60%;
    max-width: 60%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .contacts__map {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.contacts__map-wrap {
  margin: 0 -20px;
  height: 340px;
}

@media (min-width: 768px) {
  .contacts__map-wrap {
    width: 100%;
    height: 380px;
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .contacts__map-wrap {
    height: 420px;
  }
}

@media (min-width: 1280px) {
  .contacts__map-wrap {
    height: 515px;
  }
}

@media (min-width: 1440px) {
  .contacts__map-wrap {
    height: 570px;
  }
}

@media (min-width: 1700px) {
  .contacts__map-wrap {
    height: 690px;
  }
}

.contacts-list {
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;
}

@media (min-width: 1700px) {
  .contacts-list {
    margin-bottom: 40px;
  }
}

.contacts-list__item {
  margin-bottom: 20px;
}

@media (min-width: 1700px) {
  .contacts-list__item {
    margin-bottom: 30px;
  }
}

.contacts-list__item:last-child {
  margin-bottom: 0;
}

.contacts-list__link-wrap {
  display: block;
  white-space: nowrap;
}

.contacts-list__title, .contacts-list__text, .contacts-list__phone-link, .contacts-list__email-link, .contacts-list__link-tip {
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .contacts-list__title, .contacts-list__text, .contacts-list__phone-link, .contacts-list__email-link, .contacts-list__link-tip {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .contacts-list__title, .contacts-list__text, .contacts-list__phone-link, .contacts-list__email-link, .contacts-list__link-tip {
    font-size: 20px;
    line-height: 28px;
  }
}

.contacts-list__title {
  margin-bottom: 5px;
  font-weight: 600;
}

.contacts-list__phone-link, .contacts-list__email-link {
  color: #323232;
}

.contacts-list__phone-link:hover, .contacts-list__email-link:hover {
  color: #95C12B;
}

.contacts-list__phone-link:active, .contacts-list__email-link:active {
  color: #77A310;
}

.contacts-list__email-link {
  color: #969696;
}

.price-list__list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

.price-list__row {
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
}

.price-list__row-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px;
}

@media (min-width: 768px) {
  .price-list__row-inner {
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 auto;
    padding: 15px 0;
  }
}

@media (min-width: 1024px) {
  .price-list__row-inner {
    width: 984px;
  }
}

@media (min-width: 1280px) {
  .price-list__row-inner {
    width: 1200px;
  }
}

@media (min-width: 1440px) {
  .price-list__row-inner {
    width: 1320px;
  }
}

@media (min-width: 1700px) {
  .price-list__row-inner {
    width: 1600px;
  }
}

.price-list__col:nth-child(1) {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .price-list__col:nth-child(1) {
    width: 200px;
    margin-right: 25px;
    margin-bottom: 0;
    margin-left: 25px;
  }
}

@media (min-width: 1024px) {
  .price-list__col:nth-child(1) {
    width: 225px;
    margin-left: 0;
  }
}

@media (min-width: 1280px) {
  .price-list__col:nth-child(1) {
    width: 285px;
  }
}

@media (min-width: 1440px) {
  .price-list__col:nth-child(1) {
    width: 300px;
    margin-right: 40px;
  }
}

.price-list__col:nth-child(2) {
  width: 100%;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .price-list__col:nth-child(2) {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .price-list__col:nth-child(2) {
    flex-grow: 1;
    width: auto;
    margin-bottom: 0;
    margin-right: 25px;
  }
}

@media (min-width: 1440px) {
  .price-list__col:nth-child(2) {
    margin-right: 40px;
  }
}

.price-list__col:nth-child(3) {
  flex-shrink: 0;
  width: 50%;
}

@media (min-width: 768px) {
  .price-list__col:nth-child(3) {
    width: 100px;
    margin-bottom: 0;
    margin-right: 25px;
  }
}

@media (min-width: 1440px) {
  .price-list__col:nth-child(3) {
    margin-right: 40px;
  }
}

.price-list__col:nth-child(4) {
  flex-shrink: 0;
  width: 50%;
}

@media (min-width: 768px) {
  .price-list__col:nth-child(4) {
    width: 100px;
    margin-bottom: 0;
    margin-right: 25px;
  }
}

@media (min-width: 1440px) {
  .price-list__col:nth-child(4) {
    margin-right: 40px;
  }
}

@media (min-width: 1700px) {
  .price-list__col:nth-child(4) {
    margin-right: 250px;
  }
}

.price-list__caption {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 18px;
  color: #646464;
}

@media (min-width: 1024px) {
  .price-list__caption {
    display: none;
  }
}

.price-list__object, .price-list__description, .price-list__time, .price-list__price {
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1280px) {
  .price-list__object, .price-list__description, .price-list__time, .price-list__price {
    font-size: 16px;
    line-height: 24px;
  }
}

.price-list__object {
  color: #646464;
}

.price-list__time {
  font-weight: 600;
}

.price-list__price {
  font-weight: 600;
  color: #009CA5;
}

@media (min-width: 1024px) {
  .price-list__mobile-filter {
    display: none;
  }
}

.price-list__filter {
  display: none;
}

@media (min-width: 1024px) {
  .price-list__filter {
    display: block;
  }
}

.info-management__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.info-management__row > * {
  flex-basis: 100%;
}

.info-management__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .info-management__col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .info-management__col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.info-management__col--content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.info-management__img-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin: 0 -20px;
  padding-top: 25px;
  text-align: center;
  background-color: #F0F0F0;
}

@media (min-width: 768px) {
  .info-management__img-wrap {
    margin: 0;
    padding-top: 30px;
    background-color: transparent;
  }
}

@media (min-width: 1280px) {
  .info-management__img-wrap {
    padding-top: 90px;
  }
}

@media (min-width: 1440px) {
  .info-management__img-wrap {
    padding-top: 30px;
  }
}

@media (min-width: 1700px) {
  .info-management__img-wrap {
    padding-top: 110px;
  }
}

.info-management__img {
  flex-shrink: 0;
  max-height: 281px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .info-management__img {
    max-height: 309px;
  }
}

@media (min-width: 1024px) {
  .info-management__img {
    max-height: 388px;
  }
}

@media (min-width: 1280px) {
  .info-management__img {
    max-height: 405px;
  }
}

@media (min-width: 1440px) {
  .info-management__img {
    max-height: 461px;
  }
}

@media (min-width: 1700px) {
  .info-management__img {
    max-height: 551px;
  }
}

.info-management .team-card {
  margin: 0 -20px;
  padding: 20px;
  text-align: left;
}

@media (min-width: 768px) {
  .info-management .team-card {
    margin: 0;
    padding: 30px 10px;
  }
}

@media (min-width: 1024px) {
  .info-management .team-card {
    margin: 0;
    padding: 60px 30px;
  }
}

@media (min-width: 1280px) {
  .info-management .team-card {
    padding: 60px 50px;
  }
}

@media (min-width: 1700px) {
  .info-management .team-card {
    padding-right: 60px;
    padding-left: 60px;
  }
}

.client-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .client-card {
    flex: 1;
    align-items: flex-start;
    padding: 20px;
    text-align: left;
    border: 1px solid #DCDCDC;
  }
}

@media (min-width: 1280px) {
  .client-card {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media (min-width: 1700px) {
  .client-card {
    padding: 40px 60px;
  }
}

.client-card__logo-wrap {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 60px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .client-card__logo-wrap {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .client-card__logo-wrap {
    height: 80px;
  }
}

@media (min-width: 1280px) {
  .client-card__logo-wrap {
    height: 110px;
  }
}

.client-card__img {
  flex-shrink: 0;
  max-height: 60px;
  transition: opacity 0.3s ease;
}

@media (min-width: 1024px) {
  .client-card__img {
    max-height: 80px;
  }
}

@media (min-width: 1280px) {
  .client-card__img {
    max-height: 100px;
  }
}

.client-card__img:hover {
  opacity: 0.7;
}

.client-card__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .client-card__title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .client-card__title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1440px) {
  .client-card__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
  }
}

.client-card__text {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .client-card__text {
    flex: 1;
  }
}

@media (min-width: 1024px) {
  .client-card__text {
    margin-bottom: 20px;
  }
}

@media (min-width: 1700px) {
  .client-card__text {
    margin-bottom: 30px;
  }
}

.client-card__link {
  font-size: 16px;
  line-height: 24px;
  color: #95C12B;
}

@media (min-width: 1700px) {
  .client-card__link {
    font-size: 18px;
    line-height: 26px;
  }
}

.client-card__link:hover {
  color: #77A310;
}

.client-card__link:active {
  color: #77A310;
}

.info-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  padding: 0;
  list-style: none;
}

.info-list > * {
  flex-basis: 100%;
}

@media (min-width: 1024px) {
  .info-list {
    margin-bottom: -30px;
  }
}

@media (min-width: 1280px) {
  .info-list {
    margin-bottom: -40px;
  }
}

.info-list__item {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .info-list__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .info-list__item {
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .info-list__item {
    margin-bottom: 40px;
  }
}

.info-list__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .info-list__title {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) {
  .info-list__title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1700px) {
  .info-list__title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
}

.media-grid {
  width: 100%;
}

.media-grid__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  margin-bottom: -30px;
  padding: 0;
  list-style: none;
}

@media (min-width: 1024px) {
  .media-grid__list {
    margin-bottom: -40px;
  }
}

@media (min-width: 1280px) {
  .media-grid__list {
    margin-bottom: -50px;
  }
}

@media (min-width: 1440px) {
  .media-grid__list {
    margin-bottom: -60px;
  }
}

.media-grid__item {
  width: 100%;
  max-width: 482px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .media-grid__item {
    width: calc(50% - 10px);
    max-width: none;
    margin-right: 20px;
  }
}

@media (min-width: 1024px) {
  .media-grid__item {
    width: 314px;
    margin-right: 21px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1280px) {
  .media-grid__item {
    width: 386px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1440px) {
  .media-grid__item {
    width: 426px;
    margin-bottom: 60px;
  }
}

@media (min-width: 1700px) {
  .media-grid__item {
    width: 520px;
    margin-right: 20px;
  }
}

.media-grid__item:nth-child(2n) {
  margin-right: 0;
}

@media (min-width: 1024px) {
  .media-grid__item:nth-child(2n) {
    margin-right: 21px;
  }
}

@media (min-width: 1700px) {
  .media-grid__item:nth-child(2n) {
    margin-right: 20px;
  }
}

.media-grid__item:hover .media-grid__img {
  opacity: 0.8;
}

@media (min-width: 1024px) {
  .media-grid__item:nth-child(3n) {
    margin-right: 0;
  }
}

.media-grid__img-wrap {
  display: block;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  padding-top: 56.1538%;
  height: 0;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .media-grid__img-wrap {
    height: 176px;
    padding-top: 0;
  }
}

@media (min-width: 1280px) {
  .media-grid__img-wrap {
    height: 217px;
  }
}

@media (min-width: 1440px) {
  .media-grid__img-wrap {
    height: 239px;
  }
}

@media (min-width: 1700px) {
  .media-grid__img-wrap {
    margin-bottom: 20px;
    height: 292px;
  }
}

.media-grid__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
  transition: opacity 0.3s ease;
}

@media (min-width: 1024px) {
  .media-grid__img {
    position: relative;
    top: auto;
    left: auto;
  }
}

.media-grid__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .media-grid__title {
    margin-bottom: 15px;
  }
}

@media (min-width: 1440px) {
  .media-grid__title {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1700px) {
  .media-grid__title {
    font-size: 20px;
    line-height: 28px;
  }
}

.media-grid__date {
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

@media (min-width: 1280px) {
  .media-grid__date {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1700px) {
  .media-grid__date {
    font-size: 18px;
    line-height: 26px;
  }
}

.media-grid__more {
  width: 100%;
  max-width: 482px;
  padding-top: 45px;
  text-align: center;
}

@media (min-width: 768px) {
  .media-grid__more {
    max-width: none;
    padding-top: 55px;
  }
}

@media (min-width: 1024px) {
  .media-grid__more {
    padding-top: 70px;
  }
}

@media (min-width: 1280px) {
  .media-grid__more {
    padding-top: 80px;
  }
}

@media (min-width: 1700px) {
  .media-grid__more {
    padding-top: 120px;
  }
}

.media-item {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.media-item > * {
  flex-basis: 100%;
}

.media-item__content {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .media-item__content {
    flex: 0 0 90%;
    max-width: 90%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .media-item__content {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .media-item__content {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.media-item__aside {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 45px;
}

@media (min-width: 768px) {
  .media-item__aside {
    flex: 0 0 90%;
    max-width: 90%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .media-item__aside {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .media-item__aside {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1440px) {
  .media-item__aside {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1440px) {
  .media-item__aside {
    margin-left: 8.33333%;
  }
}

@media (min-width: 768px) {
  .media-item__aside {
    padding-top: 55px;
  }
}

@media (min-width: 1024px) {
  .media-item__aside {
    padding-top: 0;
  }
}

.media-item__hash-tag-list {
  margin-bottom: 20px;
}

.media-item__title {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .media-item__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (min-width: 1700px) {
  .media-item__title {
    margin-bottom: 30px;
  }
}

.media-item__slider, .media-item__video {
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .media-item__slider, .media-item__video {
    margin-bottom: 15px;
  }
}

@media (min-width: 1280px) {
  .media-item__slider, .media-item__video {
    margin-bottom: 20px;
  }
}

.media-item__author {
  margin-top: 20px;
  max-width: 80ch;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

@media (min-width: 768px) {
  .media-item__author {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .media-item__author {
    margin-top: 40px;
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 1280px) {
  .last-news {
    padding-left: 60px;
  }
}

@media (min-width: 1440px) {
  .last-news {
    padding-left: 0;
  }
}

.last-news__title {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1280px) {
  .last-news__title {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 28px;
  }
}

.last-news__list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

.last-news__item {
  margin-bottom: 20px;
}

@media (min-width: 1280px) {
  .last-news__item {
    margin-bottom: 50px;
  }
}

.last-news__item:last-child {
  margin-bottom: 0;
}

.last-news__link-wrap {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .last-news__link-wrap {
    margin-bottom: 10px;
  }
}

@media (min-width: 1280px) {
  .last-news__link-wrap {
    margin-bottom: 20px;
  }
}

.last-news__link {
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1280px) {
  .last-news__link {
    font-size: 18px;
    line-height: 26px;
  }
}

.last-news__date {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.banner-interactive {
  position: relative;
  height: 1000px;
}

.banner-interactive__img, .banner-interactive__layout {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}

.banner-interactive__img {
  z-index: 1;
}

.banner-interactive__group {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.banner-interactive__group:hover {
  opacity: 0;
}

.price-search {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 25px;
}

@media (min-width: 0) {
  .price-search {
    width: calc(100% - 20px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .price-search {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1024px) {
  .price-search {
    width: 1004px;
  }
}

@media (min-width: 1280px) {
  .price-search {
    width: 1220px;
  }
}

@media (min-width: 1440px) {
  .price-search {
    width: 1340px;
  }
}

@media (min-width: 1700px) {
  .price-search {
    width: 1620px;
  }
}

@media (min-width: 768px) {
  .price-search {
    margin-bottom: 40px;
  }
}

@media (min-width: 1280px) {
  .price-search {
    margin-bottom: 60px;
  }
}

.price-search__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.price-search__row > * {
  flex-basis: 100%;
}

.price-search__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.price-search__content {
  width: 100%;
  max-width: 720px;
}

.p-mobile-filter {
  position: relative;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #ebebeb;
}

.p-mobile-filter__toggle {
  display: inline-block;
  cursor: pointer;
}

.p-mobile-filter__toggle.is-opened .p-mobile-filter__title::after {
  transform: rotate(-135deg) translateY(0);
}

.p-mobile-filter__icon {
  margin-right: 5px;
  fill: none;
  stroke: #C4C4C4;
}

.p-mobile-filter__title {
  position: relative;
  padding-right: 16px;
  font-size: 12px;
  line-height: 18px;
  color: #646464;
}

.p-mobile-filter__title::after {
  top: calc(50% - 4px);
  left: auto;
  right: 0;
  width: 6px;
  height: 6px;
  transform-origin: center center;
  border-right: 3px solid #C4C4C4;
  border-bottom: 3px solid #C4C4C4;
  transform: rotate(45deg) translateY(0);
}

.p-mobile-filter__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.p-mobile-filter__item {
  padding: 8px;
  font-size: 12px;
  line-height: 18px;
  color: #646464;
  cursor: pointer;
  background-color: #FFFFFF;
  transition: background-color;
  user-select: none;
}

.p-mobile-filter__item:active {
  background-color: #D9F0F2;
}

.p-mobile-filter__item--active {
  background-color: #D9F0F2;
}

.p-filter {
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
}

.p-filter__row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 984px;
  margin: 0 auto;
  padding: 15px 0;
}

@media (min-width: 1280px) {
  .p-filter__row {
    width: 1200px;
  }
}

@media (min-width: 1440px) {
  .p-filter__row {
    width: 1320px;
  }
}

@media (min-width: 1700px) {
  .p-filter__row {
    width: 1600px;
  }
}

.p-filter__col:nth-child(1) {
  flex-shrink: 0;
  width: 225px;
  margin-right: 25px;
}

@media (min-width: 1280px) {
  .p-filter__col:nth-child(1) {
    width: 285px;
  }
}

@media (min-width: 1440px) {
  .p-filter__col:nth-child(1) {
    width: 300px;
    margin-right: 40px;
  }
}

.p-filter__col:nth-child(2) {
  flex-grow: 1;
  width: auto;
  margin-right: 25px;
}

@media (min-width: 1440px) {
  .p-filter__col:nth-child(2) {
    margin-right: 40px;
  }
}

.p-filter__col:nth-child(3) {
  flex-shrink: 0;
  width: 100px;
  margin-right: 25px;
}

@media (min-width: 1440px) {
  .p-filter__col:nth-child(3) {
    margin-right: 40px;
  }
}

.p-filter__col:nth-child(4) {
  flex-shrink: 0;
  width: 100px;
  margin-right: 25px;
}

@media (min-width: 1440px) {
  .p-filter__col:nth-child(4) {
    margin-right: 40px;
  }
}

@media (min-width: 1700px) {
  .p-filter__col:nth-child(4) {
    margin-right: 250px;
  }
}

.p-filter__link {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

@media (min-width: 1280px) {
  .p-filter__link {
    font-size: 16px;
    line-height: 24px;
  }
}

.p-filter__link::before {
  content: "";
  position: absolute;
  top: -17px;
  left: 0;
  width: 12px;
  height: 7px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0L12 7H0L6 0Z' fill='%23DCDCDC'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.p-filter__link--active::before {
  transform: rotate(180deg);
}

.order-block__form, .order-block__success {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.order-block__form > *, .order-block__success > * {
  flex-basis: 100%;
}

.order-block__success {
  display: none;
  min-height: 582px;
}

@media (min-width: 768px) {
  .order-block__success {
    min-height: 627px;
  }
}

@media (min-width: 1024px) {
  .order-block__success {
    min-height: 635px;
  }
}

@media (min-width: 1280px) {
  .order-block__success {
    min-height: 653px;
  }
}

@media (min-width: 1700px) {
  .order-block__success {
    min-height: 692px;
  }
}

.order-block__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .order-block__col {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .order-block__col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .order-block__col {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .order-block__col {
    margin-left: 16.66667%;
  }
}

@media (min-width: 1024px) {
  .order-block__col {
    margin-left: 25%;
  }
}

@media (min-width: 1280px) {
  .order-block__col {
    margin-left: 33.33333%;
  }
}

.order-block__text {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 16px;
  text-align: center;
}

@media (min-width: 768px) {
  .order-block__text {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .order-block__text {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1280px) {
  .order-block__text {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .order-block__text {
    margin-left: 8.33333%;
  }
}

@media (min-width: 1024px) {
  .order-block__text {
    margin-left: 16.66667%;
  }
}

@media (min-width: 1280px) {
  .order-block__text {
    margin-left: 25%;
  }
}

@media (min-width: 768px) {
  .order-block__text {
    margin-bottom: 32px;
  }
}

@media (min-width: 1280px) {
  .order-block__text {
    margin-bottom: 40px;
  }
}

.order-block__text p {
  font-size: 14px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .order-block__text p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (min-width: 1700px) {
  .order-block__text p {
    font-size: 20px;
    line-height: 32px;
  }
}

.order-block__text p:last-child {
  margin-bottom: 0;
}

.order-block__text-wrap {
  padding: 0 10%;
}

.order-block__success-text {
  text-align: center;
}

.order-form {
  max-width: 380px;
  margin: 0 auto;
}

.order-form__input-wrap + .order-form__input-wrap {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .order-form__input-wrap + .order-form__input-wrap {
    margin-top: 30px;
  }
}

.order-form__input {
  width: 100%;
}

.order-form__btn-wrap {
  width: 100%;
  max-width: 315px;
  margin: 40px auto 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .order-form__btn-wrap {
    margin-top: 30px;
  }
}

.order-form__submit {
  width: 100%;
}

.area {
  padding: 10px 0;
}

@media (min-width: 1280px) {
  .area {
    padding: 25px 0;
  }
}

.area__content {
  width: 100%;
}

@media (min-width: 768px) {
  .area__content {
    width: 540px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .area__content {
    width: 720px;
  }
}

@media (min-width: 1280px) {
  .area__content {
    width: 900px;
  }
}

@media (min-width: 1440px) {
  .area__content {
    width: 1000px;
  }
}

.area__img-wrap {
  position: relative;
  padding-top: 81.4887%;
  height: 0;
}

.area__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.area__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.area__item {
  position: absolute;
}

.area__tooltip {
  display: none;
  position: absolute;
  bottom: 65px;
  left: 50%;
  z-index: 1;
  width: 260px;
  padding: 10px 15px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  transform: translateX(-50%);
}

.area__tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -20px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 20px;
  pointer-events: none;
}

.area__btn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 1;
  color: #FFFFFF;
  background-color: #009CA5;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  transform-origin: center center;
  transition-property: background-color, color, border-color, transform;
  transition-duration: 0.3s;
  will-change: transform;
  cursor: pointer;
  user-select: none;
}

@media (min-width: 768px) {
  .area__btn {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}

@media (min-width: 1280px) {
  .area__btn {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
}

@media (min-width: 1280px) {
  .area__btn:hover {
    color: #FFFFFF;
    background-color: #0A7F86;
    border-color: #FFFFFF;
  }
}

.area__btn--active {
  color: #009CA5;
  background-color: #FFFFFF;
  border-color: #009CA5;
  transform: scale(1.4286);
}

.area__btn--active:hover {
  color: #009CA5;
  background-color: #FFFFFF;
  border-color: #009CA5;
}

.area__close {
  position: absolute;
  top: 8px;
  right: 8px;
}

.area-list {
  margin-bottom: 10px;
  padding-left: 1.5em;
  list-style: none;
  counter-reset: li;
}

@media (min-width: 768px) {
  .area-list {
    margin-bottom: 15px;
    padding-left: 2em;
  }
}

@media (min-width: 1024px) {
  .area-list {
    padding-left: 2.5em;
  }
}

@media (min-width: 1440px) {
  .area-list {
    margin-bottom: 20px;
  }
}

.area-list li {
  max-width: 80ch;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  .area-list li {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .area-list li {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
  }
}

.area-list li:last-child {
  margin-bottom: 0;
}

.area-list li::before {
  counter-increment: li;
  content: counters(li, ".") ". ";
  display: inline-block;
  width: 1.5em;
  margin-left: -1.5em;
  font-weight: 600;
  color: #95C12B;
  text-align: left;
}

@media (min-width: 768px) {
  .area-list li::before {
    width: 2em;
    margin-left: -2em;
  }
}

.area-list li > ol {
  padding-left: 2.5em;
}

.area-list li > ol li::before {
  width: 2em;
  margin-right: 0.5em;
  margin-left: -2.5em;
}

.area-list li > ol > li > ol {
  padding-left: 3.5em;
}

.area-list li > ol > li > ol li::before {
  width: 3em;
  margin-right: 0.5em;
  margin-left: -3.5em;
}

.area-list__link {
  font-size: 14px;
  line-height: 20px;
}

.building {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.building__img-bottom, .building__img-top, .building__img-mobile {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
}

.building__img-top {
  z-index: 1;
  transition: transform 1s ease-in-out;
  will-change: transform;
  cursor: pointer;
}

@media (min-width: 768px) {
  .building__img-top {
    display: none;
  }
}

@media (min-width: 1280px) {
  .building__img-top {
    display: block;
  }
}

@media (min-width: 1280px) {
  .building__img-top:hover {
    transform: translateY(-50%) scale(1.03);
  }
}

.building__img-top.is-hidden {
  display: none;
}

.building__img-mobile {
  display: none;
  z-index: 1;
}

@media (min-width: 768px) {
  .building__img-mobile {
    display: block;
  }
}

@media (min-width: 1280px) {
  .building__img-mobile {
    display: none;
  }
}

.building__list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 1280px) {
  .building__list {
    display: block;
  }
}

.building__item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  position: absolute;
  pointer-events: none;
}

.building__item:nth-child(1) {
  top: 30%;
  left: 16%;
}

@media (min-width: 1700px) {
  .building__item:nth-child(1) {
    top: 32%;
  }
}

.building__item:nth-child(2) {
  top: 49%;
  left: 16%;
}

.building__item:nth-child(3) {
  top: 67%;
  left: 16%;
}

@media (min-width: 1700px) {
  .building__item:nth-child(3) {
    top: 65%;
  }
}

.building__item:nth-child(4) {
  top: 28%;
  left: 54.5%;
}

.building__item:nth-child(5) {
  top: 49%;
  left: 69%;
}

.building__item:nth-child(6) {
  top: 30%;
  left: 76%;
}

@media (min-width: 1700px) {
  .building__item:nth-child(6) {
    top: 32%;
  }
}

.building__item:nth-child(7) {
  top: 30%;
  left: 86.5%;
}

@media (min-width: 1700px) {
  .building__item:nth-child(7) {
    top: 32%;
  }
}

.building__item:nth-child(8) {
  top: 30%;
  left: 94.5%;
}

@media (min-width: 1700px) {
  .building__item:nth-child(8) {
    top: 32%;
  }
}

.building__item:nth-child(9) {
  top: 67%;
  left: 83%;
}

@media (min-width: 1700px) {
  .building__item:nth-child(9) {
    top: 65%;
  }
}

.building__item:nth-child(10) {
  top: 67%;
  left: 54.5%;
}

@media (min-width: 1700px) {
  .building__item:nth-child(10) {
    top: 65%;
  }
}

.building__item--hidden .building__btn {
  visibility: hidden;
}

.building__tooltip {
  display: none;
  position: absolute;
  bottom: 65px;
  left: 50%;
  z-index: 1;
  width: 260px;
  padding: 10px 15px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  transform: translateX(-50%);
  pointer-events: auto;
}

.building__tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -20px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 20px;
  pointer-events: none;
}

.building__btn {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 10px 5px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition-property: transform;
  transition-duration: 1s;
  will-change: transform;
  user-select: none;
}

@media (min-width: 1280px) {
  .building__btn {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1700px) {
  .building__btn {
    width: 55px;
    height: 55px;
  }
}

.building__btn--hidden {
  visibility: hidden;
}

.building__btn-icon {
  width: 20px;
}

@media (min-width: 1280px) {
  .building__btn-icon {
    width: 40px;
  }
}

@media (min-width: 1700px) {
  .building__btn-icon {
    width: 55px;
  }
}

.building__close {
  position: absolute;
  top: 8px;
  right: 8px;
}

.building-segment-bottom {
  fill: transparent;
  transition: fill 0.3s;
  cursor: pointer;
}

@media (min-width: 1280px) {
  .building-segment-bottom:hover {
    fill: rgba(149, 193, 43, 0.2);
  }
}

.building-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.building-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

@media (min-width: 1280px) {
  .building-list__item {
    margin-bottom: 20px;
  }
}

.building-list__img {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

@media (min-width: 768px) {
  .building-list__img {
    width: 24px;
    height: 24px;
  }
}

@media (min-width: 1280px) {
  .building-list__img {
    width: 26px;
    height: 26px;
  }
}

.building-list__link {
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  .building-list__link {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1280px) {
  .building-list__link {
    font-size: 18px;
    line-height: 26px;
  }
}

.building-list__link:not([href]) {
  border: none;
}
