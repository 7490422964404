.main-menu {
	$self: &;
	margin-bottom: 40px;

	@media (min-width: $screen-lg) {
		margin: 0;
	}

	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-lg) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__item {

		+ #{$self}__item {
			margin-top: 15px;

			@media (min-width: $screen-md) {
				margin-top: 25px;
			}

			@media (min-width: $screen-lg) {
				margin-top: 0;
				margin-left: 25px;
			}

			@media (min-width: $screen-xl) {
				margin-left: 30px;
			}

			@media (min-width: $screen-xxl) {
				margin-left: 40px;
			}
		}
	}

	&__link {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: $text-color;

		@media (min-width: $screen-md) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-lg) {
			font-size: 14px;
			line-height: 20px;
		}

		@media (min-width: $screen-xxl) {
			font-size: 16px;
			line-height: 24px;
		}

		&:hover {
			color: $primary-sea;
		}

		&:focus {}

		&:active {
			color: $secondary-sea;
		}

		&--active {
			color: $primary-green;
		}
	}
}
