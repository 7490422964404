.info-doc {

	&__accordion {

		.accordion__sub-item {
			padding: 10px 0;

			&:first-child {
				padding-top: 5px;

				@media (min-width: $screen-lg) {
					padding-top: 0;
				}
			}

			&:last-child {
				padding-bottom: 15px;

				@media (min-width: $screen-sm) {
					padding-bottom: 25px;
				}

				@media (min-width: $screen-lg) {
					padding-bottom: 30px;
				}
			}
		}
	}
}
