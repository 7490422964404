.banner-interactive {
	position: relative;
	height: 1000px;

	&__img,
	&__layout {
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
	}

	&__img {
		z-index: 1;
	}

	&__group {
		opacity: 1;
		transition: opacity 0.5s ease;

		&:hover {
			opacity: 0;
		}
	}
}
