.reviews-slider {

	&__item {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		height: auto;
	}
}
