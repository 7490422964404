.info-list {
	@include row();
	margin-bottom: -20px;
	padding: 0;
	list-style: none;

	@media (min-width: $screen-md) {
		margin-bottom: -30px;
	}

	@media (min-width: $screen-lg) {
		margin-bottom: -40px;
	}

	&__item {
		@include col(xs, 12, 12);
		@include col(md, 6, 12);
		margin-bottom: 20px;

		@media (min-width: $screen-md) {
			margin-bottom: 30px;
		}

		@media (min-width: $screen-lg) {
			margin-bottom: 40px;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;

		@media (min-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}

		@media (min-width: $screen-md) {
			font-size: 18px;
			line-height: 26px;
		}

		@media (min-width: $screen-xxl) {
			margin-bottom: 20px;
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__content {
		@extend .paragraph-limit;
	}
}
